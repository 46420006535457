// Generated by the Scala Plugin for the Protocol Buffer Compiler.
// Do not edit!
//
// Protofile syntax: PROTO3

package api.v0

@SerialVersionUID(0L)
final case class SensorList(
    sensors: _root_.scala.Seq[api.v0.Sensor] = _root_.scala.Seq.empty
    ) extends scalapb.GeneratedMessage with scalapb.lenses.Updatable[SensorList] {
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      sensors.foreach { __item =>
        val __value = __item
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      }
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      sensors.foreach { __v =>
        val __m = __v
        _output__.writeTag(1, 2)
        _output__.writeUInt32NoTag(__m.serializedSize)
        __m.writeTo(_output__)
      };
    }
    def clearSensors = copy(sensors = _root_.scala.Seq.empty)
    def addSensors(__vs: api.v0.Sensor *): SensorList = addAllSensors(__vs)
    def addAllSensors(__vs: Iterable[api.v0.Sensor]): SensorList = copy(sensors = sensors ++ __vs)
    def withSensors(__v: _root_.scala.Seq[api.v0.Sensor]): SensorList = copy(sensors = __v)
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => sensors
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => _root_.scalapb.descriptors.PRepeated(sensors.iterator.map(_.toPMessage).toVector)
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.SensorList.type = api.v0.SensorList
    // @@protoc_insertion_point(GeneratedMessage[api.v0.SensorList])
}

object SensorList extends scalapb.GeneratedMessageCompanion[api.v0.SensorList] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.SensorList] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.SensorList = {
    val __sensors: _root_.scala.collection.immutable.VectorBuilder[api.v0.Sensor] = new _root_.scala.collection.immutable.VectorBuilder[api.v0.Sensor]
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 10 =>
          __sensors += _root_.scalapb.LiteParser.readMessage[api.v0.Sensor](_input__)
        case tag => _input__.skipField(tag)
      }
    }
    api.v0.SensorList(
        sensors = __sensors.result()
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.SensorList] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.SensorList(
        sensors = __fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).map(_.as[_root_.scala.Seq[api.v0.Sensor]]).getOrElse(_root_.scala.Seq.empty)
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = PwaSupportService0Proto.javaDescriptor.getMessageTypes().get(2)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = PwaSupportService0Proto.scalaDescriptor.messages(2)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = {
    var __out: _root_.scalapb.GeneratedMessageCompanion[_] = null
    (__number: @_root_.scala.unchecked) match {
      case 1 => __out = api.v0.Sensor
    }
    __out
  }
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = throw new MatchError(__fieldNumber)
  lazy val defaultInstance = api.v0.SensorList(
    sensors = _root_.scala.Seq.empty
  )
  implicit class SensorListLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.SensorList]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.SensorList](_l) {
    def sensors: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Seq[api.v0.Sensor]] = field(_.sensors)((c_, f_) => c_.copy(sensors = f_))
  }
  final val SENSORS_FIELD_NUMBER = 1
  def of(
    sensors: _root_.scala.Seq[api.v0.Sensor]
  ): _root_.api.v0.SensorList = _root_.api.v0.SensorList(
    sensors
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.SensorList])
}
