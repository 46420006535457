// Generated by the Scala Plugin for the Protocol Buffer Compiler.
// Do not edit!
//
// Protofile syntax: PROTO3

package api.v0

object PwaSupportService0Proto extends _root_.scalapb.GeneratedFileObject {
  lazy val dependencies: Seq[_root_.scalapb.GeneratedFileObject] = Seq(
    com.google.protobuf.empty.EmptyProto,
    scalapb.options.ScalapbProto,
    api.v0.TypesProto
  )
  lazy val messagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] =
    Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]](
      api.v0.LoginRequest,
      api.v0.LoginResponse,
      api.v0.SensorList,
      api.v0.ThingList,
      api.v0.TenantList,
      api.v0.LatestSensorValueRequest,
      api.v0.LatestSensorValueResponse,
      api.v0.SensorValuesRequest,
      api.v0.SensorValuesResponse,
      api.v0.ExampleRequest,
      api.v0.ExampleResponse
    )
  private lazy val ProtoBytes: _root_.scala.Array[Byte] =
      scalapb.Encoding.fromBase64(scala.collection.immutable.Seq(
  """Ch9hcGkvdjAvUHdhU3VwcG9ydFNlcnZpY2UwLnByb3RvEgZhcGkudjAaG2dvb2dsZS9wcm90b2J1Zi9lbXB0eS5wcm90bxoVc
  2NhbGFwYi9zY2FsYXBiLnByb3RvGhJhcGkvdjAvVHlwZXMucHJvdG8iZAoMTG9naW5SZXF1ZXN0EikKCHVzZXJuYW1lGAEgASgJQ
  g3iPwoSCHVzZXJuYW1lUgh1c2VybmFtZRIpCghwYXNzd29yZBgCIAEoCUIN4j8KEghwYXNzd29yZFIIcGFzc3dvcmQifwoNTG9na
  W5SZXNwb25zZRIgCgV0b2tlbhgBIAEoCUIK4j8HEgV0b2tlblIFdG9rZW4STAoOZGVmYXVsdF90ZW5hbnQYAiABKAsyDi5hcGkud
  jAuVGVuYW50QhXiPxISDWRlZmF1bHRUZW5hbnT4AQFSDWRlZmF1bHRUZW5hbnQiRAoKU2Vuc29yTGlzdBI2CgdzZW5zb3JzGAEgA
  ygLMg4uYXBpLnYwLlNlbnNvckIM4j8JEgdzZW5zb3JzUgdzZW5zb3JzIj8KCVRoaW5nTGlzdBIyCgZ0aGluZ3MYASADKAsyDS5hc
  GkudjAuVGhpbmdCC+I/CBIGdGhpbmdzUgZ0aGluZ3MiRAoKVGVuYW50TGlzdBI2Cgd0ZW5hbnRzGAEgAygLMg4uYXBpLnYwLlRlb
  mFudEIM4j8JEgd0ZW5hbnRzUgd0ZW5hbnRzIrUBChhMYXRlc3RTZW5zb3JWYWx1ZVJlcXVlc3QSPwoJc2Vuc29yX2lkGAEgASgLM
  hAuYXBpLnYwLlNlbnNvcklkQhDiPw0SCHNlbnNvcklk+AEBUghzZW5zb3JJZBJYChBtZWFzdXJlbWVudF90eXBlGAIgASgOMhcuY
  XBpLnYwLk1lYXN1cmVtZW50VHlwZUIU4j8REg9tZWFzdXJlbWVudFR5cGVSD21lYXN1cmVtZW50VHlwZSJkChlMYXRlc3RTZW5zb
  3JWYWx1ZVJlc3BvbnNlEkcKC21lYXN1cmVtZW50GAEgASgLMhMuYXBpLnYwLk1lYXN1cmVtZW50QhDiPw0SC21lYXN1cmVtZW50U
  gttZWFzdXJlbWVudCKBAgoTU2Vuc29yVmFsdWVzUmVxdWVzdBI/CglzZW5zb3JfaWQYASABKAsyEC5hcGkudjAuU2Vuc29ySWRCE
  OI/DRIIc2Vuc29ySWT4AQFSCHNlbnNvcklkElgKEG1lYXN1cmVtZW50X3R5cGUYAiABKA4yFy5hcGkudjAuTWVhc3VyZW1lbnRUe
  XBlQhTiPxESD21lYXN1cmVtZW50VHlwZVIPbWVhc3VyZW1lbnRUeXBlEk8KDXRpbWVfaW50ZXJ2YWwYAyABKAsyFC5hcGkudjAuV
  GltZUludGVydmFsQhTiPxESDHRpbWVJbnRlcnZhbPgBAVIMdGltZUludGVydmFsImkKFFNlbnNvclZhbHVlc1Jlc3BvbnNlElEKD
  G1lYXN1cmVtZW50cxgBIAEoCzIXLmFwaS52MC5NZWFzdXJlbWVudExpc3RCFOI/ERIMbWVhc3VyZW1lbnRz+AEBUgxtZWFzdXJlb
  WVudHMiPgoORXhhbXBsZVJlcXVlc3QSLAoJbXlyZXF1ZXN0GAEgASgJQg7iPwsSCW15cmVxdWVzdFIJbXlyZXF1ZXN0IkIKD0V4Y
  W1wbGVSZXNwb25zZRIvCgpteXJlc3BvbnNlGAEgASgJQg/iPwwSCm15cmVzcG9uc2VSCm15cmVzcG9uc2Uy6AMKElB3YVN1cHBvc
  nRTZXJ2aWNlMBI2CgVMb2dpbhIULmFwaS52MC5Mb2dpblJlcXVlc3QaFS5hcGkudjAuTG9naW5SZXNwb25zZSIAEjwKCUtlZXBBb
  Gl2ZRIWLmdvb2dsZS5wcm90b2J1Zi5FbXB0eRoVLmFwaS52MC5Mb2dpblJlc3BvbnNlIgASPQoNR2V0VGVuYW50TGlzdBIWLmdvb
  2dsZS5wcm90b2J1Zi5FbXB0eRoSLmFwaS52MC5UZW5hbnRMaXN0IgASNQoMR2V0VGhpbmdMaXN0EhAuYXBpLnYwLlRlbmFudElkG
  hEuYXBpLnYwLlRoaW5nTGlzdCIAEjcKDUdldFNlbnNvckxpc3QSEC5hcGkudjAuVGVuYW50SWQaEi5hcGkudjAuU2Vuc29yTGlzd
  CIAEl0KFEdldExhdGVzdFNlbnNvclZhbHVlEiAuYXBpLnYwLkxhdGVzdFNlbnNvclZhbHVlUmVxdWVzdBohLmFwaS52MC5MYXRlc
  3RTZW5zb3JWYWx1ZVJlc3BvbnNlIgASTgoPR2V0U2Vuc29yVmFsdWVzEhsuYXBpLnYwLlNlbnNvclZhbHVlc1JlcXVlc3QaHC5hc
  GkudjAuU2Vuc29yVmFsdWVzUmVzcG9uc2UiAEIH4j8EEAFIAGIGcHJvdG8z"""
      ).mkString)
  lazy val scalaDescriptor: _root_.scalapb.descriptors.FileDescriptor = {
    val scalaProto = com.google.protobuf.descriptor.FileDescriptorProto.parseFrom(ProtoBytes)
    _root_.scalapb.descriptors.FileDescriptor.buildFrom(scalaProto, dependencies.map(_.scalaDescriptor))
  }
  lazy val javaDescriptor: com.google.protobuf.Descriptors.FileDescriptor = {
    val javaProto = com.google.protobuf.DescriptorProtos.FileDescriptorProto.parseFrom(ProtoBytes)
    com.google.protobuf.Descriptors.FileDescriptor.buildFrom(javaProto, _root_.scala.Array(
      com.google.protobuf.empty.EmptyProto.javaDescriptor,
      scalapb.options.ScalapbProto.javaDescriptor,
      api.v0.TypesProto.javaDescriptor
    ))
  }
  @deprecated("Use javaDescriptor instead. In a future version this will refer to scalaDescriptor.", "ScalaPB 0.5.47")
  def descriptor: com.google.protobuf.Descriptors.FileDescriptor = javaDescriptor
}