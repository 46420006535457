// Generated by the Scala Plugin for the Protocol Buffer Compiler.
// Do not edit!
//
// Protofile syntax: PROTO3

package api.v0
import api.v0.JavaNetInstantTypeMapper._

object TypesProto extends _root_.scalapb.GeneratedFileObject {
  lazy val dependencies: Seq[_root_.scalapb.GeneratedFileObject] = Seq(
    com.google.protobuf.empty.EmptyProto,
    scalapb.options.ScalapbProto
  )
  lazy val messagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] =
    Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]](
      api.v0.SensorId,
      api.v0.ThingId,
      api.v0.TenantId,
      api.v0.UserId,
      api.v0.Tenant,
      api.v0.User,
      api.v0.Thing,
      api.v0.Sensor,
      api.v0.MeasurementTypeList,
      api.v0.JavaNetInstant,
      api.v0.TemperatureMeasurement,
      api.v0.TemperatureMeasurementList,
      api.v0.PressureMeasurement,
      api.v0.PressureMeasurementList,
      api.v0.RelativeHumidityMeasurement,
      api.v0.RelativeHumidityMeasurementList,
      api.v0.VoltageMeasurement,
      api.v0.VoltageMeasurementList,
      api.v0.PositionMeasurement,
      api.v0.PositionMeasurementList,
      api.v0.MeasurementMessage,
      api.v0.MeasurementListMessage,
      api.v0.TimeInterval
    )
  private lazy val ProtoBytes: _root_.scala.Array[Byte] =
      scalapb.Encoding.fromBase64(scala.collection.immutable.Seq(
  """ChJhcGkvdjAvVHlwZXMucHJvdG8SBmFwaS52MBobZ29vZ2xlL3Byb3RvYnVmL2VtcHR5LnByb3RvGhVzY2FsYXBiL3NjYWxhc
  GIucHJvdG8iRAoIU2Vuc29ySWQSIAoFdmFsdWUYASABKAlCCuI/BxIFdmFsdWVSBXZhbHVlOhbiPxMKEWFwaS52MC5SZXNvdXJjZ
  UlkIkMKB1RoaW5nSWQSIAoFdmFsdWUYASABKAlCCuI/BxIFdmFsdWVSBXZhbHVlOhbiPxMKEWFwaS52MC5SZXNvdXJjZUlkIkQKC
  FRlbmFudElkEiAKBXZhbHVlGAEgASgJQgriPwcSBXZhbHVlUgV2YWx1ZToW4j8TChFhcGkudjAuUmVzb3VyY2VJZCJCCgZVc2VyS
  WQSIAoFdmFsdWUYASABKAlCCuI/BxIFdmFsdWVSBXZhbHVlOhbiPxMKEWFwaS52MC5SZXNvdXJjZUlkIooBCgZUZW5hbnQSLAoCa
  WQYASABKAsyEC5hcGkudjAuVGVuYW50SWRCCuI/BxICaWT4AQFSAmlkEjMKBW93bmVyGAIgASgLMg4uYXBpLnYwLlVzZXJJZEIN4
  j8KEgVvd25lcvgBAVIFb3duZXISHQoEbmFtZRgDIAEoCUIJ4j8GEgRuYW1lUgRuYW1lIlQKBFVzZXISKgoCaWQYASABKAsyDi5hc
  GkudjAuVXNlcklkQgriPwcSAmlk+AEBUgJpZBIgCgVlbWFpbBgCIAEoCUIK4j8HEgVlbWFpbFIFZW1haWwilgEKBVRoaW5nEisKA
  mlkGAEgASgLMg8uYXBpLnYwLlRoaW5nSWRCCuI/BxICaWT4AQFSAmlkEjgKBnRlbmFudBgCIAEoCzIQLmFwaS52MC5UZW5hbnRJZ
  EIO4j8LEgZ0ZW5hbnT4AQFSBnRlbmFudBImCgdjb21tZW50GAMgASgJQgziPwkSB2NvbW1lbnRSB2NvbW1lbnQitwIKBlNlbnNvc
  hIsCgJpZBgBIAEoCzIQLmFwaS52MC5TZW5zb3JJZEIK4j8HEgJpZPgBAVICaWQSNAoFdGhpbmcYAiABKAsyDy5hcGkudjAuVGhpb
  mdJZEIN4j8KEgV0aGluZ/gBAVIFdGhpbmcSJgoHY29tbWVudBgDIAEoCUIM4j8JEgdjb21tZW50Ugdjb21tZW50Ej0KEHNlbnNvc
  l90eXBlX3RleHQYBCABKAlCE+I/EBIOc2Vuc29yVHlwZVRleHRSDnNlbnNvclR5cGVUZXh0EmIKEW1lYXN1cmVtZW50X3R5cGVzG
  AUgASgLMhsuYXBpLnYwLk1lYXN1cmVtZW50VHlwZUxpc3RCGOI/FRIQbWVhc3VyZW1lbnRUeXBlc/gBAVIQbWVhc3VyZW1lbnRUe
  XBlcyJQChNNZWFzdXJlbWVudFR5cGVMaXN0EjkKBXR5cGVzGAEgAygOMhcuYXBpLnYwLk1lYXN1cmVtZW50VHlwZUIK4j8HEgV0e
  XBlc1IFdHlwZXMicgoOSmF2YU5ldEluc3RhbnQSJgoHc2Vjb25kcxgBIAEoA0IM4j8JEgdzZWNvbmRzUgdzZWNvbmRzEiAKBW5hb
  m9zGAIgASgFQgriPwcSBW5hbm9zUgVuYW5vczoW4j8TIhFqYXZhLnRpbWUuSW5zdGFudCKxAQoWVGVtcGVyYXR1cmVNZWFzdXJlb
  WVudBJHCgl0aW1lc3RhbXAYASABKAsyFi5hcGkudjAuSmF2YU5ldEluc3RhbnRCEeI/DhIJdGltZXN0YW1w+AEBUgl0aW1lc3Rhb
  XASJgoHY2Vsc2l1cxgCIAEoAUIM4j8JEgdjZWxzaXVzUgdjZWxzaXVzOibiPyMKIWFwaS52MC5UZW1wZXJhdHVyZU1lYXN1cmVtZ
  W50QmFzZSJzChpUZW1wZXJhdHVyZU1lYXN1cmVtZW50TGlzdBJVCgxtZWFzdXJlbWVudHMYASADKAsyHi5hcGkudjAuVGVtcGVyY
  XR1cmVNZWFzdXJlbWVudEIR4j8OEgxtZWFzdXJlbWVudHNSDG1lYXN1cmVtZW50cyKoAQoTUHJlc3N1cmVNZWFzdXJlbWVudBJHC
  gl0aW1lc3RhbXAYASABKAsyFi5hcGkudjAuSmF2YU5ldEluc3RhbnRCEeI/DhIJdGltZXN0YW1w+AEBUgl0aW1lc3RhbXASIwoGc
  GFzY2FsGAIgASgBQgviPwgSBnBhc2NhbFIGcGFzY2FsOiPiPyAKHmFwaS52MC5QcmVzc3VyZU1lYXN1cmVtZW50QmFzZSJtChdQc
  mVzc3VyZU1lYXN1cmVtZW50TGlzdBJSCgxtZWFzdXJlbWVudHMYASADKAsyGy5hcGkudjAuUHJlc3N1cmVNZWFzdXJlbWVudEIR4
  j8OEgxtZWFzdXJlbWVudHNSDG1lYXN1cmVtZW50cyLEAQobUmVsYXRpdmVIdW1pZGl0eU1lYXN1cmVtZW50EkcKCXRpbWVzdGFtc
  BgBIAEoCzIWLmFwaS52MC5KYXZhTmV0SW5zdGFudEIR4j8OEgl0aW1lc3RhbXD4AQFSCXRpbWVzdGFtcBIvCgpwZXJjZW50YWdlG
  AIgASgBQg/iPwwSCnBlcmNlbnRhZ2VSCnBlcmNlbnRhZ2U6K+I/KAomYXBpLnYwLlJlbGF0aXZlSHVtaWRpdHlNZWFzdXJlbWVud
  EJhc2UifQofUmVsYXRpdmVIdW1pZGl0eU1lYXN1cmVtZW50TGlzdBJaCgxtZWFzdXJlbWVudHMYASADKAsyIy5hcGkudjAuUmVsY
  XRpdmVIdW1pZGl0eU1lYXN1cmVtZW50QhHiPw4SDG1lYXN1cmVtZW50c1IMbWVhc3VyZW1lbnRzIqABChJWb2x0YWdlTWVhc3VyZ
  W1lbnQSRwoJdGltZXN0YW1wGAEgASgLMhYuYXBpLnYwLkphdmFOZXRJbnN0YW50QhHiPw4SCXRpbWVzdGFtcPgBAVIJdGltZXN0Y
  W1wEh0KBHZvbHQYAiABKAFCCeI/BhIEdm9sdFIEdm9sdDoi4j8fCh1hcGkudjAuVm9sdGFnZU1lYXN1cmVtZW50QmFzZSJrChZWb
  2x0YWdlTWVhc3VyZW1lbnRMaXN0ElEKDG1lYXN1cmVtZW50cxgBIAMoCzIaLmFwaS52MC5Wb2x0YWdlTWVhc3VyZW1lbnRCEeI/D
  hIMbWVhc3VyZW1lbnRzUgxtZWFzdXJlbWVudHMiwAMKE1Bvc2l0aW9uTWVhc3VyZW1lbnQSRwoJdGltZXN0YW1wGAEgASgLMhYuY
  XBpLnYwLkphdmFOZXRJbnN0YW50QhHiPw4SCXRpbWVzdGFtcPgBAVIJdGltZXN0YW1wEjMKDGxhdGl0dWRlX2RlZxgCIAEoAUIQ4
  j8NEgtsYXRpdHVkZURlZ1ILbGF0aXR1ZGVEZWcSNgoNbG9uZ2l0dWRlX2RlZxgDIAEoAUIR4j8OEgxsb25naXR1ZGVEZWdSDGxvb
  mdpdHVkZURlZxJdChlwb3NpdGlvbmFsX2FjY3VyYWN5X21ldGVyGAQgASgBQhziPxkSF3Bvc2l0aW9uYWxBY2N1cmFjeU1ldGVyS
  ABSF3Bvc2l0aW9uYWxBY2N1cmFjeU1ldGVyiAEBEj4KDmFsdGl0dWRlX21ldGVyGAUgASgBQhLiPw8SDWFsdGl0dWRlTWV0ZXJIA
  VINYWx0aXR1ZGVNZXRlcogBAToj4j8gCh5hcGkudjAuUG9zaXRpb25NZWFzdXJlbWVudEJhc2VCHAoaX3Bvc2l0aW9uYWxfYWNjd
  XJhY3lfbWV0ZXJCEQoPX2FsdGl0dWRlX21ldGVyIm0KF1Bvc2l0aW9uTWVhc3VyZW1lbnRMaXN0ElIKDG1lYXN1cmVtZW50cxgBI
  AMoCzIbLmFwaS52MC5Qb3NpdGlvbk1lYXN1cmVtZW50QhHiPw4SDG1lYXN1cmVtZW50c1IMbWVhc3VyZW1lbnRzIrgDCgtNZWFzd
  XJlbWVudBJUCgt0ZW1wZXJhdHVyZRgBIAEoCzIeLmFwaS52MC5UZW1wZXJhdHVyZU1lYXN1cmVtZW50QhDiPw0SC3RlbXBlcmF0d
  XJlSABSC3RlbXBlcmF0dXJlEkgKCHByZXNzdXJlGAIgASgLMhsuYXBpLnYwLlByZXNzdXJlTWVhc3VyZW1lbnRCDeI/ChIIcHJlc
  3N1cmVIAFIIcHJlc3N1cmUSaQoRcmVsYXRpdmVfaHVtaWRpdHkYAyABKAsyIy5hcGkudjAuUmVsYXRpdmVIdW1pZGl0eU1lYXN1c
  mVtZW50QhXiPxISEHJlbGF0aXZlSHVtaWRpdHlIAFIQcmVsYXRpdmVIdW1pZGl0eRJECgd2b2x0YWdlGAQgASgLMhouYXBpLnYwL
  lZvbHRhZ2VNZWFzdXJlbWVudEIM4j8JEgd2b2x0YWdlSABSB3ZvbHRhZ2USSAoIcG9zaXRpb24YBSABKAsyGy5hcGkudjAuUG9za
  XRpb25NZWFzdXJlbWVudEIN4j8KEghwb3NpdGlvbkgAUghwb3NpdGlvbkIOCgxzZWFsZWRfdmFsdWUi0AMKD01lYXN1cmVtZW50T
  GlzdBJYCgt0ZW1wZXJhdHVyZRgBIAEoCzIiLmFwaS52MC5UZW1wZXJhdHVyZU1lYXN1cmVtZW50TGlzdEIQ4j8NEgt0ZW1wZXJhd
  HVyZUgAUgt0ZW1wZXJhdHVyZRJMCghwcmVzc3VyZRgCIAEoCzIfLmFwaS52MC5QcmVzc3VyZU1lYXN1cmVtZW50TGlzdEIN4j8KE
  ghwcmVzc3VyZUgAUghwcmVzc3VyZRJtChFyZWxhdGl2ZV9odW1pZGl0eRgDIAEoCzInLmFwaS52MC5SZWxhdGl2ZUh1bWlkaXR5T
  WVhc3VyZW1lbnRMaXN0QhXiPxISEHJlbGF0aXZlSHVtaWRpdHlIAFIQcmVsYXRpdmVIdW1pZGl0eRJICgd2b2x0YWdlGAQgASgLM
  h4uYXBpLnYwLlZvbHRhZ2VNZWFzdXJlbWVudExpc3RCDOI/CRIHdm9sdGFnZUgAUgd2b2x0YWdlEkwKCHBvc2l0aW9uGAUgASgLM
  h8uYXBpLnYwLlBvc2l0aW9uTWVhc3VyZW1lbnRMaXN0Qg3iPwoSCHBvc2l0aW9uSABSCHBvc2l0aW9uQg4KDHNlYWxlZF92YWx1Z
  SKCAQoMVGltZUludGVydmFsEjsKBWJlZ2luGAEgASgLMhYuYXBpLnYwLkphdmFOZXRJbnN0YW50Qg3iPwoSBWJlZ2lu+AEBUgViZ
  WdpbhI1CgNlbmQYAiABKAsyFi5hcGkudjAuSmF2YU5ldEluc3RhbnRCC+I/CBIDZW5k+AEBUgNlbmQqvAMKD01lYXN1cmVtZW50V
  HlwZRI4CgdVTktOT1dOEAAaK+I/KAodYXBpLnYwLlVua25vd25NZWFzdXJlbWVudFR5cGUSB1Vua25vd24SRAoLVEVNUEVSQVRVU
  kUQARoz4j8wCiFhcGkudjAuVGVtcGVyYXR1cmVNZWFzdXJlbWVudFR5cGUSC1RlbXBlcmF0dXJlEjsKCFBSRVNTVVJFEAIaLeI/K
  goeYXBpLnYwLlByZXNzdXJlTWVhc3VyZW1lbnRUeXBlEghQcmVzc3VyZRJUChFSRUxBVElWRV9IVU1JRElUWRADGj3iPzoKJmFwa
  S52MC5SZWxhdGl2ZUh1bWlkaXR5TWVhc3VyZW1lbnRUeXBlEhBSZWxhdGl2ZUh1bWlkaXR5EjgKB1ZPTFRBR0UQBBor4j8oCh1hc
  GkudjAuVm9sdGFnZU1lYXN1cmVtZW50VHlwZRIHVm9sdGFnZRI7CghQT1NJVElPThAFGi3iPyoKHmFwaS52MC5Qb3NpdGlvbk1lY
  XN1cmVtZW50VHlwZRIIUG9zaXRpb24aH+I/HAoaYXBpLnYwLk1lYXN1cmVtZW50VHlwZUJhc2VCKuI/JxABGiFhcGkudjAuSmF2Y
  U5ldEluc3RhbnRUeXBlTWFwcGVyLl9IAGIGcHJvdG8z"""
      ).mkString)
  lazy val scalaDescriptor: _root_.scalapb.descriptors.FileDescriptor = {
    val scalaProto = com.google.protobuf.descriptor.FileDescriptorProto.parseFrom(ProtoBytes)
    _root_.scalapb.descriptors.FileDescriptor.buildFrom(scalaProto, dependencies.map(_.scalaDescriptor))
  }
  lazy val javaDescriptor: com.google.protobuf.Descriptors.FileDescriptor = {
    val javaProto = com.google.protobuf.DescriptorProtos.FileDescriptorProto.parseFrom(ProtoBytes)
    com.google.protobuf.Descriptors.FileDescriptor.buildFrom(javaProto, _root_.scala.Array(
      com.google.protobuf.empty.EmptyProto.javaDescriptor,
      scalapb.options.ScalapbProto.javaDescriptor
    ))
  }
  @deprecated("Use javaDescriptor instead. In a future version this will refer to scalaDescriptor.", "ScalaPB 0.5.47")
  def descriptor: com.google.protobuf.Descriptors.FileDescriptor = javaDescriptor
}