// Generated by the Scala Plugin for the Protocol Buffer Compiler.
// Do not edit!
//
// Protofile syntax: PROTO3

package api.v0
import api.v0.JavaNetInstantTypeMapper._

/** should be wire-compatible with 'google.protobuf.Timestamp'
  */
@SerialVersionUID(0L)
final case class JavaNetInstant(
    seconds: _root_.scala.Long = 0L,
    nanos: _root_.scala.Int = 0
    ) extends scalapb.GeneratedMessage with scalapb.lenses.Updatable[JavaNetInstant] {
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      
      {
        val __value = seconds
        if (__value != 0L) {
          __size += _root_.com.google.protobuf.CodedOutputStream.computeInt64Size(1, __value)
        }
      };
      
      {
        val __value = nanos
        if (__value != 0) {
          __size += _root_.com.google.protobuf.CodedOutputStream.computeInt32Size(2, __value)
        }
      };
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      {
        val __v = seconds
        if (__v != 0L) {
          _output__.writeInt64(1, __v)
        }
      };
      {
        val __v = nanos
        if (__v != 0) {
          _output__.writeInt32(2, __v)
        }
      };
    }
    def withSeconds(__v: _root_.scala.Long): JavaNetInstant = copy(seconds = __v)
    def withNanos(__v: _root_.scala.Int): JavaNetInstant = copy(nanos = __v)
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => {
          val __t = seconds
          if (__t != 0L) __t else null
        }
        case 2 => {
          val __t = nanos
          if (__t != 0) __t else null
        }
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => _root_.scalapb.descriptors.PLong(seconds)
        case 2 => _root_.scalapb.descriptors.PInt(nanos)
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.JavaNetInstant.type = api.v0.JavaNetInstant
    // @@protoc_insertion_point(GeneratedMessage[api.v0.JavaNetInstant])
}

object JavaNetInstant extends scalapb.GeneratedMessageCompanion[api.v0.JavaNetInstant] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.JavaNetInstant] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.JavaNetInstant = {
    var __seconds: _root_.scala.Long = 0L
    var __nanos: _root_.scala.Int = 0
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 8 =>
          __seconds = _input__.readInt64()
        case 16 =>
          __nanos = _input__.readInt32()
        case tag => _input__.skipField(tag)
      }
    }
    api.v0.JavaNetInstant(
        seconds = __seconds,
        nanos = __nanos
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.JavaNetInstant] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.JavaNetInstant(
        seconds = __fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).map(_.as[_root_.scala.Long]).getOrElse(0L),
        nanos = __fieldsMap.get(scalaDescriptor.findFieldByNumber(2).get).map(_.as[_root_.scala.Int]).getOrElse(0)
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = TypesProto.javaDescriptor.getMessageTypes().get(9)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = TypesProto.scalaDescriptor.messages(9)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = throw new MatchError(__number)
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = throw new MatchError(__fieldNumber)
  lazy val defaultInstance = api.v0.JavaNetInstant(
    seconds = 0L,
    nanos = 0
  )
  implicit class JavaNetInstantLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.JavaNetInstant]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.JavaNetInstant](_l) {
    def seconds: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Long] = field(_.seconds)((c_, f_) => c_.copy(seconds = f_))
    def nanos: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Int] = field(_.nanos)((c_, f_) => c_.copy(nanos = f_))
  }
  final val SECONDS_FIELD_NUMBER = 1
  final val NANOS_FIELD_NUMBER = 2
  def of(
    seconds: _root_.scala.Long,
    nanos: _root_.scala.Int
  ): _root_.api.v0.JavaNetInstant = _root_.api.v0.JavaNetInstant(
    seconds,
    nanos
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.JavaNetInstant])
}
