// Generated by the Scala Plugin for the Protocol Buffer Compiler.
// Do not edit!
//
// Protofile syntax: PROTO3

package api.v0
import api.v0.JavaNetInstantTypeMapper._

/** [begin, end)
  */
@SerialVersionUID(0L)
final case class TimeInterval(
    begin: java.time.Instant,
    end: java.time.Instant
    ) extends scalapb.GeneratedMessage with scalapb.lenses.Updatable[TimeInterval] {
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      
      {
        val __value = api.v0.TimeInterval._typemapper_begin.toBase(begin)
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      
      {
        val __value = api.v0.TimeInterval._typemapper_end.toBase(end)
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      
      {
        val __v = api.v0.TimeInterval._typemapper_begin.toBase(begin)
        _output__.writeTag(1, 2)
        _output__.writeUInt32NoTag(__v.serializedSize)
        __v.writeTo(_output__)
      };
      
      {
        val __v = api.v0.TimeInterval._typemapper_end.toBase(end)
        _output__.writeTag(2, 2)
        _output__.writeUInt32NoTag(__v.serializedSize)
        __v.writeTo(_output__)
      };
    }
    def withBegin(__v: java.time.Instant): TimeInterval = copy(begin = __v)
    def withEnd(__v: java.time.Instant): TimeInterval = copy(end = __v)
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => api.v0.TimeInterval._typemapper_begin.toBase(begin)
        case 2 => api.v0.TimeInterval._typemapper_end.toBase(end)
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => api.v0.TimeInterval._typemapper_begin.toBase(begin).toPMessage
        case 2 => api.v0.TimeInterval._typemapper_end.toBase(end).toPMessage
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.TimeInterval.type = api.v0.TimeInterval
    // @@protoc_insertion_point(GeneratedMessage[api.v0.TimeInterval])
}

object TimeInterval extends scalapb.GeneratedMessageCompanion[api.v0.TimeInterval] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.TimeInterval] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.TimeInterval = {
    var __requiredFields0: _root_.scala.Long = 0x3L
    var __begin: _root_.scala.Option[api.v0.JavaNetInstant] = _root_.scala.None
    var __end: _root_.scala.Option[api.v0.JavaNetInstant] = _root_.scala.None
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 10 =>
          __begin = _root_.scala.Some(__begin.fold(_root_.scalapb.LiteParser.readMessage[api.v0.JavaNetInstant](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
          __requiredFields0 &= 0xfffffffffffffffeL
        case 18 =>
          __end = _root_.scala.Some(__end.fold(_root_.scalapb.LiteParser.readMessage[api.v0.JavaNetInstant](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
          __requiredFields0 &= 0xfffffffffffffffdL
        case tag => _input__.skipField(tag)
      }
    }
    if (__requiredFields0 != 0L) { throw new _root_.com.google.protobuf.InvalidProtocolBufferException("Message missing required fields.") } 
    api.v0.TimeInterval(
        begin = api.v0.TimeInterval._typemapper_begin.toCustom(__begin.getOrElse(api.v0.JavaNetInstant.defaultInstance)),
        end = api.v0.TimeInterval._typemapper_end.toCustom(__end.getOrElse(api.v0.JavaNetInstant.defaultInstance))
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.TimeInterval] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.TimeInterval(
        begin = api.v0.TimeInterval._typemapper_begin.toCustom(__fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).get.as[api.v0.JavaNetInstant]),
        end = api.v0.TimeInterval._typemapper_end.toCustom(__fieldsMap.get(scalaDescriptor.findFieldByNumber(2).get).get.as[api.v0.JavaNetInstant])
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = TypesProto.javaDescriptor.getMessageTypes().get(22)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = TypesProto.scalaDescriptor.messages(22)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = {
    var __out: _root_.scalapb.GeneratedMessageCompanion[_] = null
    (__number: @_root_.scala.unchecked) match {
      case 1 => __out = api.v0.JavaNetInstant
      case 2 => __out = api.v0.JavaNetInstant
    }
    __out
  }
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = throw new MatchError(__fieldNumber)
  lazy val defaultInstance = api.v0.TimeInterval(
    begin = api.v0.TimeInterval._typemapper_begin.toCustom(api.v0.JavaNetInstant.defaultInstance),
    end = api.v0.TimeInterval._typemapper_end.toCustom(api.v0.JavaNetInstant.defaultInstance)
  )
  implicit class TimeIntervalLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.TimeInterval]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.TimeInterval](_l) {
    def begin: _root_.scalapb.lenses.Lens[UpperPB, java.time.Instant] = field(_.begin)((c_, f_) => c_.copy(begin = f_))
    def end: _root_.scalapb.lenses.Lens[UpperPB, java.time.Instant] = field(_.end)((c_, f_) => c_.copy(end = f_))
  }
  final val BEGIN_FIELD_NUMBER = 1
  final val END_FIELD_NUMBER = 2
  @transient
  private[v0] val _typemapper_begin: _root_.scalapb.TypeMapper[api.v0.JavaNetInstant, java.time.Instant] = implicitly[_root_.scalapb.TypeMapper[api.v0.JavaNetInstant, java.time.Instant]]
  @transient
  private[v0] val _typemapper_end: _root_.scalapb.TypeMapper[api.v0.JavaNetInstant, java.time.Instant] = implicitly[_root_.scalapb.TypeMapper[api.v0.JavaNetInstant, java.time.Instant]]
  def of(
    begin: java.time.Instant,
    end: java.time.Instant
  ): _root_.api.v0.TimeInterval = _root_.api.v0.TimeInterval(
    begin,
    end
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.TimeInterval])
}
