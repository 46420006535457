package pwa.model

import api.v0._
import diode.data.{Pot, PotMap}
import japgolly.scalajs.react.extra.router.RouterCtl
import pwa.Page
import pwa.util.Mergeable

// TODO: currentTenant should probably be Option[Ref[Tenant]] ?
// or even better submodel in option that contains uncoditional Ref[Tenant]...
case class RootModel(routerCtl: RouterCtl[Page],
                     apiConfig: ApiConfigModel,
                     ui: UiModel = UiModel(),
                     tenants: Pot[Tenants],
                     currentTenant: Option[Tenant],
                     things: Pot[Things],
                     sensors: Sensors,
                    )

// TODO: this is replacement for SensorDetail, then rename it to SensorDetail
case class SensorInfo(sensor: Sensor,
                      latestMeasurements: PotMap[MeasurementType, Measurement],
                      measurements: PotMap[MeasurementType, MeasurementList],
                     ) extends Mergeable[SensorInfo] {


  override def mergeFrom(old: SensorInfo): SensorInfo = {
    scribe.debug("Merging SensorInfo")
    // SensorInfo can reuse older data from previous model state.
    // Currently just use old latestMeasurements and overwrite that with this new state's latest measurement
    // values (if any !).
    // This avoids re-fetching all latest measurements every time user navigates to sensors overview page.

    // TODO: add method to refresh latest measurements (probably to this class, taking a Dispatcher),
    // to be used in sensor overview in a timeout.

    SensorInfo(sensor, old.latestMeasurements ++ latestMeasurements.seq, measurements)
  }

  override def toString: String = {
    s"${super.toString} and ${
      latestMeasurements.seq.map {
        case (measurementType, value) => s"${measurementType} -> ${value}"
      }.mkString(",")
    }"
  }
}

// TODO: maybe replace with type aliases to Seq

case class Things(seq: Seq[Thing])

case class Tenants(seq: Seq[Tenant])

case class UiModel(
                    sidebarVisible: Boolean = false,
                    afterLoginPage: Option[Page] = None
                  )


// TODO: move somewhere else...
case class ApiToken(actualToken: String)

case class UserIdentity(email: String)

// TODO: probaly rename variable from 'api' to 'server' or 'backend'
case class ApiConfigModel(api: PwaSupportService,
                          token: Pot[ApiToken] = Pot.empty,
                          user: Pot[UserIdentity] = Pot.empty
                         )
