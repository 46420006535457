// Generated by the Scala Plugin for the Protocol Buffer Compiler.
// Do not edit!
//
// Protofile syntax: PROTO3

package api.v0
import api.v0.JavaNetInstantTypeMapper._

@SerialVersionUID(0L)
final case class Tenant(
    id: api.v0.TenantId,
    owner: api.v0.UserId,
    name: _root_.scala.Predef.String = ""
    ) extends scalapb.GeneratedMessage with scalapb.lenses.Updatable[Tenant] {
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      
      {
        val __value = id
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      
      {
        val __value = owner
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      
      {
        val __value = name
        if (!__value.isEmpty) {
          __size += _root_.com.google.protobuf.CodedOutputStream.computeStringSize(3, __value)
        }
      };
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      
      {
        val __v = id
        _output__.writeTag(1, 2)
        _output__.writeUInt32NoTag(__v.serializedSize)
        __v.writeTo(_output__)
      };
      
      {
        val __v = owner
        _output__.writeTag(2, 2)
        _output__.writeUInt32NoTag(__v.serializedSize)
        __v.writeTo(_output__)
      };
      {
        val __v = name
        if (!__v.isEmpty) {
          _output__.writeString(3, __v)
        }
      };
    }
    def withId(__v: api.v0.TenantId): Tenant = copy(id = __v)
    def withOwner(__v: api.v0.UserId): Tenant = copy(owner = __v)
    def withName(__v: _root_.scala.Predef.String): Tenant = copy(name = __v)
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => id
        case 2 => owner
        case 3 => {
          val __t = name
          if (__t != "") __t else null
        }
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => id.toPMessage
        case 2 => owner.toPMessage
        case 3 => _root_.scalapb.descriptors.PString(name)
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.Tenant.type = api.v0.Tenant
    // @@protoc_insertion_point(GeneratedMessage[api.v0.Tenant])
}

object Tenant extends scalapb.GeneratedMessageCompanion[api.v0.Tenant] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.Tenant] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.Tenant = {
    var __requiredFields0: _root_.scala.Long = 0x3L
    var __id: _root_.scala.Option[api.v0.TenantId] = _root_.scala.None
    var __owner: _root_.scala.Option[api.v0.UserId] = _root_.scala.None
    var __name: _root_.scala.Predef.String = ""
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 10 =>
          __id = _root_.scala.Some(__id.fold(_root_.scalapb.LiteParser.readMessage[api.v0.TenantId](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
          __requiredFields0 &= 0xfffffffffffffffeL
        case 18 =>
          __owner = _root_.scala.Some(__owner.fold(_root_.scalapb.LiteParser.readMessage[api.v0.UserId](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
          __requiredFields0 &= 0xfffffffffffffffdL
        case 26 =>
          __name = _input__.readStringRequireUtf8()
        case tag => _input__.skipField(tag)
      }
    }
    if (__requiredFields0 != 0L) { throw new _root_.com.google.protobuf.InvalidProtocolBufferException("Message missing required fields.") } 
    api.v0.Tenant(
        id = __id.getOrElse(api.v0.TenantId.defaultInstance),
        owner = __owner.getOrElse(api.v0.UserId.defaultInstance),
        name = __name
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.Tenant] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.Tenant(
        id = __fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).get.as[api.v0.TenantId],
        owner = __fieldsMap.get(scalaDescriptor.findFieldByNumber(2).get).get.as[api.v0.UserId],
        name = __fieldsMap.get(scalaDescriptor.findFieldByNumber(3).get).map(_.as[_root_.scala.Predef.String]).getOrElse("")
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = TypesProto.javaDescriptor.getMessageTypes().get(4)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = TypesProto.scalaDescriptor.messages(4)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = {
    var __out: _root_.scalapb.GeneratedMessageCompanion[_] = null
    (__number: @_root_.scala.unchecked) match {
      case 1 => __out = api.v0.TenantId
      case 2 => __out = api.v0.UserId
    }
    __out
  }
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = throw new MatchError(__fieldNumber)
  lazy val defaultInstance = api.v0.Tenant(
    id = api.v0.TenantId.defaultInstance,
    owner = api.v0.UserId.defaultInstance,
    name = ""
  )
  implicit class TenantLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.Tenant]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.Tenant](_l) {
    def id: _root_.scalapb.lenses.Lens[UpperPB, api.v0.TenantId] = field(_.id)((c_, f_) => c_.copy(id = f_))
    def owner: _root_.scalapb.lenses.Lens[UpperPB, api.v0.UserId] = field(_.owner)((c_, f_) => c_.copy(owner = f_))
    def name: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Predef.String] = field(_.name)((c_, f_) => c_.copy(name = f_))
  }
  final val ID_FIELD_NUMBER = 1
  final val OWNER_FIELD_NUMBER = 2
  final val NAME_FIELD_NUMBER = 3
  def of(
    id: api.v0.TenantId,
    owner: api.v0.UserId,
    name: _root_.scala.Predef.String
  ): _root_.api.v0.Tenant = _root_.api.v0.Tenant(
    id,
    owner,
    name
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.Tenant])
}
