package pwa.util

import api.v0.MeasurementType

// TODO: this will need localization...
object ToStringUtils {

  def measurementTypeToString(mt: MeasurementType): String = {
    mt match {
      case MeasurementType.Unrecognized(n) => s"Unrecognized (${n})"
      case MeasurementType.Unknown => "Unknown"
      case MeasurementType.Temperature => "Temperature"
      case MeasurementType.Pressure => "Pressure"
      case MeasurementType.RelativeHumidity => "Relative Humidity"
      case MeasurementType.Voltage => "Voltage"
      case MeasurementType.Position => "Position"
    }
  }

}
