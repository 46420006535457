package pwa.screens

import diode.Action
import diode.data.Pot
import diode.react.{ModelProxy, ReactConnectProxy}
import japgolly.scalajs.react.{BackendScope, Callback, ReactEventFromInput, ScalaComponent}
import japgolly.scalajs.react.extra.router.RouterCtl
import japgolly.scalajs.react.vdom.all.vdomAttrVtCssUnits
import japgolly.scalajs.react.vdom.html_<^._
import pwa.Page
import pwa.circuit.LoginRequestAction
import pwa.model.{ApiConfigModel, ApiToken}
import react.semanticui.collections.form.{Form, FormInput}
import react.semanticui.collections.grid.{Grid, GridColumn}
import react.semanticui.collections.message.Message
import react.semanticui.elements.button.Button
import react.semanticui.elements.header.Header
import react.semanticui.elements.input.IconPosition
import react.semanticui.elements.segment.Segment
import react.semanticui.{colors, sizes, textalignment, verticalalignment}

object LoginScreen {
  case class Props(routerCtl: RouterCtl[Page], proxy: ModelProxy[ApiConfigModel])

  case class State(
                    apiTokenProxy: ReactConnectProxy[Pot[ApiToken]],
                    email: String = "",
                    password: String = ""
                  )

  case class Backend(bs: BackendScope[Props, State]) {

    def onEmailChange(e: ReactEventFromInput): Callback = {
      bs.modState(_.copy(email = e.target.value))
    }

    def onPasswordChange(e: ReactEventFromInput): Callback = {
      bs.modState(_.copy(password = e.target.value))
    }

    def render(props: Props, state: State) = {
      val dispatch: Action => Callback = props.proxy.dispatchCB

      Grid(textAlign = textalignment.Center, verticalAlign = verticalalignment.Middle)(
        ^.height := "100vh",
        GridColumn(
          ^.maxWidth := 450.px,
          Header(textAlign = textalignment.Center, color = colors.Blue, as = "h1")(
            "Log in required..."
          ),
          Form(size = sizes.Large)(
            Segment(stacked = true)(
              FormInput(fluid = true, icon = "user", iconPosition = IconPosition.Left, onChangeE = onEmailChange(_))(^.value := state.email, ^.placeholder := "E-mail"),
              FormInput(fluid = true, icon = "lock", iconPosition = IconPosition.Left, onChangeE = onPasswordChange(_), tpe = "password")(^.value := state.password, ^.placeholder := "Password"),
              Button(fluid = true, size = sizes.Large, color = colors.Blue, onClick = dispatch(LoginRequestAction(email = state.email, password = state.password)))("Login")
            )
          ),
          state.apiTokenProxy { (mp: ModelProxy[Pot[ApiToken]]) =>
            // TODO: use this for debugging issues with api token.
            Message(s"In case of forgotten password, contact support.").vdomElement
          },
        )
      )
    }
  }

  private val component = ScalaComponent.builder[Props]
    .initialStateFromProps(props => State(apiTokenProxy = props.proxy.connect(_.token)))
    .renderBackend[Backend]
    .build

  def apply(routerCtl: RouterCtl[Page], proxy: ModelProxy[ApiConfigModel]) = component(Props(routerCtl, proxy))
}
