package pwa

import api.v0.{PwaSupportService0GrpcWeb, SensorId}
import diode.Dispatcher
import diode.data.PotMap
import pwa.circuit.SensorListFetch

package object model {

  type PwaSupportService = PwaSupportService0GrpcWeb.PwaSupportService0[_]

  type Sensors = PotMap[SensorId, SensorInfo]

  // TODO: probably remove, 'Sensors' type is used inside Pot
  object Sensors {
    def apply(dispatcher: Dispatcher): Sensors = {
      PotMap[SensorId, SensorInfo](SensorListFetch(dispatcher))
    }
  }

}
