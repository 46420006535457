package pwa.circuit

import api.v0.{Tenant, ThingList}
import diode._
import diode.data.{Pot, PotAction, RefTo}
import org.scalajs.macrotaskexecutor.MacrotaskExecutor.Implicits._
import pwa.model.{PwaSupportService, Things}

case class RefreshThingList(potResult: Pot[Things] = Pot.empty) extends PotAction[Things, RefreshThingList] {
  override def next(newResult: Pot[Things]): RefreshThingList = RefreshThingList(newResult)
}

class RefreshThingListHandler[M](dispatcher: Dispatcher,
                                 modelRW: ModelRW[M, Pot[Things]],
                                 api: ModelR[M, PwaSupportService],
                                 currentTenant: RefTo[Option[Tenant]],
                                ) extends ApiActionHandler(modelRW, api, dispatcher) {

  override protected def handle: PartialFunction[Any, ActionResult[M]] = {
    case action: RefreshThingList => {

      currentTenant.target.value match {
        case None => {
          scribe.debug("No tenant selected, moving to login page")
          redirectToLogin
        }
        case Some(tenant) => {
          val updateFunction = action.effect(api.value.getThingList(tenant.id))((x: ThingList) => Things(x.things), redirectToLoginIfNeeded)
          action.handleWith(this, updateFunction)(PotAction.handler())
        }
      }
    }
  }
}
