package pwa.screens

import diode.Action
import diode.react.{ModelProxy, ReactConnectProxy}
import japgolly.scalajs.react.extra.router.RouterCtl
import japgolly.scalajs.react.vdom.html_<^._
import japgolly.scalajs.react.{BackendScope, Callback, ScalaComponent}
import pwa.Page
import pwa.model.{RootModel, Sensors}

object DebugScreen {
  case class Props(routerCtl: RouterCtl[Page], proxy: ModelProxy[RootModel])

  case class State(
                    rootModelProxy: ReactConnectProxy[RootModel]
                  )

  case class Backend(bs: BackendScope[Props, State]) {

    def renderSensors(sensors: Sensors): VdomNode = {
      <.div(
        s"Count: ${sensors.keySet.size}",
        <.ul(
          sensors.keySet.toVdomArray(sensorId => {
            <.li(
              s"${sensorId}"
            )
          })
        )
      )
    }

    def render(props: Props, state: State) = {

      val dispatch: Action => Callback = props.proxy.dispatchCB
      state.rootModelProxy((proxy: ModelProxy[RootModel]) => {
        <.div(
          proxy().toString,
          <.h1("sensors"),
          renderSensors(proxy().sensors),
        )
      })
    }
  }

  private val component = ScalaComponent.builder[Props]
    .initialStateFromProps(props => State(rootModelProxy = props.proxy.connect(identity)))
    .renderBackend[Backend]
    .build

  def apply(routerCtl: RouterCtl[Page], proxy: ModelProxy[RootModel]) = component(Props(routerCtl, proxy))
}
