package pwa.circuit

import api.v0.PwaSupportService0GrpcWeb
import diode._
import diode.data.{Pot, RefTo, Unavailable}
import diode.react.ReactConnector
import io.grpc.ManagedChannel
import pwa._
import pwa.model._
import scalapb.grpcweb.Metadata

import scala.concurrent.ExecutionContext

case class ToggleSidebar() extends Action

object AppCircuitHelper {
  def triggerClearTenantLevel()(implicit ec: ExecutionContext): EffectSeq = {
    val clearSensors = Effect.action(ClearSensorList())
    val clearThings = Effect.action(RefreshThingList(Unavailable))

    // TOOD: instead of dispatch, handle using actions ?
    // afterLoginPage
    //val red = Effect.action(Redirect)
    clearThings >> clearSensors
  }

  // TODO: this does not seem to work...
  def triggerClearEverything()(implicit ec: ExecutionContext): EffectSeq = {
    Effect.action(UnsetCurrentTenant()) >> Effect.action(RefreshTenantList(Unavailable))
  }
}



// TODO: send 'refresh login' action on start
class AppCircuit(grpcChannel: ManagedChannel) extends Circuit[model.RootModel] with ReactConnector[model.RootModel] {

  // TODO: refactor this out !
  lazy val api: PwaSupportService0GrpcWeb.PwaSupportService0[Metadata] = PwaSupportService0GrpcWeb.stub(grpcChannel)

  override protected def initialModel: RootModel = RootModel(
    routerCtl = null,
    apiConfig = ApiConfigModel(api = api),
    tenants = Pot.empty[Tenants],
    currentTenant = None,
    sensors = Sensors(this),
    things = Pot.empty[Things],
  )

  val configureRouterActionHandler = new ConfigureRouterActionHandler(zoomTo(_.routerCtl))
  val sidebarHandler = new SidebarActionHandler(zoomTo(_.ui.sidebarVisible))
  val pageChangeNeededHandler = new PageChangeNeededHandler(zoomTo(_.routerCtl))
  // not using: "zoomTo(_.apiConfig.token).zip(this.zoomTo(_.apiConfig.user))" because that results in non-writeable ModelRO
  val loginHandler = new LoginActionHandler(zoomTo(_.apiConfig), zoomTo(_.ui.afterLoginPage), zoomTo(_.currentTenant), grpcChannel, api)

  val afterLoginPageChangeHandler = new AfterLoginPageChangeHandler(zoomTo(_.ui.afterLoginPage))

  val changeCurrentTenantHandler = new ChangeCurrentTenantHandler(zoomTo(_.currentTenant), zoomTo(_.ui.afterLoginPage))
  val updateSensorListHandler = new UpdateSensorListHandler(this, zoomTo(_.sensors),zoomTo(_.apiConfig.api), RefTo(zoom(_.currentTenant)))
  val thingListHandler = new RefreshThingListHandler(this, zoomTo(_.things), zoomTo(_.apiConfig.api), RefTo(zoom(_.currentTenant)))
  val tenantListHandler = new RefreshTenantListHandler(this, zoomTo(_.tenants), zoomTo(_.apiConfig.api))

  val updateSensorLatestMeasurementHandler = new UpdateSensorLastMeasurementsHandler(this, zoomTo(_.sensors), zoomTo(_.apiConfig.api), RefTo(zoom(_.currentTenant)))
  val updateSensorMeasurementsHandler = new UpdateSensorMeasurementsHandler(this, zoomTo(_.sensors), zoomTo(_.apiConfig.api), RefTo(zoom(_.currentTenant)))

  override protected def actionHandler: HandlerFunction = composeHandlers(
    configureRouterActionHandler,
    sidebarHandler,
    pageChangeNeededHandler,
    loginHandler,
    afterLoginPageChangeHandler,
    changeCurrentTenantHandler,
    updateSensorListHandler,
    thingListHandler,
    tenantListHandler,
    updateSensorLatestMeasurementHandler,
    updateSensorMeasurementsHandler,
  )
}
