package pwa.util

import diode.data.Pot

abstract class Merger[T] {
  def apply(oldValue: T, newValue: T): T
}

/*
For classes implementing Mergeable, make it possible to re-use data from old value.
 */
object Merger {
  implicit def smart[T <: Mergeable[T]]: Merger[T] = new Merger[T] {
    override def apply(oldValue: T, newValue: T): T = {
      scribe.debug(s"smart path taken")
      newValue.mergeFrom(oldValue)
    }
  }

  implicit def smartPot[T <: Mergeable[T]](implicit m: Merger[T]): Merger[Pot[T]] = new Merger[Pot[T]] {
    override def apply(oldValue: Pot[T], newValue: Pot[T]) = {
      scribe.debug(s"smart pot path taken")
      newValue.map(t => {
        if (oldValue.nonEmpty) {
          Merger.merge(oldValue.get, t)(m)
        } else {
          t
        }
      })
    }
  }

  // NOTE: it can be useful to comment this method out, to make sure implicit Merger[T] are present,
  // and of proper types (eg. Merger[T] where Merge[Pot[T]] should be used)
  implicit def fallback[T]: Merger[T] = new Merger[T] {
    override def apply(oldValue: T, newValue: T): T = newValue
  }

  // NOTE: for this to work, calling code either has to have 'T' as known type,
  // or (implicit merger: Merger[T]) in parameters if T is still generic at that level
  // (or often: implicit merger: Merger[Pot[T]])
  // WARNING: failure is silent, it uses the fallback !
  def merge[T](oldValue: T, newValue: T)(implicit merger: Merger[T]): T = {
    merger(oldValue, newValue)
  }
}
