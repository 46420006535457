package pwa.components

import api.v0.{MeasurementList, MeasurementType, PositionMeasurementList, PressureMeasurement, PressureMeasurementList, RelativeHumidityMeasurement, RelativeHumidityMeasurementList, ScalarMeasurementBase, ScalarMeasurementTypeBase, TemperatureMeasurement, TemperatureMeasurementList, VoltageMeasurement, VoltageMeasurementList}
import diode.data.Pot
import diode.react.{ModelProxy, ReactConnectProxy}
import japgolly.scalajs.react.callback.CallbackTo
import japgolly.scalajs.react.{BackendScope, Callback, Ref, ScalaComponent}
import japgolly.scalajs.react.component.builder.Lifecycle.ComponentDidMount
import japgolly.scalajs.react.vdom.html_<^
import org.scalajs.dom.html
import org.scalajs.dom.html.Element
import japgolly.scalajs.react.vdom.html_<^._
import react.semanticui.collections.table.{Table, TableBody, TableCell, TableCompact, TableHeader, TableHeaderCell, TableRow, TableTextAlign}
import react.semanticui.elements.header.Header

import java.time.{Instant, ZoneId}
import java.time.format.DateTimeFormatter
import java.time.temporal.{ChronoUnit, TemporalUnit}
import java.util.Locale
import scala.collection.immutable
import scala.scalajs.js
import scala.scalajs.js.annotation.{JSGlobal, JSImport}

object MeasurementsTable {

  case class Props(mt: MeasurementType, mlp: ModelProxy[Pot[MeasurementList]])

  case class State(rcp: ReactConnectProxy[Pot[MeasurementList]])

  case class Backend($: BackendScope[Props, State]) {

    def didMount(cdm: ComponentDidMount[Props, State, Backend]): Callback = {
      Callback()
    }

    lazy val dayMarkerInstantFormatter = DateTimeFormatter
      .ofPattern("yyyy-MM-dd' 'O")
      .withLocale(Locale.getDefault)
      .withZone(ZoneId.systemDefault)

    lazy val shortInstantFormatter = DateTimeFormatter
      .ofPattern("HH:mm:ss")
      .withLocale(Locale.getDefault)
      .withZone(ZoneId.systemDefault)

    private def formatDayMark(instant: Instant): String = {
      dayMarkerInstantFormatter.format(instant)
    }

    private def formatShortTimestamp(instant: Instant): String = {
      shortInstantFormatter.format(instant)
    }

    private def scalarMeasurementsToTableBody(mt: ScalarMeasurementTypeBase[_], measurements: Seq[ScalarMeasurementBase[_]]): VdomElement = {
      val z: Map[Instant, Seq[ScalarMeasurementBase[_]]] = measurements.groupBy(_.timestamp.truncatedTo(ChronoUnit.DAYS))
      val elements: Seq[html_<^.VdomElement] = z.map((t: (Instant, Seq[ScalarMeasurementBase[_]])) => {
        val h = TableRow(textAlign = TableTextAlign.Center)(
          TableCell()(
            Header(as = <.h5)(formatDayMark(t._1))
          )(^.colSpan := 2),
        ).vdomElement

        val d = t._2.map(m => {
          TableRow()(
            TableCell(formatShortTimestamp(m.timestamp)),
            TableCell(m.customaryFormat),
          ).vdomElement
        })
        val t2: List[VdomElement] = List(h) ++ d
        t._1 -> t2
      }).toSeq.sortBy(_._1).flatMap(_._2)
      TableBody()(
        elements: _*
      ).vdomElement
    }

    def emptyRow() = TableRow()(TableCell()(^.colSpan := 2, "No data")).vdomElement

    def measurementListToTableBody(mt: MeasurementType, ml: MeasurementList): VdomNode = {
      mt.asScalar match {
        case None => {
          ml match {
            case PositionMeasurementList(measurements) => {
              val rs = measurements.map(m => {
                TableRow()(
                  TableCell(s"${m.latitudeDeg} lat ${m.longitudeDeg} lon"),
                  TableCell("Position"),
                  TableCell(formatShortTimestamp(m.timestamp)),
                ).vdomElement
              })
              rs
              TableBody()(rs: _*)
            }
            case _ => {
              TableBody(emptyRow())
            }
          }
        }
        case Some(scalarMt) => {
          ml match {
            case MeasurementList.Empty => TableBody(emptyRow())
            case TemperatureMeasurementList(measurements: Seq[TemperatureMeasurement]) => {
              scalarMeasurementsToTableBody(scalarMt, measurements)
            }
            case PressureMeasurementList(measurements: Seq[PressureMeasurement]) => {
              scalarMeasurementsToTableBody(scalarMt, measurements)
            }
            case RelativeHumidityMeasurementList(measurements: Seq[RelativeHumidityMeasurement]) => {
              scalarMeasurementsToTableBody(scalarMt, measurements)
            }
            case VoltageMeasurementList(measurements: Seq[VoltageMeasurement]) => {
              scalarMeasurementsToTableBody(scalarMt, measurements)
            }
          }
        }
      }
    }

    def render(props: Props, state: State) = {
      import diode.react.ReactPot._


      def pendingRow(startTime: Long, text: String) = TableRow()(TableCell()(^.colSpan := 2, text)).vdomElement

      def failedRow(exception: Throwable) = TableRow()(TableCell()(^.colSpan := 2, s"Failed (${exception})")).vdomElement


      state.rcp((mlmp: ModelProxy[Pot[MeasurementList]]) => {
        Table(unstackable = true, compact = TableCompact.Very)(
          TableHeader()(
            TableRow()(
              TableHeaderCell("time"),
              TableHeaderCell("value"),
            )
          ),
          mlmp().render((ml: MeasurementList) => measurementListToTableBody(props.mt, ml))
        ).vdomElement
      })
    }
  }

  private val component = ScalaComponent.builder[Props]
    .initialStateFromProps(props => {
      State(props.mlp.connect(identity))
    })
    .renderBackend[Backend]
    .componentDidMount((x: ComponentDidMount[Props, State, Backend]) => x.backend.didMount(x))
    .build

  def apply(mt: MeasurementType, mlp: ModelProxy[Pot[MeasurementList]]) = component(Props(mt, mlp))

}
