// Generated by the Scala Plugin for the Protocol Buffer Compiler.
// Do not edit!
//
// Protofile syntax: PROTO3

package api.v0
import api.v0.JavaNetInstantTypeMapper._

@SerialVersionUID(0L)
final case class MeasurementTypeList(
    types: _root_.scala.Seq[api.v0.MeasurementType] = _root_.scala.Seq.empty
    ) extends scalapb.GeneratedMessage with scalapb.lenses.Updatable[MeasurementTypeList] {
    private[this] def typesSerializedSize = {
      if (__typesSerializedSizeField == 0) __typesSerializedSizeField = {
        var __s: _root_.scala.Int = 0
        types.foreach(__i => __s += _root_.com.google.protobuf.CodedOutputStream.computeEnumSizeNoTag(__i.value))
        __s
      }
      __typesSerializedSizeField
    }
    @transient private[this] var __typesSerializedSizeField: _root_.scala.Int = 0
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      if (types.nonEmpty) {
        val __localsize = typesSerializedSize
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__localsize) + __localsize
      }
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      if (types.nonEmpty) {
        _output__.writeTag(1, 2)
        _output__.writeUInt32NoTag(typesSerializedSize)
        types.foreach((_output__.writeEnumNoTag _).compose((_: api.v0.MeasurementType).value))
      };
    }
    def clearTypes = copy(types = _root_.scala.Seq.empty)
    def addTypes(__vs: api.v0.MeasurementType *): MeasurementTypeList = addAllTypes(__vs)
    def addAllTypes(__vs: Iterable[api.v0.MeasurementType]): MeasurementTypeList = copy(types = types ++ __vs)
    def withTypes(__v: _root_.scala.Seq[api.v0.MeasurementType]): MeasurementTypeList = copy(types = __v)
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => types.iterator.map(_.javaValueDescriptor).toSeq
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => _root_.scalapb.descriptors.PRepeated(types.iterator.map(__e => _root_.scalapb.descriptors.PEnum(__e.scalaValueDescriptor)).toVector)
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.MeasurementTypeList.type = api.v0.MeasurementTypeList
    // @@protoc_insertion_point(GeneratedMessage[api.v0.MeasurementTypeList])
}

object MeasurementTypeList extends scalapb.GeneratedMessageCompanion[api.v0.MeasurementTypeList] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.MeasurementTypeList] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.MeasurementTypeList = {
    val __types: _root_.scala.collection.immutable.VectorBuilder[api.v0.MeasurementType] = new _root_.scala.collection.immutable.VectorBuilder[api.v0.MeasurementType]
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 8 =>
          __types += api.v0.MeasurementType.fromValue(_input__.readEnum())
        case 10 => {
          val length = _input__.readRawVarint32()
          val oldLimit = _input__.pushLimit(length)
          while (_input__.getBytesUntilLimit > 0) {
            __types += api.v0.MeasurementType.fromValue(_input__.readEnum())
          }
          _input__.popLimit(oldLimit)
        }
        case tag => _input__.skipField(tag)
      }
    }
    api.v0.MeasurementTypeList(
        types = __types.result()
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.MeasurementTypeList] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.MeasurementTypeList(
        types = __fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).map(_.as[_root_.scala.Seq[_root_.scalapb.descriptors.EnumValueDescriptor]]).getOrElse(_root_.scala.Seq.empty).iterator.map(__e => api.v0.MeasurementType.fromValue(__e.number)).toSeq
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = TypesProto.javaDescriptor.getMessageTypes().get(8)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = TypesProto.scalaDescriptor.messages(8)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = throw new MatchError(__number)
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = {
    (__fieldNumber: @_root_.scala.unchecked) match {
      case 1 => api.v0.MeasurementType
    }
  }
  lazy val defaultInstance = api.v0.MeasurementTypeList(
    types = _root_.scala.Seq.empty
  )
  implicit class MeasurementTypeListLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.MeasurementTypeList]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.MeasurementTypeList](_l) {
    def types: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Seq[api.v0.MeasurementType]] = field(_.types)((c_, f_) => c_.copy(types = f_))
  }
  final val TYPES_FIELD_NUMBER = 1
  def of(
    types: _root_.scala.Seq[api.v0.MeasurementType]
  ): _root_.api.v0.MeasurementTypeList = _root_.api.v0.MeasurementTypeList(
    types
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.MeasurementTypeList])
}
