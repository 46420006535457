package pwa.circuit

import api.v0.TenantList
import com.google.protobuf.empty.Empty
import diode._
import diode.data.PotState.PotReady
import diode.data._
import org.scalajs.macrotaskexecutor.MacrotaskExecutor.Implicits._
import pwa.model.{PwaSupportService, Tenants}

case class RefreshTenantList(potResult: Pot[Tenants] = Pot.empty) extends PotAction[Tenants, RefreshTenantList] {
  override def next(newResult: Pot[Tenants]): RefreshTenantList = RefreshTenantList(newResult)
}

class RefreshTenantListHandler[M](dispatcher: Dispatcher,
                                  modelRW: ModelRW[M, Pot[Tenants]],
                                  api: ModelR[M, PwaSupportService]
                                 ) extends ApiActionHandler(modelRW, api, dispatcher) {

    override protected def handle: PartialFunction[Any, ActionResult[M]] = {
    case action: RefreshTenantList => {

      val updateFunction: EffectSingle[RefreshTenantList] = action.effect(
        api.value.getTenantList(Empty())
      )(success = (x: TenantList) => Tenants(x.tenants), failure = redirectToLoginIfNeeded)

      action.handle {
        case PotState.PotFailed => {
          val e = action.result.failed.get
          scribe.debug(s"failed with ${e}")
          updated(value.fail(e))
        }
        case PotReady => {
          updated(action.potResult)
        }
        // remaining cases handled by default handler
        case s: PotState => {
          val h = PotAction.handler[Tenants, M, RefreshTenantList]()
          h(action, this, updateFunction)
        }
      }
    }
  }
}
