// Generated by the Scala Plugin for the Protocol Buffer Compiler.
// Do not edit!
//
// Protofile syntax: PROTO3

package api.v0

@SerialVersionUID(0L)
final case class LatestSensorValueResponse(
    measurement: api.v0.Measurement = api.v0.LatestSensorValueResponse._typemapper_measurement.toCustom(api.v0.MeasurementMessage.defaultInstance)
    ) extends scalapb.GeneratedMessage with scalapb.lenses.Updatable[LatestSensorValueResponse] {
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      
      {
        val __value = api.v0.LatestSensorValueResponse._typemapper_measurement.toBase(measurement)
        if (__value.serializedSize != 0) {
          __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
        }
      };
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      {
        val __v = api.v0.LatestSensorValueResponse._typemapper_measurement.toBase(measurement)
        if (__v.serializedSize != 0) {
          _output__.writeTag(1, 2)
          _output__.writeUInt32NoTag(__v.serializedSize)
          __v.writeTo(_output__)
        }
      };
    }
    def withMeasurement(__v: api.v0.Measurement): LatestSensorValueResponse = copy(measurement = __v)
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => {
          val __t = api.v0.LatestSensorValueResponse._typemapper_measurement.toBase(measurement)
          if (__t != api.v0.MeasurementMessage.defaultInstance) __t else null
        }
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => api.v0.LatestSensorValueResponse._typemapper_measurement.toBase(measurement).toPMessage
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.LatestSensorValueResponse.type = api.v0.LatestSensorValueResponse
    // @@protoc_insertion_point(GeneratedMessage[api.v0.LatestSensorValueResponse])
}

object LatestSensorValueResponse extends scalapb.GeneratedMessageCompanion[api.v0.LatestSensorValueResponse] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.LatestSensorValueResponse] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.LatestSensorValueResponse = {
    var __measurement: _root_.scala.Option[api.v0.MeasurementMessage] = _root_.scala.None
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 10 =>
          __measurement = _root_.scala.Some(__measurement.fold(_root_.scalapb.LiteParser.readMessage[api.v0.MeasurementMessage](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
        case tag => _input__.skipField(tag)
      }
    }
    api.v0.LatestSensorValueResponse(
        measurement = api.v0.LatestSensorValueResponse._typemapper_measurement.toCustom(__measurement.getOrElse(api.v0.MeasurementMessage.defaultInstance))
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.LatestSensorValueResponse] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.LatestSensorValueResponse(
        measurement = api.v0.LatestSensorValueResponse._typemapper_measurement.toCustom(__fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).map(_.as[api.v0.MeasurementMessage]).getOrElse(api.v0.MeasurementMessage.defaultInstance))
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = PwaSupportService0Proto.javaDescriptor.getMessageTypes().get(6)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = PwaSupportService0Proto.scalaDescriptor.messages(6)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = {
    var __out: _root_.scalapb.GeneratedMessageCompanion[_] = null
    (__number: @_root_.scala.unchecked) match {
      case 1 => __out = api.v0.MeasurementMessage
    }
    __out
  }
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = throw new MatchError(__fieldNumber)
  lazy val defaultInstance = api.v0.LatestSensorValueResponse(
    measurement = api.v0.LatestSensorValueResponse._typemapper_measurement.toCustom(api.v0.MeasurementMessage.defaultInstance)
  )
  implicit class LatestSensorValueResponseLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.LatestSensorValueResponse]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.LatestSensorValueResponse](_l) {
    def measurement: _root_.scalapb.lenses.Lens[UpperPB, api.v0.Measurement] = field(_.measurement)((c_, f_) => c_.copy(measurement = f_))
  }
  final val MEASUREMENT_FIELD_NUMBER = 1
  @transient
  private[v0] val _typemapper_measurement: _root_.scalapb.TypeMapper[api.v0.MeasurementMessage, api.v0.Measurement] = implicitly[_root_.scalapb.TypeMapper[api.v0.MeasurementMessage, api.v0.Measurement]]
  def of(
    measurement: api.v0.Measurement
  ): _root_.api.v0.LatestSensorValueResponse = _root_.api.v0.LatestSensorValueResponse(
    measurement
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.LatestSensorValueResponse])
}
