package pwa.circuit

import diode.{ActionHandler, ActionResult, Dispatcher, Effect, ModelR, ModelRW}
import pwa.circuit.exceptions.LoginNeededException
import pwa.model.PwaSupportService

import scala.concurrent.ExecutionContext

abstract class ApiActionHandler[M, T](
                                       modelRW: ModelRW[M, T],
                                       protected val api: ModelR[M, PwaSupportService],
                                       protected val dispatcher: Dispatcher,
                                     ) extends ActionHandler(modelRW) {

  def dispatchRedirectToLogin(): Unit = {
    dispatcher.dispatch(PageChangeNeeded.login)
  }
  def redirectToLogin(implicit ec: ExecutionContext): ActionResult[M] = {
    effectOnly(Effect.action(PageChangeNeeded.login))
  }

  def redirectToLoginIfNeeded: Throwable => Throwable = {
    case e: io.grpc.StatusRuntimeException => e.getStatus().getCode() match {
      case io.grpc.Status.Code.UNAUTHENTICATED => {
        scribe.debug("UNAUTHENTICATED, moving to login page")
        dispatchRedirectToLogin()
        e
      }
      case _ => {
        scribe.debug(e, s"status is is: ${e.getStatus()}, code: ${e.getStatus().getCode()}")
        e
      }
    }

    case e: LoginNeededException => {
      dispatchRedirectToLogin()
      e
    }
    case e: Throwable => {
      scribe.error(e, "This exception is unexpected")
      e
    }
  }

  // TODO: helper to wrap api call (take 'api => RT'), wrap error codes to proper exceptions
  // (unauthenticated, unauthorized, connection issues,...)
}
