package pwa.components

import api.v0.{MeasurementList, MeasurementType}
import diode.data.Pot
import diode.react.{ModelProxy, ReactConnectProxy}
import japgolly.scalajs.react.vdom.html_<^._
import japgolly.scalajs.react.{BackendScope, Ref, ScalaComponent}
import org.scalajs.dom.html

object ScalarChart {

  case class Props(mt: MeasurementType, mlp: ModelProxy[Pot[MeasurementList]])

  case class State(rcp: ReactConnectProxy[Pot[MeasurementList]])

  case class Backend($: BackendScope[Props, State]) {
    private val divRef = Ref[html.Element]

    def render(props: Props, state: State) = {
      import diode.react.ReactPot._
      state.rcp((mlmp: ModelProxy[Pot[MeasurementList]]) => {
        <.div(
          mlmp().render(ml => {
            StaticScalarChart(props.mt, ml)
          })
        )
      })
    }
  }

  private val component = ScalaComponent.builder[Props]
    .initialStateFromProps(props => {
      State(props.mlp.connect(identity))
    })
    .renderBackend[Backend]
    .build

  def apply(mt: MeasurementType, mlp: ModelProxy[Pot[MeasurementList]]) = component(Props(mt, mlp))
}
