package pwa.components

import japgolly.scalajs.react.ScalaComponent
import japgolly.scalajs.react.ScalaComponent.BackendScope
import japgolly.scalajs.react.vdom.html_<^._

object ThingDetail {
  case class Props(id: api.v0.ThingId)

  case class State()

  case class Backend($: BackendScope[Props, State]) {
    def render(props: Props, state: State) = {
      <.div(
        s"Got ${props.id}"
      )
    }
  }

  private val component = ScalaComponent.builder[Props]
    .initialStateFromProps(p => State())
    .renderBackend[Backend]
    .build

  def apply(id: api.v0.ThingId) = component(Props(id))
}
