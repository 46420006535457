// Generated by the Scala Plugin for the Protocol Buffer Compiler.
// Do not edit!
//
// Protofile syntax: PROTO3

package api.v0

@SerialVersionUID(0L)
final case class LoginResponse(
    token: _root_.scala.Predef.String = "",
    defaultTenant: api.v0.Tenant
    ) extends scalapb.GeneratedMessage with scalapb.lenses.Updatable[LoginResponse] {
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      
      {
        val __value = token
        if (!__value.isEmpty) {
          __size += _root_.com.google.protobuf.CodedOutputStream.computeStringSize(1, __value)
        }
      };
      
      {
        val __value = defaultTenant
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      {
        val __v = token
        if (!__v.isEmpty) {
          _output__.writeString(1, __v)
        }
      };
      
      {
        val __v = defaultTenant
        _output__.writeTag(2, 2)
        _output__.writeUInt32NoTag(__v.serializedSize)
        __v.writeTo(_output__)
      };
    }
    def withToken(__v: _root_.scala.Predef.String): LoginResponse = copy(token = __v)
    def withDefaultTenant(__v: api.v0.Tenant): LoginResponse = copy(defaultTenant = __v)
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => {
          val __t = token
          if (__t != "") __t else null
        }
        case 2 => defaultTenant
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => _root_.scalapb.descriptors.PString(token)
        case 2 => defaultTenant.toPMessage
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.LoginResponse.type = api.v0.LoginResponse
    // @@protoc_insertion_point(GeneratedMessage[api.v0.LoginResponse])
}

object LoginResponse extends scalapb.GeneratedMessageCompanion[api.v0.LoginResponse] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.LoginResponse] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.LoginResponse = {
    var __requiredFields0: _root_.scala.Long = 0x1L
    var __token: _root_.scala.Predef.String = ""
    var __defaultTenant: _root_.scala.Option[api.v0.Tenant] = _root_.scala.None
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 10 =>
          __token = _input__.readStringRequireUtf8()
        case 18 =>
          __defaultTenant = _root_.scala.Some(__defaultTenant.fold(_root_.scalapb.LiteParser.readMessage[api.v0.Tenant](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
          __requiredFields0 &= 0xfffffffffffffffeL
        case tag => _input__.skipField(tag)
      }
    }
    if (__requiredFields0 != 0L) { throw new _root_.com.google.protobuf.InvalidProtocolBufferException("Message missing required fields.") } 
    api.v0.LoginResponse(
        token = __token,
        defaultTenant = __defaultTenant.getOrElse(api.v0.Tenant.defaultInstance)
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.LoginResponse] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.LoginResponse(
        token = __fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).map(_.as[_root_.scala.Predef.String]).getOrElse(""),
        defaultTenant = __fieldsMap.get(scalaDescriptor.findFieldByNumber(2).get).get.as[api.v0.Tenant]
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = PwaSupportService0Proto.javaDescriptor.getMessageTypes().get(1)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = PwaSupportService0Proto.scalaDescriptor.messages(1)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = {
    var __out: _root_.scalapb.GeneratedMessageCompanion[_] = null
    (__number: @_root_.scala.unchecked) match {
      case 2 => __out = api.v0.Tenant
    }
    __out
  }
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = throw new MatchError(__fieldNumber)
  lazy val defaultInstance = api.v0.LoginResponse(
    token = "",
    defaultTenant = api.v0.Tenant.defaultInstance
  )
  implicit class LoginResponseLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.LoginResponse]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.LoginResponse](_l) {
    def token: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Predef.String] = field(_.token)((c_, f_) => c_.copy(token = f_))
    def defaultTenant: _root_.scalapb.lenses.Lens[UpperPB, api.v0.Tenant] = field(_.defaultTenant)((c_, f_) => c_.copy(defaultTenant = f_))
  }
  final val TOKEN_FIELD_NUMBER = 1
  final val DEFAULT_TENANT_FIELD_NUMBER = 2
  def of(
    token: _root_.scala.Predef.String,
    defaultTenant: api.v0.Tenant
  ): _root_.api.v0.LoginResponse = _root_.api.v0.LoginResponse(
    token,
    defaultTenant
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.LoginResponse])
}
