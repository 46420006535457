package pwa.circuit.exceptions

// NOTE: user-level login needed, does _not_ contain gRPC auth/permission failures !
class LoginNeededException extends RuntimeException {

}

class NoCurrentTenantException extends LoginNeededException {

}
