package api.v0

object PwaSupportService0GrpcWeb {
  val METHOD_LOGIN: _root_.io.grpc.MethodDescriptor[api.v0.LoginRequest, api.v0.LoginResponse] =
    _root_.io.grpc.MethodDescriptor.newBuilder()
      .setType(_root_.io.grpc.MethodDescriptor.MethodType.UNARY)
      .setFullMethodName(_root_.io.grpc.MethodDescriptor.generateFullMethodName("api.v0.PwaSupportService0", "Login"))
      .setSampledToLocalTracing(true)
      .setRequestMarshaller(_root_.scalapb.grpc.Marshaller.forMessage[api.v0.LoginRequest])
      .setResponseMarshaller(_root_.scalapb.grpc.Marshaller.forMessage[api.v0.LoginResponse])
      .setSchemaDescriptor(_root_.scalapb.grpc.ConcreteProtoMethodDescriptorSupplier.fromMethodDescriptor(api.v0.PwaSupportService0Proto.javaDescriptor.getServices().get(0).getMethods().get(0)))
      .build()
  
  val METHOD_KEEP_ALIVE: _root_.io.grpc.MethodDescriptor[com.google.protobuf.empty.Empty, api.v0.LoginResponse] =
    _root_.io.grpc.MethodDescriptor.newBuilder()
      .setType(_root_.io.grpc.MethodDescriptor.MethodType.UNARY)
      .setFullMethodName(_root_.io.grpc.MethodDescriptor.generateFullMethodName("api.v0.PwaSupportService0", "KeepAlive"))
      .setSampledToLocalTracing(true)
      .setRequestMarshaller(_root_.scalapb.grpc.Marshaller.forMessage[com.google.protobuf.empty.Empty])
      .setResponseMarshaller(_root_.scalapb.grpc.Marshaller.forMessage[api.v0.LoginResponse])
      .setSchemaDescriptor(_root_.scalapb.grpc.ConcreteProtoMethodDescriptorSupplier.fromMethodDescriptor(api.v0.PwaSupportService0Proto.javaDescriptor.getServices().get(0).getMethods().get(1)))
      .build()
  
  val METHOD_GET_TENANT_LIST: _root_.io.grpc.MethodDescriptor[com.google.protobuf.empty.Empty, api.v0.TenantList] =
    _root_.io.grpc.MethodDescriptor.newBuilder()
      .setType(_root_.io.grpc.MethodDescriptor.MethodType.UNARY)
      .setFullMethodName(_root_.io.grpc.MethodDescriptor.generateFullMethodName("api.v0.PwaSupportService0", "GetTenantList"))
      .setSampledToLocalTracing(true)
      .setRequestMarshaller(_root_.scalapb.grpc.Marshaller.forMessage[com.google.protobuf.empty.Empty])
      .setResponseMarshaller(_root_.scalapb.grpc.Marshaller.forMessage[api.v0.TenantList])
      .setSchemaDescriptor(_root_.scalapb.grpc.ConcreteProtoMethodDescriptorSupplier.fromMethodDescriptor(api.v0.PwaSupportService0Proto.javaDescriptor.getServices().get(0).getMethods().get(2)))
      .build()
  
  val METHOD_GET_THING_LIST: _root_.io.grpc.MethodDescriptor[api.v0.TenantId, api.v0.ThingList] =
    _root_.io.grpc.MethodDescriptor.newBuilder()
      .setType(_root_.io.grpc.MethodDescriptor.MethodType.UNARY)
      .setFullMethodName(_root_.io.grpc.MethodDescriptor.generateFullMethodName("api.v0.PwaSupportService0", "GetThingList"))
      .setSampledToLocalTracing(true)
      .setRequestMarshaller(_root_.scalapb.grpc.Marshaller.forMessage[api.v0.TenantId])
      .setResponseMarshaller(_root_.scalapb.grpc.Marshaller.forMessage[api.v0.ThingList])
      .setSchemaDescriptor(_root_.scalapb.grpc.ConcreteProtoMethodDescriptorSupplier.fromMethodDescriptor(api.v0.PwaSupportService0Proto.javaDescriptor.getServices().get(0).getMethods().get(3)))
      .build()
  
  val METHOD_GET_SENSOR_LIST: _root_.io.grpc.MethodDescriptor[api.v0.TenantId, api.v0.SensorList] =
    _root_.io.grpc.MethodDescriptor.newBuilder()
      .setType(_root_.io.grpc.MethodDescriptor.MethodType.UNARY)
      .setFullMethodName(_root_.io.grpc.MethodDescriptor.generateFullMethodName("api.v0.PwaSupportService0", "GetSensorList"))
      .setSampledToLocalTracing(true)
      .setRequestMarshaller(_root_.scalapb.grpc.Marshaller.forMessage[api.v0.TenantId])
      .setResponseMarshaller(_root_.scalapb.grpc.Marshaller.forMessage[api.v0.SensorList])
      .setSchemaDescriptor(_root_.scalapb.grpc.ConcreteProtoMethodDescriptorSupplier.fromMethodDescriptor(api.v0.PwaSupportService0Proto.javaDescriptor.getServices().get(0).getMethods().get(4)))
      .build()
  
  val METHOD_GET_LATEST_SENSOR_VALUE: _root_.io.grpc.MethodDescriptor[api.v0.LatestSensorValueRequest, api.v0.LatestSensorValueResponse] =
    _root_.io.grpc.MethodDescriptor.newBuilder()
      .setType(_root_.io.grpc.MethodDescriptor.MethodType.UNARY)
      .setFullMethodName(_root_.io.grpc.MethodDescriptor.generateFullMethodName("api.v0.PwaSupportService0", "GetLatestSensorValue"))
      .setSampledToLocalTracing(true)
      .setRequestMarshaller(_root_.scalapb.grpc.Marshaller.forMessage[api.v0.LatestSensorValueRequest])
      .setResponseMarshaller(_root_.scalapb.grpc.Marshaller.forMessage[api.v0.LatestSensorValueResponse])
      .setSchemaDescriptor(_root_.scalapb.grpc.ConcreteProtoMethodDescriptorSupplier.fromMethodDescriptor(api.v0.PwaSupportService0Proto.javaDescriptor.getServices().get(0).getMethods().get(5)))
      .build()
  
  val METHOD_GET_SENSOR_VALUES: _root_.io.grpc.MethodDescriptor[api.v0.SensorValuesRequest, api.v0.SensorValuesResponse] =
    _root_.io.grpc.MethodDescriptor.newBuilder()
      .setType(_root_.io.grpc.MethodDescriptor.MethodType.UNARY)
      .setFullMethodName(_root_.io.grpc.MethodDescriptor.generateFullMethodName("api.v0.PwaSupportService0", "GetSensorValues"))
      .setSampledToLocalTracing(true)
      .setRequestMarshaller(_root_.scalapb.grpc.Marshaller.forMessage[api.v0.SensorValuesRequest])
      .setResponseMarshaller(_root_.scalapb.grpc.Marshaller.forMessage[api.v0.SensorValuesResponse])
      .setSchemaDescriptor(_root_.scalapb.grpc.ConcreteProtoMethodDescriptorSupplier.fromMethodDescriptor(api.v0.PwaSupportService0Proto.javaDescriptor.getServices().get(0).getMethods().get(6)))
      .build()
  
  trait PwaSupportService0[-Context] {
    def login(request: api.v0.LoginRequest, context: Context): scala.concurrent.Future[api.v0.LoginResponse]
    def login(request: api.v0.LoginRequest): scala.concurrent.Future[api.v0.LoginResponse]
    /** returns updated token
      */
    def keepAlive(request: com.google.protobuf.empty.Empty, context: Context): scala.concurrent.Future[api.v0.LoginResponse]
    def keepAlive(request: com.google.protobuf.empty.Empty): scala.concurrent.Future[api.v0.LoginResponse]
    /** returns user-visible tenants !
      */
    def getTenantList(request: com.google.protobuf.empty.Empty, context: Context): scala.concurrent.Future[api.v0.TenantList]
    def getTenantList(request: com.google.protobuf.empty.Empty): scala.concurrent.Future[api.v0.TenantList]
    /** returns user-visible things !
      */
    def getThingList(request: api.v0.TenantId, context: Context): scala.concurrent.Future[api.v0.ThingList]
    def getThingList(request: api.v0.TenantId): scala.concurrent.Future[api.v0.ThingList]
    /** returns user-visible sensors !
      */
    def getSensorList(request: api.v0.TenantId, context: Context): scala.concurrent.Future[api.v0.SensorList]
    def getSensorList(request: api.v0.TenantId): scala.concurrent.Future[api.v0.SensorList]
    def getLatestSensorValue(request: api.v0.LatestSensorValueRequest, context: Context): scala.concurrent.Future[api.v0.LatestSensorValueResponse]
    def getLatestSensorValue(request: api.v0.LatestSensorValueRequest): scala.concurrent.Future[api.v0.LatestSensorValueResponse]
    def getSensorValues(request: api.v0.SensorValuesRequest, context: Context): scala.concurrent.Future[api.v0.SensorValuesResponse]
    def getSensorValues(request: api.v0.SensorValuesRequest): scala.concurrent.Future[api.v0.SensorValuesResponse]
  }
  
  private final class PwaSupportService0Stub[Context](channel: _root_.io.grpc.Channel, f: Context => _root_.scalapb.grpcweb.Metadata, defaultContext: => Context, options: _root_.io.grpc.CallOptions = _root_.io.grpc.CallOptions.DEFAULT) extends PwaSupportService0[Context] {
    def login(request: api.v0.LoginRequest, context: Context): scala.concurrent.Future[api.v0.LoginResponse] = {
      _root_.scalapb.grpc.ClientCalls.asyncUnaryCall(channel, METHOD_LOGIN, options, f(context), request)
    }
    def login(request: api.v0.LoginRequest): scala.concurrent.Future[api.v0.LoginResponse] =
      login(request, defaultContext)
    
    /** returns updated token
      */
    def keepAlive(request: com.google.protobuf.empty.Empty, context: Context): scala.concurrent.Future[api.v0.LoginResponse] = {
      _root_.scalapb.grpc.ClientCalls.asyncUnaryCall(channel, METHOD_KEEP_ALIVE, options, f(context), request)
    }
    def keepAlive(request: com.google.protobuf.empty.Empty): scala.concurrent.Future[api.v0.LoginResponse] =
      keepAlive(request, defaultContext)
    
    /** returns user-visible tenants !
      */
    def getTenantList(request: com.google.protobuf.empty.Empty, context: Context): scala.concurrent.Future[api.v0.TenantList] = {
      _root_.scalapb.grpc.ClientCalls.asyncUnaryCall(channel, METHOD_GET_TENANT_LIST, options, f(context), request)
    }
    def getTenantList(request: com.google.protobuf.empty.Empty): scala.concurrent.Future[api.v0.TenantList] =
      getTenantList(request, defaultContext)
    
    /** returns user-visible things !
      */
    def getThingList(request: api.v0.TenantId, context: Context): scala.concurrent.Future[api.v0.ThingList] = {
      _root_.scalapb.grpc.ClientCalls.asyncUnaryCall(channel, METHOD_GET_THING_LIST, options, f(context), request)
    }
    def getThingList(request: api.v0.TenantId): scala.concurrent.Future[api.v0.ThingList] =
      getThingList(request, defaultContext)
    
    /** returns user-visible sensors !
      */
    def getSensorList(request: api.v0.TenantId, context: Context): scala.concurrent.Future[api.v0.SensorList] = {
      _root_.scalapb.grpc.ClientCalls.asyncUnaryCall(channel, METHOD_GET_SENSOR_LIST, options, f(context), request)
    }
    def getSensorList(request: api.v0.TenantId): scala.concurrent.Future[api.v0.SensorList] =
      getSensorList(request, defaultContext)
    
    def getLatestSensorValue(request: api.v0.LatestSensorValueRequest, context: Context): scala.concurrent.Future[api.v0.LatestSensorValueResponse] = {
      _root_.scalapb.grpc.ClientCalls.asyncUnaryCall(channel, METHOD_GET_LATEST_SENSOR_VALUE, options, f(context), request)
    }
    def getLatestSensorValue(request: api.v0.LatestSensorValueRequest): scala.concurrent.Future[api.v0.LatestSensorValueResponse] =
      getLatestSensorValue(request, defaultContext)
    
    def getSensorValues(request: api.v0.SensorValuesRequest, context: Context): scala.concurrent.Future[api.v0.SensorValuesResponse] = {
      _root_.scalapb.grpc.ClientCalls.asyncUnaryCall(channel, METHOD_GET_SENSOR_VALUES, options, f(context), request)
    }
    def getSensorValues(request: api.v0.SensorValuesRequest): scala.concurrent.Future[api.v0.SensorValuesResponse] =
      getSensorValues(request, defaultContext)
    
  }
  
  def stub(channel: _root_.io.grpc.Channel): PwaSupportService0[_root_.scalapb.grpcweb.Metadata] = new PwaSupportService0Stub[_root_.scalapb.grpcweb.Metadata](channel, identity, _root_.scalapb.grpcweb.Metadata.empty)
  
  def stub(channel: _root_.io.grpc.Channel, metadata: _root_.scalapb.grpcweb.Metadata): PwaSupportService0[_root_.scalapb.grpcweb.Metadata] = new PwaSupportService0Stub[_root_.scalapb.grpcweb.Metadata](channel, identity, _root_.scalapb.grpcweb.Metadata.empty)
  
  def stub[Context](channel: _root_.io.grpc.Channel, f: Context => _root_.scalapb.grpcweb.Metadata, defaultContext: Context): PwaSupportService0[Context] = new PwaSupportService0Stub[Context](channel, f, defaultContext)
}