// Generated by the Scala Plugin for the Protocol Buffer Compiler.
// Do not edit!
//
// Protofile syntax: PROTO3

package api.v0

@SerialVersionUID(0L)
final case class TenantList(
    tenants: _root_.scala.Seq[api.v0.Tenant] = _root_.scala.Seq.empty
    ) extends scalapb.GeneratedMessage with scalapb.lenses.Updatable[TenantList] {
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      tenants.foreach { __item =>
        val __value = __item
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      }
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      tenants.foreach { __v =>
        val __m = __v
        _output__.writeTag(1, 2)
        _output__.writeUInt32NoTag(__m.serializedSize)
        __m.writeTo(_output__)
      };
    }
    def clearTenants = copy(tenants = _root_.scala.Seq.empty)
    def addTenants(__vs: api.v0.Tenant *): TenantList = addAllTenants(__vs)
    def addAllTenants(__vs: Iterable[api.v0.Tenant]): TenantList = copy(tenants = tenants ++ __vs)
    def withTenants(__v: _root_.scala.Seq[api.v0.Tenant]): TenantList = copy(tenants = __v)
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => tenants
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => _root_.scalapb.descriptors.PRepeated(tenants.iterator.map(_.toPMessage).toVector)
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.TenantList.type = api.v0.TenantList
    // @@protoc_insertion_point(GeneratedMessage[api.v0.TenantList])
}

object TenantList extends scalapb.GeneratedMessageCompanion[api.v0.TenantList] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.TenantList] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.TenantList = {
    val __tenants: _root_.scala.collection.immutable.VectorBuilder[api.v0.Tenant] = new _root_.scala.collection.immutable.VectorBuilder[api.v0.Tenant]
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 10 =>
          __tenants += _root_.scalapb.LiteParser.readMessage[api.v0.Tenant](_input__)
        case tag => _input__.skipField(tag)
      }
    }
    api.v0.TenantList(
        tenants = __tenants.result()
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.TenantList] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.TenantList(
        tenants = __fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).map(_.as[_root_.scala.Seq[api.v0.Tenant]]).getOrElse(_root_.scala.Seq.empty)
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = PwaSupportService0Proto.javaDescriptor.getMessageTypes().get(4)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = PwaSupportService0Proto.scalaDescriptor.messages(4)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = {
    var __out: _root_.scalapb.GeneratedMessageCompanion[_] = null
    (__number: @_root_.scala.unchecked) match {
      case 1 => __out = api.v0.Tenant
    }
    __out
  }
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = throw new MatchError(__fieldNumber)
  lazy val defaultInstance = api.v0.TenantList(
    tenants = _root_.scala.Seq.empty
  )
  implicit class TenantListLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.TenantList]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.TenantList](_l) {
    def tenants: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Seq[api.v0.Tenant]] = field(_.tenants)((c_, f_) => c_.copy(tenants = f_))
  }
  final val TENANTS_FIELD_NUMBER = 1
  def of(
    tenants: _root_.scala.Seq[api.v0.Tenant]
  ): _root_.api.v0.TenantList = _root_.api.v0.TenantList(
    tenants
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.TenantList])
}
