package pwa.circuit

import diode.{Action, ActionHandler, ActionResult, ModelRW}
import pwa.Page

case class AfterLoginPageChange(maybePage : Option[Page]) extends Action

class AfterLoginPageChangeHandler[M](modelRW: ModelRW[M, Option[Page]]) extends ActionHandler(modelRW) {

  override protected def handle: PartialFunction[Any, ActionResult[M]] = {
    case AfterLoginPageChange(maybePage) => {
      scribe.debug(s"new after login page ${maybePage}")
      updated(maybePage)
    }
  }
}
