package pwa.circuit

import api.v0._
import diode.data._
import diode.{ActionResult, Dispatcher, ModelR, ModelRW}
import org.scalajs.macrotaskexecutor.MacrotaskExecutor.Implicits._
import pwa.model.{PwaSupportService, SensorInfo, Sensors}
import pwa.util.ProperAsyncAction

import java.time.Instant
import java.time.temporal.ChronoUnit
import scala.concurrent.Future
import scala.util.{Failure, Try}

case class UpdateSensorMeasurements(sensorId: SensorId,
                                    keys: Set[MeasurementType],
                                    state: PotState = PotState.PotEmpty,
                                    result: Try[Map[MeasurementType, Pot[MeasurementList]]] = Failure(new AsyncAction.PendingException)
                                   ) extends ProperAsyncAction[Map[MeasurementType, Pot[MeasurementList]], MeasurementType, MeasurementList, UpdateSensorMeasurements] {


  override def shouldFetch(pot: Pot[MeasurementList]): Boolean = super.shouldFetchOnlyEmpty(pot)

  override def next(newState: PotState, newValue: Try[Map[MeasurementType, Pot[MeasurementList]]]): UpdateSensorMeasurements = {
    scribe.debug(s"updating UpdateSensorMeasurements with ${newState} state and value ${newValue} (current ${result})")
    UpdateSensorMeasurements(sensorId, keys, newState, newValue)
  }
}

class MeasurementFetch(val sensorId: SensorId, dispatch: Dispatcher) extends Fetch[MeasurementType] {
  override def fetch(key: MeasurementType): Unit = {
    scribe.debug(s"dispatching fetch for ${sensorId} of ${key}")
    dispatch(UpdateSensorMeasurements(sensorId = sensorId, keys = Set(key)))
  }

  override def fetch(keys: Iterable[MeasurementType]): Unit = {
    scribe.debug(s"dispatching fetch for ${sensorId} of ${keys}")
    dispatch(UpdateSensorMeasurements(sensorId = sensorId, keys = Set() ++ keys))
  }
}

class UpdateSensorMeasurementsHandler[M](
                                          dispatch: Dispatcher,
                                          modelRW: ModelRW[M, Sensors],
                                          api: ModelR[M, PwaSupportService],
                                          currentTenant: RefTo[Option[Tenant]],
                                        ) extends ApiActionHandler(modelRW, api, dispatch) {

  def load(sensorId: SensorId, measurementTypes: Set[MeasurementType]): Future[Map[MeasurementType, Pot[MeasurementList]]] = {
    val intervalEnd: Instant = Instant.now()
    val intervalBegin: Instant = Instant.now().minus(1, ChronoUnit.DAYS)

    def fetchCall: MeasurementType => Future[Pot[MeasurementList]] = (key: MeasurementType) => api.value.getSensorValues(SensorValuesRequest(
      sensorId = sensorId,
      measurementType = key,
      timeInterval = TimeInterval(begin = intervalBegin, end = intervalEnd)
    )).map((result: SensorValuesResponse) => {
      Ready(result.measurements)
    })

    scribe.debug("triggering api call")
    val setOfFutures: Set[Future[(MeasurementType, Pot[MeasurementList])]] = measurementTypes.map(measurementType => fetchCall(measurementType).map(measurements => measurementType -> measurements))
    val futureOfMap: Future[Map[MeasurementType, Pot[MeasurementList]]] = Future.foldLeft(setOfFutures)(Map[MeasurementType, Pot[MeasurementList]]())(_ + _)
    futureOfMap
  }

  // TODO: shared code with UpdateSensorLastMeasurement, move to common trait ?
  private def zoomToSensorLatest(sensorId: SensorId, rw: ModelRW[M, Sensors]): Option[ModelRW[M, PotMap[MeasurementType, MeasurementList]]] = {
    val sensorModel: ModelRW[M, Pot[SensorInfo]] = rw.zoomRW(_.get(sensorId))((sensorMap: Sensors, potValue: Pot[SensorInfo]) => if (potValue.isEmpty) {
      sensorMap
    } else {
      sensorMap + (potValue.get.sensor.id, potValue)
    })

    if (sensorModel.value.isEmpty) {
      None
    } else {
      val actual: ModelRW[M, PotMap[MeasurementType, MeasurementList]] = sensorModel
        .zoomRW(pot => pot.get)((current: Pot[SensorInfo], v: SensorInfo) => Ready(v))
        .zoomTo(_.measurements)
      Some(actual)
    }
  }

  override protected def handle: PartialFunction[Any, ActionResult[M]] = {
    case action: UpdateSensorMeasurements => {
      scribe.debug(s"handling action: ${action} (with potstate ${action.state})")
      zoomToSensorLatest(action.sensorId, modelRW) match {
        case None => noChange
        case Some(innerModel: ModelRW[M, PotMap[MeasurementType, MeasurementList]]) => {
          scribe.debug(s"keys before handle: ${action.keys.size} = ${action.keys}")
          action.handleProperly(innerModel, (action: UpdateSensorMeasurements, keys: Set[MeasurementType]) => action.effect(load(action.sensorId, keys))(success = identity, failure = redirectToLoginIfNeeded))
        }
      }
    }
  }
}
