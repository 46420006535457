// Generated by the Scala Plugin for the Protocol Buffer Compiler.
// Do not edit!
//
// Protofile syntax: PROTO3

package api.v0
import api.v0.JavaNetInstantTypeMapper._

/** @param sensorTypeText
  *   TODO: _maybe_ allow displaying protocol types
  */
@SerialVersionUID(0L)
final case class Sensor(
    id: api.v0.SensorId,
    thing: api.v0.ThingId,
    comment: _root_.scala.Predef.String = "",
    sensorTypeText: _root_.scala.Predef.String = "",
    measurementTypes: api.v0.MeasurementTypeList
    ) extends scalapb.GeneratedMessage with scalapb.lenses.Updatable[Sensor] {
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      
      {
        val __value = id
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      
      {
        val __value = thing
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      
      {
        val __value = comment
        if (!__value.isEmpty) {
          __size += _root_.com.google.protobuf.CodedOutputStream.computeStringSize(3, __value)
        }
      };
      
      {
        val __value = sensorTypeText
        if (!__value.isEmpty) {
          __size += _root_.com.google.protobuf.CodedOutputStream.computeStringSize(4, __value)
        }
      };
      
      {
        val __value = measurementTypes
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      
      {
        val __v = id
        _output__.writeTag(1, 2)
        _output__.writeUInt32NoTag(__v.serializedSize)
        __v.writeTo(_output__)
      };
      
      {
        val __v = thing
        _output__.writeTag(2, 2)
        _output__.writeUInt32NoTag(__v.serializedSize)
        __v.writeTo(_output__)
      };
      {
        val __v = comment
        if (!__v.isEmpty) {
          _output__.writeString(3, __v)
        }
      };
      {
        val __v = sensorTypeText
        if (!__v.isEmpty) {
          _output__.writeString(4, __v)
        }
      };
      
      {
        val __v = measurementTypes
        _output__.writeTag(5, 2)
        _output__.writeUInt32NoTag(__v.serializedSize)
        __v.writeTo(_output__)
      };
    }
    def withId(__v: api.v0.SensorId): Sensor = copy(id = __v)
    def withThing(__v: api.v0.ThingId): Sensor = copy(thing = __v)
    def withComment(__v: _root_.scala.Predef.String): Sensor = copy(comment = __v)
    def withSensorTypeText(__v: _root_.scala.Predef.String): Sensor = copy(sensorTypeText = __v)
    def withMeasurementTypes(__v: api.v0.MeasurementTypeList): Sensor = copy(measurementTypes = __v)
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => id
        case 2 => thing
        case 3 => {
          val __t = comment
          if (__t != "") __t else null
        }
        case 4 => {
          val __t = sensorTypeText
          if (__t != "") __t else null
        }
        case 5 => measurementTypes
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => id.toPMessage
        case 2 => thing.toPMessage
        case 3 => _root_.scalapb.descriptors.PString(comment)
        case 4 => _root_.scalapb.descriptors.PString(sensorTypeText)
        case 5 => measurementTypes.toPMessage
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.Sensor.type = api.v0.Sensor
    // @@protoc_insertion_point(GeneratedMessage[api.v0.Sensor])
}

object Sensor extends scalapb.GeneratedMessageCompanion[api.v0.Sensor] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.Sensor] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.Sensor = {
    var __requiredFields0: _root_.scala.Long = 0x7L
    var __id: _root_.scala.Option[api.v0.SensorId] = _root_.scala.None
    var __thing: _root_.scala.Option[api.v0.ThingId] = _root_.scala.None
    var __comment: _root_.scala.Predef.String = ""
    var __sensorTypeText: _root_.scala.Predef.String = ""
    var __measurementTypes: _root_.scala.Option[api.v0.MeasurementTypeList] = _root_.scala.None
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 10 =>
          __id = _root_.scala.Some(__id.fold(_root_.scalapb.LiteParser.readMessage[api.v0.SensorId](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
          __requiredFields0 &= 0xfffffffffffffffeL
        case 18 =>
          __thing = _root_.scala.Some(__thing.fold(_root_.scalapb.LiteParser.readMessage[api.v0.ThingId](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
          __requiredFields0 &= 0xfffffffffffffffdL
        case 26 =>
          __comment = _input__.readStringRequireUtf8()
        case 34 =>
          __sensorTypeText = _input__.readStringRequireUtf8()
        case 42 =>
          __measurementTypes = _root_.scala.Some(__measurementTypes.fold(_root_.scalapb.LiteParser.readMessage[api.v0.MeasurementTypeList](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
          __requiredFields0 &= 0xfffffffffffffffbL
        case tag => _input__.skipField(tag)
      }
    }
    if (__requiredFields0 != 0L) { throw new _root_.com.google.protobuf.InvalidProtocolBufferException("Message missing required fields.") } 
    api.v0.Sensor(
        id = __id.getOrElse(api.v0.SensorId.defaultInstance),
        thing = __thing.getOrElse(api.v0.ThingId.defaultInstance),
        comment = __comment,
        sensorTypeText = __sensorTypeText,
        measurementTypes = __measurementTypes.getOrElse(api.v0.MeasurementTypeList.defaultInstance)
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.Sensor] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.Sensor(
        id = __fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).get.as[api.v0.SensorId],
        thing = __fieldsMap.get(scalaDescriptor.findFieldByNumber(2).get).get.as[api.v0.ThingId],
        comment = __fieldsMap.get(scalaDescriptor.findFieldByNumber(3).get).map(_.as[_root_.scala.Predef.String]).getOrElse(""),
        sensorTypeText = __fieldsMap.get(scalaDescriptor.findFieldByNumber(4).get).map(_.as[_root_.scala.Predef.String]).getOrElse(""),
        measurementTypes = __fieldsMap.get(scalaDescriptor.findFieldByNumber(5).get).get.as[api.v0.MeasurementTypeList]
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = TypesProto.javaDescriptor.getMessageTypes().get(7)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = TypesProto.scalaDescriptor.messages(7)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = {
    var __out: _root_.scalapb.GeneratedMessageCompanion[_] = null
    (__number: @_root_.scala.unchecked) match {
      case 1 => __out = api.v0.SensorId
      case 2 => __out = api.v0.ThingId
      case 5 => __out = api.v0.MeasurementTypeList
    }
    __out
  }
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = throw new MatchError(__fieldNumber)
  lazy val defaultInstance = api.v0.Sensor(
    id = api.v0.SensorId.defaultInstance,
    thing = api.v0.ThingId.defaultInstance,
    comment = "",
    sensorTypeText = "",
    measurementTypes = api.v0.MeasurementTypeList.defaultInstance
  )
  implicit class SensorLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.Sensor]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.Sensor](_l) {
    def id: _root_.scalapb.lenses.Lens[UpperPB, api.v0.SensorId] = field(_.id)((c_, f_) => c_.copy(id = f_))
    def thing: _root_.scalapb.lenses.Lens[UpperPB, api.v0.ThingId] = field(_.thing)((c_, f_) => c_.copy(thing = f_))
    def comment: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Predef.String] = field(_.comment)((c_, f_) => c_.copy(comment = f_))
    def sensorTypeText: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Predef.String] = field(_.sensorTypeText)((c_, f_) => c_.copy(sensorTypeText = f_))
    def measurementTypes: _root_.scalapb.lenses.Lens[UpperPB, api.v0.MeasurementTypeList] = field(_.measurementTypes)((c_, f_) => c_.copy(measurementTypes = f_))
  }
  final val ID_FIELD_NUMBER = 1
  final val THING_FIELD_NUMBER = 2
  final val COMMENT_FIELD_NUMBER = 3
  final val SENSOR_TYPE_TEXT_FIELD_NUMBER = 4
  final val MEASUREMENT_TYPES_FIELD_NUMBER = 5
  def of(
    id: api.v0.SensorId,
    thing: api.v0.ThingId,
    comment: _root_.scala.Predef.String,
    sensorTypeText: _root_.scala.Predef.String,
    measurementTypes: api.v0.MeasurementTypeList
  ): _root_.api.v0.Sensor = _root_.api.v0.Sensor(
    id,
    thing,
    comment,
    sensorTypeText,
    measurementTypes
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.Sensor])
}
