package pwa.screens

import diode.data.Pot
import diode.react.{ModelProxy, ReactConnectProxy}
import japgolly.scalajs.react.component.builder.Lifecycle.ComponentDidMount
import japgolly.scalajs.react.extra.router.RouterCtl
import japgolly.scalajs.react.vdom.html_<^._
import japgolly.scalajs.react.{BackendScope, Callback, ScalaComponent}
import pwa.Page
import pwa.circuit.{RefreshThingList, UpdateSensorList}
import pwa.model.{RootModel, Sensors, Tenants, Things}
import react.common.syntax.vdom._
import react.semanticui.elements.container.Container


object HomeScreen {
  case class Props(routerCtl: RouterCtl[Page], proxy: ModelProxy[RootModel])

  case class State(
                    tenantListProxy: ReactConnectProxy[Pot[Tenants]],
                    thingListProxy: ReactConnectProxy[Pot[Things]],
                    sensorListProxy: ReactConnectProxy[Sensors],
                  )

  case class Backend(bs: BackendScope[Props, State]) {

    def didMount(cdm: ComponentDidMount[Props, State, Backend]): Callback = {
      // NOTE: this does not work, at the moment, api token is not available for refresh
      Callback {
        cdm.props.proxy.dispatchNow(RefreshThingList())
        cdm.props.proxy.dispatchNow(UpdateSensorList.all)
      }
    }

    def render(props: Props, state: State) = {

      val thingListProxy = state.thingListProxy
      val sensorListProxy = state.sensorListProxy

      <.div(
        Container(className = "ui statistics")(
          thingListProxy((thingListModelProxy: ModelProxy[Pot[Things]]) => {
            val potThings: Pot[Things] = thingListModelProxy()
            if (potThings.nonEmpty) {
              val things = potThings.get
              Container(className = "ui statistic")(
                <.div(^.cls := "value")(
                  things.seq.length
                ),
                <.div(^.cls := "label")(
                  "Things"
                ),
              ).vdomElement
            } else {
              <.div("Error retrieving thing list")
            }
          }
          ),
          sensorListProxy((sensorListModelProxy: ModelProxy[Sensors]) => {
            val sensors: Sensors = sensorListModelProxy()
            Container(className = "ui statistic")(
              <.div(^.cls := "value")(
                sensors.size
              ),
              <.div(^.cls := "label")(
                "Sensors"
              ),
            ).vdomElement
          }
          ),
        )
      )
    }
  }

  private val component = ScalaComponent.builder[Props]
    .initialStateFromProps(props => State(
      tenantListProxy = props.proxy.connect(_.tenants),
      thingListProxy = props.proxy.connect(_.things),
      sensorListProxy = props.proxy.connect(_.sensors),
    ))
    .renderBackend[Backend]
    .componentDidMount((x: ComponentDidMount[Props, State, Backend]) => x.backend.didMount(x))
    .build

  def apply(routerCtl: RouterCtl[Page], proxy: ModelProxy[RootModel]) = component(Props(routerCtl, proxy))
}
