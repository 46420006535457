package pwa.circuit

import api.v0.Tenant
import diode._
import org.scalajs.macrotaskexecutor.MacrotaskExecutor.Implicits._
import pwa.Page

// for user directed action
case class SetCurrentTenant(currentTenant: Tenant) extends Action

// for default selection after login
case class AtLoginWeakSetCurrentTenant(currentTenant: Tenant) extends Action

// for logout
case class UnsetCurrentTenant() extends Action

class ChangeCurrentTenantHandler[M](modelRW: ModelRW[M, Option[Tenant]], afterLoginPage: ModelRW[M, Option[Page]]) extends ActionHandler(modelRW) {

  override protected def handle: PartialFunction[Any, ActionResult[M]] = {
    case a@AtLoginWeakSetCurrentTenant(possibleCurrentTenant) => {
      println(s"received ${a}")

      // TODO: this may need change for silent re-login using stored token...
      val nextPage: pwa.Page = afterLoginPage.value.getOrElse(Page.Home)

      value match {
        case Some(existingCurrentTenant) => {
          // Re-login case, no need to clear tenant-level data,
          // only redirect to useful page
          effectOnly(Effect.action(PageChangeNeeded(nextPage)))
        }
        case None => {
          println(s"no existing current tenant, setting to ${possibleCurrentTenant}")
          this.updated(Some(possibleCurrentTenant), AppCircuitHelper.triggerClearTenantLevel() << Effect.action(PageChangeNeeded(nextPage)))
        }
      }
    }
    case a@SetCurrentTenant(currentTenant) => {
      println(s"received ${a}")
      this.updated(Some(currentTenant), AppCircuitHelper.triggerClearTenantLevel())
    }
    case a: UnsetCurrentTenant => {
      println(s"received ${a}")
      this.updated(None, AppCircuitHelper.triggerClearTenantLevel())
    }
  }
}
