// Generated by the Scala Plugin for the Protocol Buffer Compiler.
// Do not edit!
//
// Protofile syntax: PROTO3

package api.v0
import api.v0.JavaNetInstantTypeMapper._

sealed trait Measurement extends scalapb.GeneratedSealedOneof {
  type MessageType = api.v0.MeasurementMessage
  final def isEmpty = this.isInstanceOf[api.v0.Measurement.Empty.type]
  final def isDefined = !isEmpty
  final def asMessage: api.v0.MeasurementMessage = api.v0.Measurement.MeasurementTypeMapper.toBase(this)
  final def asNonEmpty: Option[api.v0.Measurement.NonEmpty] = if (isEmpty) None else Some(this.asInstanceOf[api.v0.Measurement.NonEmpty])
}

object Measurement {
  case object Empty extends api.v0.Measurement
  
  sealed trait NonEmpty extends api.v0.Measurement
  def defaultInstance: api.v0.Measurement = Empty
  
  implicit val MeasurementTypeMapper: _root_.scalapb.TypeMapper[api.v0.MeasurementMessage, api.v0.Measurement] = new _root_.scalapb.TypeMapper[api.v0.MeasurementMessage, api.v0.Measurement] {
    override def toCustom(__base: api.v0.MeasurementMessage): api.v0.Measurement = __base.sealedValue match {
      case __v: api.v0.MeasurementMessage.SealedValue.Temperature => __v.value
      case __v: api.v0.MeasurementMessage.SealedValue.Pressure => __v.value
      case __v: api.v0.MeasurementMessage.SealedValue.RelativeHumidity => __v.value
      case __v: api.v0.MeasurementMessage.SealedValue.Voltage => __v.value
      case __v: api.v0.MeasurementMessage.SealedValue.Position => __v.value
      case api.v0.MeasurementMessage.SealedValue.Empty => Empty
    }
    override def toBase(__custom: api.v0.Measurement): api.v0.MeasurementMessage = api.v0.MeasurementMessage(__custom match {
      case __v: api.v0.TemperatureMeasurement => api.v0.MeasurementMessage.SealedValue.Temperature(__v)
      case __v: api.v0.PressureMeasurement => api.v0.MeasurementMessage.SealedValue.Pressure(__v)
      case __v: api.v0.RelativeHumidityMeasurement => api.v0.MeasurementMessage.SealedValue.RelativeHumidity(__v)
      case __v: api.v0.VoltageMeasurement => api.v0.MeasurementMessage.SealedValue.Voltage(__v)
      case __v: api.v0.PositionMeasurement => api.v0.MeasurementMessage.SealedValue.Position(__v)
      case Empty => api.v0.MeasurementMessage.SealedValue.Empty
    })
  }
}
@SerialVersionUID(0L)
final case class MeasurementMessage(
    sealedValue: api.v0.MeasurementMessage.SealedValue = api.v0.MeasurementMessage.SealedValue.Empty
    ) extends scalapb.GeneratedMessage with scalapb.lenses.Updatable[MeasurementMessage] {
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      if (sealedValue.temperature.isDefined) {
        val __value = sealedValue.temperature.get
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      if (sealedValue.pressure.isDefined) {
        val __value = sealedValue.pressure.get
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      if (sealedValue.relativeHumidity.isDefined) {
        val __value = sealedValue.relativeHumidity.get
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      if (sealedValue.voltage.isDefined) {
        val __value = sealedValue.voltage.get
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      if (sealedValue.position.isDefined) {
        val __value = sealedValue.position.get
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      sealedValue.temperature.foreach { __v =>
        val __m = __v
        _output__.writeTag(1, 2)
        _output__.writeUInt32NoTag(__m.serializedSize)
        __m.writeTo(_output__)
      };
      sealedValue.pressure.foreach { __v =>
        val __m = __v
        _output__.writeTag(2, 2)
        _output__.writeUInt32NoTag(__m.serializedSize)
        __m.writeTo(_output__)
      };
      sealedValue.relativeHumidity.foreach { __v =>
        val __m = __v
        _output__.writeTag(3, 2)
        _output__.writeUInt32NoTag(__m.serializedSize)
        __m.writeTo(_output__)
      };
      sealedValue.voltage.foreach { __v =>
        val __m = __v
        _output__.writeTag(4, 2)
        _output__.writeUInt32NoTag(__m.serializedSize)
        __m.writeTo(_output__)
      };
      sealedValue.position.foreach { __v =>
        val __m = __v
        _output__.writeTag(5, 2)
        _output__.writeUInt32NoTag(__m.serializedSize)
        __m.writeTo(_output__)
      };
    }
    def getTemperature: api.v0.TemperatureMeasurement = sealedValue.temperature.getOrElse(api.v0.TemperatureMeasurement.defaultInstance)
    def withTemperature(__v: api.v0.TemperatureMeasurement): MeasurementMessage = copy(sealedValue = api.v0.MeasurementMessage.SealedValue.Temperature(__v))
    def getPressure: api.v0.PressureMeasurement = sealedValue.pressure.getOrElse(api.v0.PressureMeasurement.defaultInstance)
    def withPressure(__v: api.v0.PressureMeasurement): MeasurementMessage = copy(sealedValue = api.v0.MeasurementMessage.SealedValue.Pressure(__v))
    def getRelativeHumidity: api.v0.RelativeHumidityMeasurement = sealedValue.relativeHumidity.getOrElse(api.v0.RelativeHumidityMeasurement.defaultInstance)
    def withRelativeHumidity(__v: api.v0.RelativeHumidityMeasurement): MeasurementMessage = copy(sealedValue = api.v0.MeasurementMessage.SealedValue.RelativeHumidity(__v))
    def getVoltage: api.v0.VoltageMeasurement = sealedValue.voltage.getOrElse(api.v0.VoltageMeasurement.defaultInstance)
    def withVoltage(__v: api.v0.VoltageMeasurement): MeasurementMessage = copy(sealedValue = api.v0.MeasurementMessage.SealedValue.Voltage(__v))
    def getPosition: api.v0.PositionMeasurement = sealedValue.position.getOrElse(api.v0.PositionMeasurement.defaultInstance)
    def withPosition(__v: api.v0.PositionMeasurement): MeasurementMessage = copy(sealedValue = api.v0.MeasurementMessage.SealedValue.Position(__v))
    def clearSealedValue: MeasurementMessage = copy(sealedValue = api.v0.MeasurementMessage.SealedValue.Empty)
    def withSealedValue(__v: api.v0.MeasurementMessage.SealedValue): MeasurementMessage = copy(sealedValue = __v)
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => sealedValue.temperature.orNull
        case 2 => sealedValue.pressure.orNull
        case 3 => sealedValue.relativeHumidity.orNull
        case 4 => sealedValue.voltage.orNull
        case 5 => sealedValue.position.orNull
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => sealedValue.temperature.map(_.toPMessage).getOrElse(_root_.scalapb.descriptors.PEmpty)
        case 2 => sealedValue.pressure.map(_.toPMessage).getOrElse(_root_.scalapb.descriptors.PEmpty)
        case 3 => sealedValue.relativeHumidity.map(_.toPMessage).getOrElse(_root_.scalapb.descriptors.PEmpty)
        case 4 => sealedValue.voltage.map(_.toPMessage).getOrElse(_root_.scalapb.descriptors.PEmpty)
        case 5 => sealedValue.position.map(_.toPMessage).getOrElse(_root_.scalapb.descriptors.PEmpty)
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.MeasurementMessage.type = api.v0.MeasurementMessage
    def toMeasurement: api.v0.Measurement = api.v0.Measurement.MeasurementTypeMapper.toCustom(this)
    // @@protoc_insertion_point(GeneratedMessage[api.v0.Measurement])
}

object MeasurementMessage extends scalapb.GeneratedMessageCompanion[api.v0.MeasurementMessage] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.MeasurementMessage] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.MeasurementMessage = {
    var __sealedValue: api.v0.MeasurementMessage.SealedValue = api.v0.MeasurementMessage.SealedValue.Empty
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 10 =>
          __sealedValue = api.v0.MeasurementMessage.SealedValue.Temperature(__sealedValue.temperature.fold(_root_.scalapb.LiteParser.readMessage[api.v0.TemperatureMeasurement](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
        case 18 =>
          __sealedValue = api.v0.MeasurementMessage.SealedValue.Pressure(__sealedValue.pressure.fold(_root_.scalapb.LiteParser.readMessage[api.v0.PressureMeasurement](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
        case 26 =>
          __sealedValue = api.v0.MeasurementMessage.SealedValue.RelativeHumidity(__sealedValue.relativeHumidity.fold(_root_.scalapb.LiteParser.readMessage[api.v0.RelativeHumidityMeasurement](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
        case 34 =>
          __sealedValue = api.v0.MeasurementMessage.SealedValue.Voltage(__sealedValue.voltage.fold(_root_.scalapb.LiteParser.readMessage[api.v0.VoltageMeasurement](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
        case 42 =>
          __sealedValue = api.v0.MeasurementMessage.SealedValue.Position(__sealedValue.position.fold(_root_.scalapb.LiteParser.readMessage[api.v0.PositionMeasurement](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
        case tag => _input__.skipField(tag)
      }
    }
    api.v0.MeasurementMessage(
        sealedValue = __sealedValue
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.MeasurementMessage] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.MeasurementMessage(
        sealedValue = __fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).flatMap(_.as[_root_.scala.Option[api.v0.TemperatureMeasurement]]).map(api.v0.MeasurementMessage.SealedValue.Temperature(_))
            .orElse[api.v0.MeasurementMessage.SealedValue](__fieldsMap.get(scalaDescriptor.findFieldByNumber(2).get).flatMap(_.as[_root_.scala.Option[api.v0.PressureMeasurement]]).map(api.v0.MeasurementMessage.SealedValue.Pressure(_)))
            .orElse[api.v0.MeasurementMessage.SealedValue](__fieldsMap.get(scalaDescriptor.findFieldByNumber(3).get).flatMap(_.as[_root_.scala.Option[api.v0.RelativeHumidityMeasurement]]).map(api.v0.MeasurementMessage.SealedValue.RelativeHumidity(_)))
            .orElse[api.v0.MeasurementMessage.SealedValue](__fieldsMap.get(scalaDescriptor.findFieldByNumber(4).get).flatMap(_.as[_root_.scala.Option[api.v0.VoltageMeasurement]]).map(api.v0.MeasurementMessage.SealedValue.Voltage(_)))
            .orElse[api.v0.MeasurementMessage.SealedValue](__fieldsMap.get(scalaDescriptor.findFieldByNumber(5).get).flatMap(_.as[_root_.scala.Option[api.v0.PositionMeasurement]]).map(api.v0.MeasurementMessage.SealedValue.Position(_)))
            .getOrElse(api.v0.MeasurementMessage.SealedValue.Empty)
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = TypesProto.javaDescriptor.getMessageTypes().get(20)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = TypesProto.scalaDescriptor.messages(20)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = {
    var __out: _root_.scalapb.GeneratedMessageCompanion[_] = null
    (__number: @_root_.scala.unchecked) match {
      case 1 => __out = api.v0.TemperatureMeasurement
      case 2 => __out = api.v0.PressureMeasurement
      case 3 => __out = api.v0.RelativeHumidityMeasurement
      case 4 => __out = api.v0.VoltageMeasurement
      case 5 => __out = api.v0.PositionMeasurement
    }
    __out
  }
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = throw new MatchError(__fieldNumber)
  lazy val defaultInstance = api.v0.MeasurementMessage(
    sealedValue = api.v0.MeasurementMessage.SealedValue.Empty
  )
  sealed trait SealedValue extends _root_.scalapb.GeneratedOneof {
    def isEmpty: _root_.scala.Boolean = false
    def isDefined: _root_.scala.Boolean = true
    def isTemperature: _root_.scala.Boolean = false
    def isPressure: _root_.scala.Boolean = false
    def isRelativeHumidity: _root_.scala.Boolean = false
    def isVoltage: _root_.scala.Boolean = false
    def isPosition: _root_.scala.Boolean = false
    def temperature: _root_.scala.Option[api.v0.TemperatureMeasurement] = _root_.scala.None
    def pressure: _root_.scala.Option[api.v0.PressureMeasurement] = _root_.scala.None
    def relativeHumidity: _root_.scala.Option[api.v0.RelativeHumidityMeasurement] = _root_.scala.None
    def voltage: _root_.scala.Option[api.v0.VoltageMeasurement] = _root_.scala.None
    def position: _root_.scala.Option[api.v0.PositionMeasurement] = _root_.scala.None
  }
  object SealedValue {
    @SerialVersionUID(0L)
    case object Empty extends api.v0.MeasurementMessage.SealedValue {
      type ValueType = _root_.scala.Nothing
      override def isEmpty: _root_.scala.Boolean = true
      override def isDefined: _root_.scala.Boolean = false
      override def number: _root_.scala.Int = 0
      override def value: _root_.scala.Nothing = throw new java.util.NoSuchElementException("Empty.value")
    }
  
    @SerialVersionUID(0L)
    final case class Temperature(value: api.v0.TemperatureMeasurement) extends api.v0.MeasurementMessage.SealedValue {
      type ValueType = api.v0.TemperatureMeasurement
      override def isTemperature: _root_.scala.Boolean = true
      override def temperature: _root_.scala.Option[api.v0.TemperatureMeasurement] = Some(value)
      override def number: _root_.scala.Int = 1
    }
    @SerialVersionUID(0L)
    final case class Pressure(value: api.v0.PressureMeasurement) extends api.v0.MeasurementMessage.SealedValue {
      type ValueType = api.v0.PressureMeasurement
      override def isPressure: _root_.scala.Boolean = true
      override def pressure: _root_.scala.Option[api.v0.PressureMeasurement] = Some(value)
      override def number: _root_.scala.Int = 2
    }
    @SerialVersionUID(0L)
    final case class RelativeHumidity(value: api.v0.RelativeHumidityMeasurement) extends api.v0.MeasurementMessage.SealedValue {
      type ValueType = api.v0.RelativeHumidityMeasurement
      override def isRelativeHumidity: _root_.scala.Boolean = true
      override def relativeHumidity: _root_.scala.Option[api.v0.RelativeHumidityMeasurement] = Some(value)
      override def number: _root_.scala.Int = 3
    }
    @SerialVersionUID(0L)
    final case class Voltage(value: api.v0.VoltageMeasurement) extends api.v0.MeasurementMessage.SealedValue {
      type ValueType = api.v0.VoltageMeasurement
      override def isVoltage: _root_.scala.Boolean = true
      override def voltage: _root_.scala.Option[api.v0.VoltageMeasurement] = Some(value)
      override def number: _root_.scala.Int = 4
    }
    @SerialVersionUID(0L)
    final case class Position(value: api.v0.PositionMeasurement) extends api.v0.MeasurementMessage.SealedValue {
      type ValueType = api.v0.PositionMeasurement
      override def isPosition: _root_.scala.Boolean = true
      override def position: _root_.scala.Option[api.v0.PositionMeasurement] = Some(value)
      override def number: _root_.scala.Int = 5
    }
  }
  implicit class MeasurementMessageLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.MeasurementMessage]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.MeasurementMessage](_l) {
    def temperature: _root_.scalapb.lenses.Lens[UpperPB, api.v0.TemperatureMeasurement] = field(_.getTemperature)((c_, f_) => c_.copy(sealedValue = api.v0.MeasurementMessage.SealedValue.Temperature(f_)))
    def pressure: _root_.scalapb.lenses.Lens[UpperPB, api.v0.PressureMeasurement] = field(_.getPressure)((c_, f_) => c_.copy(sealedValue = api.v0.MeasurementMessage.SealedValue.Pressure(f_)))
    def relativeHumidity: _root_.scalapb.lenses.Lens[UpperPB, api.v0.RelativeHumidityMeasurement] = field(_.getRelativeHumidity)((c_, f_) => c_.copy(sealedValue = api.v0.MeasurementMessage.SealedValue.RelativeHumidity(f_)))
    def voltage: _root_.scalapb.lenses.Lens[UpperPB, api.v0.VoltageMeasurement] = field(_.getVoltage)((c_, f_) => c_.copy(sealedValue = api.v0.MeasurementMessage.SealedValue.Voltage(f_)))
    def position: _root_.scalapb.lenses.Lens[UpperPB, api.v0.PositionMeasurement] = field(_.getPosition)((c_, f_) => c_.copy(sealedValue = api.v0.MeasurementMessage.SealedValue.Position(f_)))
    def sealedValue: _root_.scalapb.lenses.Lens[UpperPB, api.v0.MeasurementMessage.SealedValue] = field(_.sealedValue)((c_, f_) => c_.copy(sealedValue = f_))
  }
  final val TEMPERATURE_FIELD_NUMBER = 1
  final val PRESSURE_FIELD_NUMBER = 2
  final val RELATIVE_HUMIDITY_FIELD_NUMBER = 3
  final val VOLTAGE_FIELD_NUMBER = 4
  final val POSITION_FIELD_NUMBER = 5
  def of(
    sealedValue: api.v0.MeasurementMessage.SealedValue
  ): _root_.api.v0.MeasurementMessage = _root_.api.v0.MeasurementMessage(
    sealedValue
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.Measurement])
}

@SerialVersionUID(0L)
final case class TemperatureMeasurement(
    timestamp: java.time.Instant,
    celsius: _root_.scala.Double = 0.0
    ) extends scalapb.GeneratedMessage with api.v0.Measurement.NonEmpty with scalapb.lenses.Updatable[TemperatureMeasurement] with api.v0.TemperatureMeasurementBase {
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      
      {
        val __value = api.v0.TemperatureMeasurement._typemapper_timestamp.toBase(timestamp)
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      
      {
        val __value = celsius
        if (__value != 0.0) {
          __size += _root_.com.google.protobuf.CodedOutputStream.computeDoubleSize(2, __value)
        }
      };
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      
      {
        val __v = api.v0.TemperatureMeasurement._typemapper_timestamp.toBase(timestamp)
        _output__.writeTag(1, 2)
        _output__.writeUInt32NoTag(__v.serializedSize)
        __v.writeTo(_output__)
      };
      {
        val __v = celsius
        if (__v != 0.0) {
          _output__.writeDouble(2, __v)
        }
      };
    }
    def withTimestamp(__v: java.time.Instant): TemperatureMeasurement = copy(timestamp = __v)
    def withCelsius(__v: _root_.scala.Double): TemperatureMeasurement = copy(celsius = __v)
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => api.v0.TemperatureMeasurement._typemapper_timestamp.toBase(timestamp)
        case 2 => {
          val __t = celsius
          if (__t != 0.0) __t else null
        }
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => api.v0.TemperatureMeasurement._typemapper_timestamp.toBase(timestamp).toPMessage
        case 2 => _root_.scalapb.descriptors.PDouble(celsius)
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.TemperatureMeasurement.type = api.v0.TemperatureMeasurement
    // @@protoc_insertion_point(GeneratedMessage[api.v0.TemperatureMeasurement])
}

object TemperatureMeasurement extends scalapb.GeneratedMessageCompanion[api.v0.TemperatureMeasurement] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.TemperatureMeasurement] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.TemperatureMeasurement = {
    var __requiredFields0: _root_.scala.Long = 0x1L
    var __timestamp: _root_.scala.Option[api.v0.JavaNetInstant] = _root_.scala.None
    var __celsius: _root_.scala.Double = 0.0
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 10 =>
          __timestamp = _root_.scala.Some(__timestamp.fold(_root_.scalapb.LiteParser.readMessage[api.v0.JavaNetInstant](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
          __requiredFields0 &= 0xfffffffffffffffeL
        case 17 =>
          __celsius = _input__.readDouble()
        case tag => _input__.skipField(tag)
      }
    }
    if (__requiredFields0 != 0L) { throw new _root_.com.google.protobuf.InvalidProtocolBufferException("Message missing required fields.") } 
    api.v0.TemperatureMeasurement(
        timestamp = api.v0.TemperatureMeasurement._typemapper_timestamp.toCustom(__timestamp.getOrElse(api.v0.JavaNetInstant.defaultInstance)),
        celsius = __celsius
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.TemperatureMeasurement] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.TemperatureMeasurement(
        timestamp = api.v0.TemperatureMeasurement._typemapper_timestamp.toCustom(__fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).get.as[api.v0.JavaNetInstant]),
        celsius = __fieldsMap.get(scalaDescriptor.findFieldByNumber(2).get).map(_.as[_root_.scala.Double]).getOrElse(0.0)
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = TypesProto.javaDescriptor.getMessageTypes().get(10)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = TypesProto.scalaDescriptor.messages(10)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = {
    var __out: _root_.scalapb.GeneratedMessageCompanion[_] = null
    (__number: @_root_.scala.unchecked) match {
      case 1 => __out = api.v0.JavaNetInstant
    }
    __out
  }
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = throw new MatchError(__fieldNumber)
  lazy val defaultInstance = api.v0.TemperatureMeasurement(
    timestamp = api.v0.TemperatureMeasurement._typemapper_timestamp.toCustom(api.v0.JavaNetInstant.defaultInstance),
    celsius = 0.0
  )
  implicit class TemperatureMeasurementLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.TemperatureMeasurement]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.TemperatureMeasurement](_l) {
    def timestamp: _root_.scalapb.lenses.Lens[UpperPB, java.time.Instant] = field(_.timestamp)((c_, f_) => c_.copy(timestamp = f_))
    def celsius: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Double] = field(_.celsius)((c_, f_) => c_.copy(celsius = f_))
  }
  final val TIMESTAMP_FIELD_NUMBER = 1
  final val CELSIUS_FIELD_NUMBER = 2
  @transient
  private[v0] val _typemapper_timestamp: _root_.scalapb.TypeMapper[api.v0.JavaNetInstant, java.time.Instant] = implicitly[_root_.scalapb.TypeMapper[api.v0.JavaNetInstant, java.time.Instant]]
  def of(
    timestamp: java.time.Instant,
    celsius: _root_.scala.Double
  ): _root_.api.v0.TemperatureMeasurement = _root_.api.v0.TemperatureMeasurement(
    timestamp,
    celsius
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.TemperatureMeasurement])
}

@SerialVersionUID(0L)
final case class PressureMeasurement(
    timestamp: java.time.Instant,
    pascal: _root_.scala.Double = 0.0
    ) extends scalapb.GeneratedMessage with api.v0.Measurement.NonEmpty with scalapb.lenses.Updatable[PressureMeasurement] with api.v0.PressureMeasurementBase {
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      
      {
        val __value = api.v0.PressureMeasurement._typemapper_timestamp.toBase(timestamp)
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      
      {
        val __value = pascal
        if (__value != 0.0) {
          __size += _root_.com.google.protobuf.CodedOutputStream.computeDoubleSize(2, __value)
        }
      };
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      
      {
        val __v = api.v0.PressureMeasurement._typemapper_timestamp.toBase(timestamp)
        _output__.writeTag(1, 2)
        _output__.writeUInt32NoTag(__v.serializedSize)
        __v.writeTo(_output__)
      };
      {
        val __v = pascal
        if (__v != 0.0) {
          _output__.writeDouble(2, __v)
        }
      };
    }
    def withTimestamp(__v: java.time.Instant): PressureMeasurement = copy(timestamp = __v)
    def withPascal(__v: _root_.scala.Double): PressureMeasurement = copy(pascal = __v)
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => api.v0.PressureMeasurement._typemapper_timestamp.toBase(timestamp)
        case 2 => {
          val __t = pascal
          if (__t != 0.0) __t else null
        }
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => api.v0.PressureMeasurement._typemapper_timestamp.toBase(timestamp).toPMessage
        case 2 => _root_.scalapb.descriptors.PDouble(pascal)
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.PressureMeasurement.type = api.v0.PressureMeasurement
    // @@protoc_insertion_point(GeneratedMessage[api.v0.PressureMeasurement])
}

object PressureMeasurement extends scalapb.GeneratedMessageCompanion[api.v0.PressureMeasurement] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.PressureMeasurement] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.PressureMeasurement = {
    var __requiredFields0: _root_.scala.Long = 0x1L
    var __timestamp: _root_.scala.Option[api.v0.JavaNetInstant] = _root_.scala.None
    var __pascal: _root_.scala.Double = 0.0
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 10 =>
          __timestamp = _root_.scala.Some(__timestamp.fold(_root_.scalapb.LiteParser.readMessage[api.v0.JavaNetInstant](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
          __requiredFields0 &= 0xfffffffffffffffeL
        case 17 =>
          __pascal = _input__.readDouble()
        case tag => _input__.skipField(tag)
      }
    }
    if (__requiredFields0 != 0L) { throw new _root_.com.google.protobuf.InvalidProtocolBufferException("Message missing required fields.") } 
    api.v0.PressureMeasurement(
        timestamp = api.v0.PressureMeasurement._typemapper_timestamp.toCustom(__timestamp.getOrElse(api.v0.JavaNetInstant.defaultInstance)),
        pascal = __pascal
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.PressureMeasurement] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.PressureMeasurement(
        timestamp = api.v0.PressureMeasurement._typemapper_timestamp.toCustom(__fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).get.as[api.v0.JavaNetInstant]),
        pascal = __fieldsMap.get(scalaDescriptor.findFieldByNumber(2).get).map(_.as[_root_.scala.Double]).getOrElse(0.0)
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = TypesProto.javaDescriptor.getMessageTypes().get(12)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = TypesProto.scalaDescriptor.messages(12)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = {
    var __out: _root_.scalapb.GeneratedMessageCompanion[_] = null
    (__number: @_root_.scala.unchecked) match {
      case 1 => __out = api.v0.JavaNetInstant
    }
    __out
  }
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = throw new MatchError(__fieldNumber)
  lazy val defaultInstance = api.v0.PressureMeasurement(
    timestamp = api.v0.PressureMeasurement._typemapper_timestamp.toCustom(api.v0.JavaNetInstant.defaultInstance),
    pascal = 0.0
  )
  implicit class PressureMeasurementLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.PressureMeasurement]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.PressureMeasurement](_l) {
    def timestamp: _root_.scalapb.lenses.Lens[UpperPB, java.time.Instant] = field(_.timestamp)((c_, f_) => c_.copy(timestamp = f_))
    def pascal: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Double] = field(_.pascal)((c_, f_) => c_.copy(pascal = f_))
  }
  final val TIMESTAMP_FIELD_NUMBER = 1
  final val PASCAL_FIELD_NUMBER = 2
  @transient
  private[v0] val _typemapper_timestamp: _root_.scalapb.TypeMapper[api.v0.JavaNetInstant, java.time.Instant] = implicitly[_root_.scalapb.TypeMapper[api.v0.JavaNetInstant, java.time.Instant]]
  def of(
    timestamp: java.time.Instant,
    pascal: _root_.scala.Double
  ): _root_.api.v0.PressureMeasurement = _root_.api.v0.PressureMeasurement(
    timestamp,
    pascal
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.PressureMeasurement])
}

@SerialVersionUID(0L)
final case class RelativeHumidityMeasurement(
    timestamp: java.time.Instant,
    percentage: _root_.scala.Double = 0.0
    ) extends scalapb.GeneratedMessage with api.v0.Measurement.NonEmpty with scalapb.lenses.Updatable[RelativeHumidityMeasurement] with api.v0.RelativeHumidityMeasurementBase {
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      
      {
        val __value = api.v0.RelativeHumidityMeasurement._typemapper_timestamp.toBase(timestamp)
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      
      {
        val __value = percentage
        if (__value != 0.0) {
          __size += _root_.com.google.protobuf.CodedOutputStream.computeDoubleSize(2, __value)
        }
      };
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      
      {
        val __v = api.v0.RelativeHumidityMeasurement._typemapper_timestamp.toBase(timestamp)
        _output__.writeTag(1, 2)
        _output__.writeUInt32NoTag(__v.serializedSize)
        __v.writeTo(_output__)
      };
      {
        val __v = percentage
        if (__v != 0.0) {
          _output__.writeDouble(2, __v)
        }
      };
    }
    def withTimestamp(__v: java.time.Instant): RelativeHumidityMeasurement = copy(timestamp = __v)
    def withPercentage(__v: _root_.scala.Double): RelativeHumidityMeasurement = copy(percentage = __v)
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => api.v0.RelativeHumidityMeasurement._typemapper_timestamp.toBase(timestamp)
        case 2 => {
          val __t = percentage
          if (__t != 0.0) __t else null
        }
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => api.v0.RelativeHumidityMeasurement._typemapper_timestamp.toBase(timestamp).toPMessage
        case 2 => _root_.scalapb.descriptors.PDouble(percentage)
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.RelativeHumidityMeasurement.type = api.v0.RelativeHumidityMeasurement
    // @@protoc_insertion_point(GeneratedMessage[api.v0.RelativeHumidityMeasurement])
}

object RelativeHumidityMeasurement extends scalapb.GeneratedMessageCompanion[api.v0.RelativeHumidityMeasurement] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.RelativeHumidityMeasurement] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.RelativeHumidityMeasurement = {
    var __requiredFields0: _root_.scala.Long = 0x1L
    var __timestamp: _root_.scala.Option[api.v0.JavaNetInstant] = _root_.scala.None
    var __percentage: _root_.scala.Double = 0.0
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 10 =>
          __timestamp = _root_.scala.Some(__timestamp.fold(_root_.scalapb.LiteParser.readMessage[api.v0.JavaNetInstant](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
          __requiredFields0 &= 0xfffffffffffffffeL
        case 17 =>
          __percentage = _input__.readDouble()
        case tag => _input__.skipField(tag)
      }
    }
    if (__requiredFields0 != 0L) { throw new _root_.com.google.protobuf.InvalidProtocolBufferException("Message missing required fields.") } 
    api.v0.RelativeHumidityMeasurement(
        timestamp = api.v0.RelativeHumidityMeasurement._typemapper_timestamp.toCustom(__timestamp.getOrElse(api.v0.JavaNetInstant.defaultInstance)),
        percentage = __percentage
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.RelativeHumidityMeasurement] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.RelativeHumidityMeasurement(
        timestamp = api.v0.RelativeHumidityMeasurement._typemapper_timestamp.toCustom(__fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).get.as[api.v0.JavaNetInstant]),
        percentage = __fieldsMap.get(scalaDescriptor.findFieldByNumber(2).get).map(_.as[_root_.scala.Double]).getOrElse(0.0)
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = TypesProto.javaDescriptor.getMessageTypes().get(14)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = TypesProto.scalaDescriptor.messages(14)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = {
    var __out: _root_.scalapb.GeneratedMessageCompanion[_] = null
    (__number: @_root_.scala.unchecked) match {
      case 1 => __out = api.v0.JavaNetInstant
    }
    __out
  }
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = throw new MatchError(__fieldNumber)
  lazy val defaultInstance = api.v0.RelativeHumidityMeasurement(
    timestamp = api.v0.RelativeHumidityMeasurement._typemapper_timestamp.toCustom(api.v0.JavaNetInstant.defaultInstance),
    percentage = 0.0
  )
  implicit class RelativeHumidityMeasurementLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.RelativeHumidityMeasurement]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.RelativeHumidityMeasurement](_l) {
    def timestamp: _root_.scalapb.lenses.Lens[UpperPB, java.time.Instant] = field(_.timestamp)((c_, f_) => c_.copy(timestamp = f_))
    def percentage: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Double] = field(_.percentage)((c_, f_) => c_.copy(percentage = f_))
  }
  final val TIMESTAMP_FIELD_NUMBER = 1
  final val PERCENTAGE_FIELD_NUMBER = 2
  @transient
  private[v0] val _typemapper_timestamp: _root_.scalapb.TypeMapper[api.v0.JavaNetInstant, java.time.Instant] = implicitly[_root_.scalapb.TypeMapper[api.v0.JavaNetInstant, java.time.Instant]]
  def of(
    timestamp: java.time.Instant,
    percentage: _root_.scala.Double
  ): _root_.api.v0.RelativeHumidityMeasurement = _root_.api.v0.RelativeHumidityMeasurement(
    timestamp,
    percentage
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.RelativeHumidityMeasurement])
}

@SerialVersionUID(0L)
final case class VoltageMeasurement(
    timestamp: java.time.Instant,
    volt: _root_.scala.Double = 0.0
    ) extends scalapb.GeneratedMessage with api.v0.Measurement.NonEmpty with scalapb.lenses.Updatable[VoltageMeasurement] with api.v0.VoltageMeasurementBase {
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      
      {
        val __value = api.v0.VoltageMeasurement._typemapper_timestamp.toBase(timestamp)
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      
      {
        val __value = volt
        if (__value != 0.0) {
          __size += _root_.com.google.protobuf.CodedOutputStream.computeDoubleSize(2, __value)
        }
      };
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      
      {
        val __v = api.v0.VoltageMeasurement._typemapper_timestamp.toBase(timestamp)
        _output__.writeTag(1, 2)
        _output__.writeUInt32NoTag(__v.serializedSize)
        __v.writeTo(_output__)
      };
      {
        val __v = volt
        if (__v != 0.0) {
          _output__.writeDouble(2, __v)
        }
      };
    }
    def withTimestamp(__v: java.time.Instant): VoltageMeasurement = copy(timestamp = __v)
    def withVolt(__v: _root_.scala.Double): VoltageMeasurement = copy(volt = __v)
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => api.v0.VoltageMeasurement._typemapper_timestamp.toBase(timestamp)
        case 2 => {
          val __t = volt
          if (__t != 0.0) __t else null
        }
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => api.v0.VoltageMeasurement._typemapper_timestamp.toBase(timestamp).toPMessage
        case 2 => _root_.scalapb.descriptors.PDouble(volt)
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.VoltageMeasurement.type = api.v0.VoltageMeasurement
    // @@protoc_insertion_point(GeneratedMessage[api.v0.VoltageMeasurement])
}

object VoltageMeasurement extends scalapb.GeneratedMessageCompanion[api.v0.VoltageMeasurement] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.VoltageMeasurement] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.VoltageMeasurement = {
    var __requiredFields0: _root_.scala.Long = 0x1L
    var __timestamp: _root_.scala.Option[api.v0.JavaNetInstant] = _root_.scala.None
    var __volt: _root_.scala.Double = 0.0
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 10 =>
          __timestamp = _root_.scala.Some(__timestamp.fold(_root_.scalapb.LiteParser.readMessage[api.v0.JavaNetInstant](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
          __requiredFields0 &= 0xfffffffffffffffeL
        case 17 =>
          __volt = _input__.readDouble()
        case tag => _input__.skipField(tag)
      }
    }
    if (__requiredFields0 != 0L) { throw new _root_.com.google.protobuf.InvalidProtocolBufferException("Message missing required fields.") } 
    api.v0.VoltageMeasurement(
        timestamp = api.v0.VoltageMeasurement._typemapper_timestamp.toCustom(__timestamp.getOrElse(api.v0.JavaNetInstant.defaultInstance)),
        volt = __volt
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.VoltageMeasurement] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.VoltageMeasurement(
        timestamp = api.v0.VoltageMeasurement._typemapper_timestamp.toCustom(__fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).get.as[api.v0.JavaNetInstant]),
        volt = __fieldsMap.get(scalaDescriptor.findFieldByNumber(2).get).map(_.as[_root_.scala.Double]).getOrElse(0.0)
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = TypesProto.javaDescriptor.getMessageTypes().get(16)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = TypesProto.scalaDescriptor.messages(16)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = {
    var __out: _root_.scalapb.GeneratedMessageCompanion[_] = null
    (__number: @_root_.scala.unchecked) match {
      case 1 => __out = api.v0.JavaNetInstant
    }
    __out
  }
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = throw new MatchError(__fieldNumber)
  lazy val defaultInstance = api.v0.VoltageMeasurement(
    timestamp = api.v0.VoltageMeasurement._typemapper_timestamp.toCustom(api.v0.JavaNetInstant.defaultInstance),
    volt = 0.0
  )
  implicit class VoltageMeasurementLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.VoltageMeasurement]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.VoltageMeasurement](_l) {
    def timestamp: _root_.scalapb.lenses.Lens[UpperPB, java.time.Instant] = field(_.timestamp)((c_, f_) => c_.copy(timestamp = f_))
    def volt: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Double] = field(_.volt)((c_, f_) => c_.copy(volt = f_))
  }
  final val TIMESTAMP_FIELD_NUMBER = 1
  final val VOLT_FIELD_NUMBER = 2
  @transient
  private[v0] val _typemapper_timestamp: _root_.scalapb.TypeMapper[api.v0.JavaNetInstant, java.time.Instant] = implicitly[_root_.scalapb.TypeMapper[api.v0.JavaNetInstant, java.time.Instant]]
  def of(
    timestamp: java.time.Instant,
    volt: _root_.scala.Double
  ): _root_.api.v0.VoltageMeasurement = _root_.api.v0.VoltageMeasurement(
    timestamp,
    volt
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.VoltageMeasurement])
}

@SerialVersionUID(0L)
final case class PositionMeasurement(
    timestamp: java.time.Instant,
    latitudeDeg: _root_.scala.Double = 0.0,
    longitudeDeg: _root_.scala.Double = 0.0,
    positionalAccuracyMeter: _root_.scala.Option[_root_.scala.Double] = _root_.scala.None,
    altitudeMeter: _root_.scala.Option[_root_.scala.Double] = _root_.scala.None
    ) extends scalapb.GeneratedMessage with api.v0.Measurement.NonEmpty with scalapb.lenses.Updatable[PositionMeasurement] with api.v0.PositionMeasurementBase {
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      
      {
        val __value = api.v0.PositionMeasurement._typemapper_timestamp.toBase(timestamp)
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      
      {
        val __value = latitudeDeg
        if (__value != 0.0) {
          __size += _root_.com.google.protobuf.CodedOutputStream.computeDoubleSize(2, __value)
        }
      };
      
      {
        val __value = longitudeDeg
        if (__value != 0.0) {
          __size += _root_.com.google.protobuf.CodedOutputStream.computeDoubleSize(3, __value)
        }
      };
      if (positionalAccuracyMeter.isDefined) {
        val __value = positionalAccuracyMeter.get
        __size += _root_.com.google.protobuf.CodedOutputStream.computeDoubleSize(4, __value)
      };
      if (altitudeMeter.isDefined) {
        val __value = altitudeMeter.get
        __size += _root_.com.google.protobuf.CodedOutputStream.computeDoubleSize(5, __value)
      };
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      
      {
        val __v = api.v0.PositionMeasurement._typemapper_timestamp.toBase(timestamp)
        _output__.writeTag(1, 2)
        _output__.writeUInt32NoTag(__v.serializedSize)
        __v.writeTo(_output__)
      };
      {
        val __v = latitudeDeg
        if (__v != 0.0) {
          _output__.writeDouble(2, __v)
        }
      };
      {
        val __v = longitudeDeg
        if (__v != 0.0) {
          _output__.writeDouble(3, __v)
        }
      };
      positionalAccuracyMeter.foreach { __v =>
        val __m = __v
        _output__.writeDouble(4, __m)
      };
      altitudeMeter.foreach { __v =>
        val __m = __v
        _output__.writeDouble(5, __m)
      };
    }
    def withTimestamp(__v: java.time.Instant): PositionMeasurement = copy(timestamp = __v)
    def withLatitudeDeg(__v: _root_.scala.Double): PositionMeasurement = copy(latitudeDeg = __v)
    def withLongitudeDeg(__v: _root_.scala.Double): PositionMeasurement = copy(longitudeDeg = __v)
    def getPositionalAccuracyMeter: _root_.scala.Double = positionalAccuracyMeter.getOrElse(0.0)
    def clearPositionalAccuracyMeter: PositionMeasurement = copy(positionalAccuracyMeter = _root_.scala.None)
    def withPositionalAccuracyMeter(__v: _root_.scala.Double): PositionMeasurement = copy(positionalAccuracyMeter = Option(__v))
    def getAltitudeMeter: _root_.scala.Double = altitudeMeter.getOrElse(0.0)
    def clearAltitudeMeter: PositionMeasurement = copy(altitudeMeter = _root_.scala.None)
    def withAltitudeMeter(__v: _root_.scala.Double): PositionMeasurement = copy(altitudeMeter = Option(__v))
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => api.v0.PositionMeasurement._typemapper_timestamp.toBase(timestamp)
        case 2 => {
          val __t = latitudeDeg
          if (__t != 0.0) __t else null
        }
        case 3 => {
          val __t = longitudeDeg
          if (__t != 0.0) __t else null
        }
        case 4 => positionalAccuracyMeter.orNull
        case 5 => altitudeMeter.orNull
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => api.v0.PositionMeasurement._typemapper_timestamp.toBase(timestamp).toPMessage
        case 2 => _root_.scalapb.descriptors.PDouble(latitudeDeg)
        case 3 => _root_.scalapb.descriptors.PDouble(longitudeDeg)
        case 4 => positionalAccuracyMeter.map(_root_.scalapb.descriptors.PDouble(_)).getOrElse(_root_.scalapb.descriptors.PEmpty)
        case 5 => altitudeMeter.map(_root_.scalapb.descriptors.PDouble(_)).getOrElse(_root_.scalapb.descriptors.PEmpty)
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.PositionMeasurement.type = api.v0.PositionMeasurement
    // @@protoc_insertion_point(GeneratedMessage[api.v0.PositionMeasurement])
}

object PositionMeasurement extends scalapb.GeneratedMessageCompanion[api.v0.PositionMeasurement] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.PositionMeasurement] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.PositionMeasurement = {
    var __requiredFields0: _root_.scala.Long = 0x1L
    var __timestamp: _root_.scala.Option[api.v0.JavaNetInstant] = _root_.scala.None
    var __latitudeDeg: _root_.scala.Double = 0.0
    var __longitudeDeg: _root_.scala.Double = 0.0
    var __positionalAccuracyMeter: _root_.scala.Option[_root_.scala.Double] = _root_.scala.None
    var __altitudeMeter: _root_.scala.Option[_root_.scala.Double] = _root_.scala.None
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 10 =>
          __timestamp = _root_.scala.Some(__timestamp.fold(_root_.scalapb.LiteParser.readMessage[api.v0.JavaNetInstant](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
          __requiredFields0 &= 0xfffffffffffffffeL
        case 17 =>
          __latitudeDeg = _input__.readDouble()
        case 25 =>
          __longitudeDeg = _input__.readDouble()
        case 33 =>
          __positionalAccuracyMeter = Option(_input__.readDouble())
        case 41 =>
          __altitudeMeter = Option(_input__.readDouble())
        case tag => _input__.skipField(tag)
      }
    }
    if (__requiredFields0 != 0L) { throw new _root_.com.google.protobuf.InvalidProtocolBufferException("Message missing required fields.") } 
    api.v0.PositionMeasurement(
        timestamp = api.v0.PositionMeasurement._typemapper_timestamp.toCustom(__timestamp.getOrElse(api.v0.JavaNetInstant.defaultInstance)),
        latitudeDeg = __latitudeDeg,
        longitudeDeg = __longitudeDeg,
        positionalAccuracyMeter = __positionalAccuracyMeter,
        altitudeMeter = __altitudeMeter
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.PositionMeasurement] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.PositionMeasurement(
        timestamp = api.v0.PositionMeasurement._typemapper_timestamp.toCustom(__fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).get.as[api.v0.JavaNetInstant]),
        latitudeDeg = __fieldsMap.get(scalaDescriptor.findFieldByNumber(2).get).map(_.as[_root_.scala.Double]).getOrElse(0.0),
        longitudeDeg = __fieldsMap.get(scalaDescriptor.findFieldByNumber(3).get).map(_.as[_root_.scala.Double]).getOrElse(0.0),
        positionalAccuracyMeter = __fieldsMap.get(scalaDescriptor.findFieldByNumber(4).get).flatMap(_.as[_root_.scala.Option[_root_.scala.Double]]),
        altitudeMeter = __fieldsMap.get(scalaDescriptor.findFieldByNumber(5).get).flatMap(_.as[_root_.scala.Option[_root_.scala.Double]])
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = TypesProto.javaDescriptor.getMessageTypes().get(18)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = TypesProto.scalaDescriptor.messages(18)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = {
    var __out: _root_.scalapb.GeneratedMessageCompanion[_] = null
    (__number: @_root_.scala.unchecked) match {
      case 1 => __out = api.v0.JavaNetInstant
    }
    __out
  }
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = throw new MatchError(__fieldNumber)
  lazy val defaultInstance = api.v0.PositionMeasurement(
    timestamp = api.v0.PositionMeasurement._typemapper_timestamp.toCustom(api.v0.JavaNetInstant.defaultInstance),
    latitudeDeg = 0.0,
    longitudeDeg = 0.0,
    positionalAccuracyMeter = _root_.scala.None,
    altitudeMeter = _root_.scala.None
  )
  implicit class PositionMeasurementLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.PositionMeasurement]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.PositionMeasurement](_l) {
    def timestamp: _root_.scalapb.lenses.Lens[UpperPB, java.time.Instant] = field(_.timestamp)((c_, f_) => c_.copy(timestamp = f_))
    def latitudeDeg: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Double] = field(_.latitudeDeg)((c_, f_) => c_.copy(latitudeDeg = f_))
    def longitudeDeg: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Double] = field(_.longitudeDeg)((c_, f_) => c_.copy(longitudeDeg = f_))
    def positionalAccuracyMeter: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Double] = field(_.getPositionalAccuracyMeter)((c_, f_) => c_.copy(positionalAccuracyMeter = Option(f_)))
    def optionalPositionalAccuracyMeter: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Option[_root_.scala.Double]] = field(_.positionalAccuracyMeter)((c_, f_) => c_.copy(positionalAccuracyMeter = f_))
    def altitudeMeter: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Double] = field(_.getAltitudeMeter)((c_, f_) => c_.copy(altitudeMeter = Option(f_)))
    def optionalAltitudeMeter: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Option[_root_.scala.Double]] = field(_.altitudeMeter)((c_, f_) => c_.copy(altitudeMeter = f_))
  }
  final val TIMESTAMP_FIELD_NUMBER = 1
  final val LATITUDE_DEG_FIELD_NUMBER = 2
  final val LONGITUDE_DEG_FIELD_NUMBER = 3
  final val POSITIONAL_ACCURACY_METER_FIELD_NUMBER = 4
  final val ALTITUDE_METER_FIELD_NUMBER = 5
  @transient
  private[v0] val _typemapper_timestamp: _root_.scalapb.TypeMapper[api.v0.JavaNetInstant, java.time.Instant] = implicitly[_root_.scalapb.TypeMapper[api.v0.JavaNetInstant, java.time.Instant]]
  def of(
    timestamp: java.time.Instant,
    latitudeDeg: _root_.scala.Double,
    longitudeDeg: _root_.scala.Double,
    positionalAccuracyMeter: _root_.scala.Option[_root_.scala.Double],
    altitudeMeter: _root_.scala.Option[_root_.scala.Double]
  ): _root_.api.v0.PositionMeasurement = _root_.api.v0.PositionMeasurement(
    timestamp,
    latitudeDeg,
    longitudeDeg,
    positionalAccuracyMeter,
    altitudeMeter
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.PositionMeasurement])
}
