package pwa

sealed trait Page

object Page {

  case object Login extends Page

  case object Home extends Page

  case object SensorList extends Page

  case class Sensor(id: api.v0.SensorId) extends Page

  case object ThingList extends Page

  case class Thing(id: api.v0.ThingId) extends Page

  case object TenantList extends Page

  case class Tenant(id: api.v0.TenantId) extends Page

  case object Debug extends Page
}
