package pwa

import api.v0.Tenant
import diode.Action
import diode.react.{ModelProxy, ReactConnectProxy}
import japgolly.scalajs.react.extra.router.{ResolutionWithProps, RouterCtl}
import japgolly.scalajs.react.vdom.all.vdomAttrVtCssUnits
import japgolly.scalajs.react.vdom.html_<^._
import japgolly.scalajs.react.{BackendScope, Callback, ScalaComponent}
import pwa.circuit.{LogoutAction, ToggleSidebar}
import pwa.model.UiModel
import react.semanticui.collections.grid.{Grid, GridColumn, GridOnly, GridRow}
import react.semanticui.collections.menu.{Menu, MenuItem, MenuItemPosition}
import react.semanticui.elements.header.Header
import react.semanticui.elements.icon.Icon
import react.semanticui.elements.segment.{Segment, SegmentAttached}
import react.semanticui.modules.sidebar._
import react.semanticui.{SemanticSize, sizes, textalignment, verticalalignment, widths}

object Shell {

  case class Props(routerCtl: RouterCtl[Page], resolution: ResolutionWithProps[Page, AppRouter.Props], routerProps: AppRouter.Props)

  // TODO: replace this with UiState (sidebar state is internal to this component...)
  case class State(uiModelRcp: ReactConnectProxy[UiModel], currentTenantRcp: ReactConnectProxy[Option[Tenant]])

  case class Backend(bs: BackendScope[Props, State]) {

    def MyMenu(router: RouterCtl[Page], dispatch: Action => Callback, currentTenantRcp: ReactConnectProxy[Option[Tenant]], vertical: Boolean, size: SemanticSize = sizes.Large) = {
      Menu(inverted = true, vertical = vertical, size = size)(
        currentTenantRcp {
          ct: ModelProxy[Option[Tenant]] =>
            ct.value match {
              case Some(value) => MenuItem(as = <.a, header = true)(router.setOnClick(Page.Home), s"${value.name}").vdomElement
              case None => MenuItem(as = <.a, header = true)(router.setOnClick(Page.Login), "Not logged in...").vdomElement
            }
        },
        MenuItem(as = <.a)(router.setOnClick(Page.SensorList), "Sensors"),
        MenuItem(as = <.a)(router.setOnClick(Page.ThingList), "Things"),
        MenuItem(as = <.a)(router.setOnClick(Page.TenantList), "Tenants"),
        MenuItem(as = <.a)(router.setOnClick(Page.Debug), "Debug"),
        currentTenantRcp {
          ct: ModelProxy[Option[Tenant]] =>
            ct.value match {
              case Some(value) => MenuItem(as = <.a, position = MenuItemPosition.Right)(^.onClick --> dispatch(LogoutAction()),"Logout").vdomElement
              // second login on the right.
              case None => MenuItem(as = <.a, position = MenuItemPosition.Right)(router.setOnClick(Page.Login),"Login").vdomElement
            }
        },
      )
    }

    def NavBar(router: RouterCtl[Page], dispatch: Action => Callback, currentTenantRcp: ReactConnectProxy[Option[Tenant]]) = {
      Segment(as = "nav", inverted = true, attached = SegmentAttached.Attached)(
        Grid(
          GridRow(
            // -- begin mobile-only
            // "hamburger"
            GridColumn(mobile = widths.widthOf(4), only = GridOnly.Mobile)(
              Menu(inverted = true)(
                MenuItem(as = <.a, header = true)(^.onClick --> dispatch(ToggleSidebar()), Icon(/*as = <.a,*/ name = "bars", size = sizes.Big/*, inverted = true*/)),
              )
            ),
            // actual title
            GridColumn(width = widths.widthOf(12), only = GridOnly.Mobile, verticalAlign = verticalalignment.Middle)(
              currentTenantRcp {
                ct: ModelProxy[Option[Tenant]] =>
                  ct.value match {
                    case Some(value) => Header(as = <.h1, size = sizes.Small, inverted = true, content = s"${value.name}")(router.setOnClick(Page.Home)).vdomElement
                    case None => Header(as = <.h1, size = sizes.Small, inverted = true, content = "Not logged in...")(router.setOnClick(Page.Login)).vdomElement
                  }
              },
            ),
            // -- end mobile-only
            // -- begin normal screen
            // nav buttons
            GridColumn(width = widths.widthOf(16), only = GridOnly.Value("computer tablet"), textAlign = textalignment.Center)(
              MyMenu(router, dispatch, currentTenantRcp, vertical = false, size = sizes.Massive)
            ),
            // -- end normal screen
          ),
        )
      )
    }

    def render(props: Props, state: State) = {
      state.uiModelRcp { (uiModelProxy: ModelProxy[UiModel]) =>
        val sidebarVisibleProxy: ModelProxy[Boolean] = uiModelProxy.zoom(_.sidebarVisible)

        <.div(
          ^.height := "100vh",
          // begin navbar
          NavBar(props.routerCtl, sidebarVisibleProxy.dispatchCB, state.currentTenantRcp),
          // end navbar
          // TODO: figure out, how to make sidebar hide on click to the main area
          // This https://github.com/japgolly/scalajs-react/blob/master/doc/REFS.md
          // does not seem to be compatible with scala react-semantic-ui ('target' type does not match obtainable Ref types)
          SidebarPushable(
            Sidebar(
              animation = SidebarAnimation.Overlay,
              direction = SidebarDirection.Left,
              visible = sidebarVisibleProxy(),
              width = SidebarWidth.Thin,
            )(
              MyMenu(props.routerCtl, sidebarVisibleProxy.dispatchCB, state.currentTenantRcp, vertical = true, size = sizes.Massive)
            ),
            SidebarPusher(dimmed = sidebarVisibleProxy())(
              Segment(basic = true)(^.position.absolute, ^.top := 40.px, ^.bottom := 0.px, ^.width := 100.pct, ^.overflowY.scroll, ^.margin := 0.px, props.resolution.renderP(props.routerProps)),
            )
          ),
        )
      }
    }
  }

  private val component = ScalaComponent.builder[Props]
    .initialStateFromProps(props => State(
      uiModelRcp = props.routerProps.rootModelProxy.connect(_.ui),
      currentTenantRcp = props.routerProps.rootModelProxy.connect(_.currentTenant),
    ))
    .renderBackend[Backend]
    .build

  def apply(routerCtl: AppRouter.Ctl, resolution: AppRouter.Resolution, routerProps: AppRouter.Props) = component(Props(routerCtl, resolution, routerProps))
}
