package pwa.circuit

import api.v0._
import diode.data._
import diode.{ActionResult, Dispatcher, ModelR, ModelRW}
import org.scalajs.macrotaskexecutor.MacrotaskExecutor.Implicits._
import pwa.model.{PwaSupportService, SensorInfo, Sensors}
import pwa.util.ProperAsyncAction

import scala.concurrent.Future
import scala.util.{Failure, Try}

case class UpdateSensorLastMeasurements(sensorId: SensorId,
                                        keys: Set[MeasurementType],
                                        state: PotState = PotState.PotEmpty,
                                        result: Try[Map[MeasurementType, Pot[Measurement]]] = Failure(new AsyncAction.PendingException)
                                       ) extends ProperAsyncAction[Map[MeasurementType, Pot[Measurement]], MeasurementType, Measurement, UpdateSensorLastMeasurements] {


  override def shouldFetch(pot: Pot[Measurement]): Boolean = super.shouldFetchOnlyEmpty(pot)

  override def next(newState: PotState, newValue: Try[Map[MeasurementType, Pot[Measurement]]]): UpdateSensorLastMeasurements = {
    scribe.debug(s"updating UpdateSensorLastMeasurements with ${newState} state and value ${newValue} (current ${result})")
    UpdateSensorLastMeasurements(sensorId, keys, newState, newValue)
  }
}

class LastMeasurementFetch(val sensorId: SensorId, dispatch: Dispatcher) extends Fetch[MeasurementType] {
  override def fetch(key: MeasurementType): Unit = {
    scribe.debug(s"dispatching fetch for ${sensorId} of ${key}")
    dispatch(UpdateSensorLastMeasurements(sensorId = sensorId, keys = Set(key)))
  }

  override def fetch(keys: Iterable[MeasurementType]): Unit = {
    scribe.debug(s"dispatching fetch for ${sensorId} of ${keys}")
    dispatch(UpdateSensorLastMeasurements(sensorId = sensorId, keys = Set() ++ keys))
  }
}

class UpdateSensorLastMeasurementsHandler[M](
                                              dispatch: Dispatcher,
                                              modelRW: ModelRW[M, Sensors],
                                              api: ModelR[M, PwaSupportService],
                                              currentTenant: RefTo[Option[Tenant]],
                                            ) extends ApiActionHandler(modelRW, api, dispatch) {

  def load(sensorId: SensorId, measurementTypes: Set[MeasurementType]): Future[Map[MeasurementType, Pot[Measurement]]] = {

    def fetchCall: MeasurementType => Future[Pot[Measurement]] = (key: MeasurementType) => api.value.getLatestSensorValue(LatestSensorValueRequest(
      sensorId = sensorId, measurementType = key
    )).map((result: LatestSensorValueResponse) => {
      Ready(result.measurement)
    })

    scribe.debug("triggering api call")
    val setOfFutures: Set[Future[(MeasurementType, Pot[Measurement])]] = measurementTypes.map(measurementType => fetchCall(measurementType).map(measurement => {
      scribe.debug(s"api call returned, got: ${measurement}")
      measurementType -> measurement
    }))
    val futureOfMap: Future[Map[MeasurementType, Pot[Measurement]]] = Future.foldLeft(setOfFutures)(Map[MeasurementType, Pot[Measurement]]())((m: Map[MeasurementType, Pot[Measurement]], newOne: (MeasurementType, Pot[Measurement])) => {
      scribe.debug(s"adding ${newOne} to map ${m}")
      m + newOne
    })
    futureOfMap.map(m => {
      scribe.info(s"final map ${m}")
      m
    })
  }

  private def zoomToSensorLatest(sensorId: SensorId, rw: ModelRW[M, Sensors]): Option[ModelRW[M, PotMap[MeasurementType, Measurement]]] = {
    val sensorModel: ModelRW[M, Pot[SensorInfo]] = rw.zoomRW(_.get(sensorId))((sensorMap: Sensors, potValue: Pot[SensorInfo]) => sensorMap + (potValue.get.sensor.id, potValue))
    if (sensorModel.value.isEmpty) {
      None
    } else {
      val actual: ModelRW[M, PotMap[MeasurementType, Measurement]] = sensorModel
        .zoomRW(pot => pot.get)((current: Pot[SensorInfo], v: SensorInfo) => Ready(v))
        .zoomTo(_.latestMeasurements)
      Some(actual)
    }
  }

  override protected def handle: PartialFunction[Any, ActionResult[M]] = {
    case action: UpdateSensorLastMeasurements => {
      scribe.debug(s"handling action: ${action} (with potstate ${action.state})")
      zoomToSensorLatest(action.sensorId, modelRW) match {
        case None => noChange
        case Some(innerModel: ModelRW[M, PotMap[MeasurementType, Measurement]]) => {
          scribe.debug(s"keys before handle: ${action.keys.size} = ${action.keys} (inner model keys: ${innerModel.value.keySet})")
          action.handleProperly(innerModel, (action: UpdateSensorLastMeasurements, keys: Set[MeasurementType]) => action.effect(load(action.sensorId, keys))(success = identity, failure = redirectToLoginIfNeeded))
        }
      }
    }
  }
}
