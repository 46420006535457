package pwa

import diode.react.{ModelProxy, ReactConnectProxy}
import io.grpc.ManagedChannel
import japgolly.scalajs.react.extra.router.BaseUrl
import org.scalajs.dom
import org.scalajs.dom.{ServiceWorkerRegistration, window}
import pwa.circuit.AppCircuit
import pwa.model.RootModel
import scalapb.grpc.Channels
import scribe.Level
import scribe.writer.BrowserConsoleWriter
import org.scalajs.macrotaskexecutor.MacrotaskExecutor.Implicits._
import scala.util.{Failure, Success}

object Main extends {
  def main(args: Array[String]): Unit = {
    scribe.Logger.root
      .clearHandlers()
      .clearModifiers()
      .withHandler(
        minimumLevel = Some(Level.Debug),
        writer = BrowserConsoleWriter,
      )
      .replace()
    scribe.debug("logger configured")

    scribe.debug("registering sw")

    window.navigator.serviceWorker.register(
      scriptURL = "/app/sw.js"
    ).toFuture.onComplete {
      case Failure(exception) => {
        scribe.debug(s"service registration failed (${exception}, trace ${exception.printStackTrace()}")
      }
      case Success(r: ServiceWorkerRegistration) => {
        scribe.debug(s"service registration successful ${r} (scope: ${r.scope}")
        r.update()
      }
    }

    val grpcurl = BaseUrl.fromWindowOrigin.value
    scribe.debug(s"grpcurl: ${grpcurl}")
    val chan: ManagedChannel = Channels.grpcwebChannel(grpcurl)

    val myAppCircuit = new AppCircuit(chan)
    val router = AppRouter(myAppCircuit)

    val topLevelConnection: ReactConnectProxy[RootModel] = myAppCircuit.connect((x: RootModel) => x)
    topLevelConnection((rootModelProxy: ModelProxy[RootModel]) =>
      router(AppRouter.Props(rootModelProxy, myAppCircuit))
    ).renderIntoDOM(dom.document.getElementById("root"))
  }
}
