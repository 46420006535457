package pwa.screens

import api.v0.{MeasurementList, MeasurementType}
import diode.data.Pot
import diode.react.{ModelProxy, ReactConnectProxy}
import japgolly.scalajs.react.ScalaComponent
import japgolly.scalajs.react.ScalaComponent.BackendScope
import japgolly.scalajs.react.extra.router.RouterCtl
import japgolly.scalajs.react.vdom.html_<^._
import pwa.Page
import pwa.components.ScalarDataVisualization
import pwa.model.SensorInfo
import pwa.screens.SensorDetailScreen.ActiveTab.ActiveTab
import react.semanticui.elements.container.Container
import react.semanticui.elements.header.Header
import react.semanticui.elements.segment.SegmentGroup

object SensorDetailScreen {

  object ActiveTab extends Enumeration {
    type ActiveTab = Value
    val GRAPH, TABLE = Value
  }

  case class Props(routerCtl: RouterCtl[Page], id: api.v0.SensorId, mppsi: ModelProxy[Pot[SensorInfo]])

  case class State(rcp: ReactConnectProxy[Pot[SensorInfo]], activeCard: ActiveTab = ActiveTab.GRAPH)

  case class Backend($: BackendScope[Props, State]) {
    def modelProxyMeasurementListFromModelProxySensorInfo(mt: MeasurementType, mppsi: ModelProxy[Pot[SensorInfo]]): ModelProxy[Pot[MeasurementList]] = {
      val zoomedMapped: ModelProxy[Pot[Pot[MeasurementList]]] = ModelProxy(mppsi.modelReader.zoomMap(identity)(_.measurements.get(mt)), mppsi.theDispatch, mppsi.connector)
      val flattened: ModelProxy[Pot[MeasurementList]] = zoomedMapped.zoom(_.flatten)
      flattened
    }

    def render(props: Props, state: State) = {
      import diode.react.ReactPot._

      state.rcp((mp: ModelProxy[Pot[SensorInfo]]) => {

        <.div(
          mp().render((si: SensorInfo) => {
            val z = for {
              mt <- si.sensor.measurementTypes.types
            } yield {

              ScalarDataVisualization(mt, modelProxyMeasurementListFromModelProxySensorInfo(mt, mp)).vdomElement
            }
            Container(
              Header(as = <.h2)(s"${if (si.sensor.comment.nonEmpty) si.sensor.comment else "Sensor"}"),
              <.p(s"${si.sensor.id.value} (${si.sensor.sensorTypeText})"),
              <.p(s"${si.sensor.measurementTypes.types.map(_.physicalQuantity).toList.mkString(",")}"),
              SegmentGroup(
                VdomArray() ++= z
              )
            )
          })
        )
      })
    }
  }

  private val component = ScalaComponent.builder[Props]
    .initialStateFromProps(props => State(rcp = props.mppsi.connect(identity)))
    .renderBackend[Backend]
    .build

  def apply(routerCtl: RouterCtl[Page], id: api.v0.SensorId, proxy: ModelProxy[Pot[SensorInfo]]) = component(Props(routerCtl, id, proxy))
}
