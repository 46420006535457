package pwa.components

import api.v0._
import japgolly.scalajs.react.component.builder.Lifecycle.ComponentDidMount
import japgolly.scalajs.react.vdom.html_<^._
import japgolly.scalajs.react.{BackendScope, Callback, Ref, ScalaComponent}
import org.scalajs.dom.html
import pwa.util.DygraphHelper
import typings.dygraphs.dygraphs.{DataArray, Options}

import scala.scalajs.js
import scala.scalajs.js.|

object StaticScalarChart {

  case class Props(mt: MeasurementType, ml: MeasurementList)

  case class State()

  case class Backend($: BackendScope[Props, State]) {
    private val divRef = Ref[html.Element]

    private def scalarMeasurementsToDataArray(measurements: Seq[ScalarMeasurementBase[_]]): DataArray = {
      import scala.scalajs.js.JSConverters._

      measurements.map(tm => js.Array[Double | js.Date | Null](new js.Date(tm.timestamp.toEpochMilli), tm.customaryValue)).toJSArray
    }

    def didMount(cdm: ComponentDidMount[Props, State, Backend]): Callback = {

      val data: DataArray = cdm.props.mt.asScalar match {
        case None => {
          scribe.warn(s"Cannot chart non-scalar MeasurementList ! (${cdm.props.mt.physicalQuantity})")
          js.Array[js.Array[Double | js.Date | Null]]()
        }
        case Some(mt) => {
          cdm.props.ml match {
            case MeasurementList.Empty => {
              scribe.warn("cannot chart MeasurementList.Empty !")
              js.Array[js.Array[Double | js.Date | Null]]()
            }
            case TemperatureMeasurementList(measurements) => scalarMeasurementsToDataArray(measurements)
            case PressureMeasurementList(measurements) => scalarMeasurementsToDataArray(measurements)
            case RelativeHumidityMeasurementList(measurements) => scalarMeasurementsToDataArray(measurements)
            case VoltageMeasurementList(measurements) => scalarMeasurementsToDataArray(measurements)
          }
        }
      }
      divRef.foreach(element => {
        val options: Options = Options()
        // TODO: fix naming... non-hackable
        options.labels = js.Array("Time", cdm.props.mt.physicalQuantity)
        options.ylabel = s"${cdm.props.mt.physicalQuantity} ${cdm.props.mt.asScalar.map(mt => s"(${mt.customaryUnit})").getOrElse("")}"
        options.labelsSeparateLines = true
        options.drawPoints = true
        val graph: DygraphHelper.Dygraph = new DygraphHelper.Dygraph(element, data, options)
      })
    }

    def render(props: Props, state: State) = {
      <.div.withRef(divRef)(^.width := "100%")
    }
  }

  private val component = ScalaComponent.builder[Props]
    .initialState(State())
    .renderBackend[Backend]
    .componentDidMount((x: ComponentDidMount[Props, State, Backend]) => x.backend.didMount(x))
    .build

  def apply(mt: MeasurementType, ml: MeasurementList) = component(Props(mt, ml))

}
