package pwa

import diode.Dispatcher
import diode.react.ModelProxy
import japgolly.scalajs.react.extra.router.{BaseUrl, ResolutionWithProps, RouterCtl, RouterLogicF, RouterWithProps, RouterWithPropsConfigDsl, RouterWithPropsF, SetRouteVia}
import japgolly.scalajs.react.{Callback, CallbackTo}
import pwa.circuit.{AfterLoginPageChange, AppCircuit, ConfigureRouter}
import pwa.model.RootModel
import pwa.screens._

import scala.scalajs.js.|.fromTypeConstructor

object AppRouter {
  type Ctl = RouterCtl[Page]
  type Resolution = ResolutionWithProps[Page, Props]

  // Required to provide ShellLayout with model access
  case class Props(rootModelProxy: ModelProxy[RootModel], dispatch: Dispatcher)

  def ShellLayoutWrapper(routerCtl: AppRouter.Ctl, resolution: AppRouter.Resolution) = { (routerProps: AppRouter.Props) =>
    Shell(routerCtl, resolution, routerProps).vdomElement
  }

  def routerConfig(myAppCircuit: AppCircuit) = RouterWithPropsConfigDsl[Page, AppRouter.Props].buildConfig { dsl =>
    import dsl._

    val sensorListConnection = myAppCircuit.connect(_.sensors)
    val thingListConnection = myAppCircuit.connect(_.things)
    val tenantListConnection = myAppCircuit.connect(_.tenants)
    val everythingConnection = myAppCircuit.connect(x => x)

    def sensorRule: Rule = dynamicRouteCT("#sensor" / string("s-.*")
      .caseClass[api.v0.SensorId]
      .pmap[Page.Sensor]((id: api.v0.SensorId) => Some(Page.Sensor(id))) { case Page.Sensor(id) => id }) ~> dynRenderRP((z: Page.Sensor, routerCtl, props: Props) => {
      screens.SensorDetailScreen(routerCtl, z.id, props.rootModelProxy.zoom(_.sensors.get(z.id)))
    })

    def thingRule: Rule = dynamicRouteCT("#thing" / string("t-.*")
      .caseClass[api.v0.ThingId]
      // TODO: thing probably needs same change from Pot[Seq[..]] to Things(=PotMap)
      .pmap[Page.Thing]((id: api.v0.ThingId) => Some(Page.Thing(id))) { case Page.Thing(id) => id }) ~> dynRender((z: Page.Thing) => components.ThingDetail(z.id))

    def tenantRule: Rule = dynamicRouteCT("#tenant" / string("te-.*")
      .caseClass[api.v0.TenantId]
      .pmap[Page.Tenant]((id: api.v0.TenantId) => Some(Page.Tenant(id))) { case Page.Tenant(id) => id }) ~> dynRender((z: Page.Tenant) => components.TenantDetail(z.id))

    (
      emptyRule
        | staticRoute(root, Page.Home) ~> renderR(routerCtl => everythingConnection(proxy => HomeScreen(routerCtl, proxy)))
        | staticRoute("#login", Page.Login) ~> renderRP((routerCtl, props) => everythingConnection(proxy => screens.LoginScreen(routerCtl, props.rootModelProxy.zoom(_.apiConfig))))
        | staticRoute("#sensor", Page.SensorList) ~> renderR(routerCtl => sensorListConnection(proxy => SensorListScreen(routerCtl, proxy)))
        | staticRoute("#thing", Page.ThingList) ~> renderR(routerCtl => thingListConnection(proxy => ThingListScreen(routerCtl, proxy)))
        | staticRoute("#tenant", Page.TenantList) ~> renderR(routerCtl => tenantListConnection(proxy => TenantListScreen(routerCtl, proxy)))
        | staticRoute("#debug", Page.Debug) ~> renderR(routerCtl => everythingConnection(proxy => DebugScreen(routerCtl, proxy)))
        | sensorRule
        | thingRule
        | tenantRule
        | removeTrailingSlashes
      )
      .notFound(redirectToPage(Page.Login)(SetRouteVia.HistoryReplace))
      .onPostRenderP((maybePrev, cur, props) => Callback {
        scribe.debug(s"post render: prev=${maybePrev}, cur=${cur}")
        cur match {
          case Page.Login => {
            maybePrev match {
              case None =>
              case Some(prev) => prev match {
                case Page.Login =>
                case p : Page => {
                  // update AfterLogin page only when accessing Login page (and the previous page was _not_ login page)
                  scribe.debug(s"dispatching AfterLoginPageChange")
                  props.dispatch(AfterLoginPageChange(Some(prev)))
                }
              }
            }
          }
          case _ =>
        }

      })
      .setTitleOptionP((page: Page, props: Props) => Some("PrimoDoMobilu"))
      .renderWithP(ShellLayoutWrapper)
      .verify(Page.Home, Page.Debug)
  }

  def apply(myAppCircuit: AppCircuit): RouterWithPropsF[CallbackTo, Page, Props] = {
    /*
    In order to handler redirect to Login page, action handler requires access to RouterCtl object.
    That is not available at AppCircuit creation time. It is created only in the early part of RouterWithProps construction
    The following code unrolls that construction, and dispatches the RouterCtl object reference to update the model.
     */

    val baseUrl = BaseUrl.fromWindowUrl((s: String) => s.takeWhile(c => c != '#'))
    val cfg = routerConfig(myAppCircuit)
    val routerLogic = new RouterLogicF(baseUrl, cfg)

    val ctl: RouterCtl[Page] = routerLogic.ctl
    myAppCircuit.dispatch(ConfigureRouter(ctl))

    RouterWithProps.componentUnbuiltC[CallbackTo, Page, Props](cfg, routerLogic).build
  }
}
