package pwa.screens

import api.v0._
import com.google.protobuf.timestamp.Timestamp
import diode.Action
import diode.data._
import diode.react.{ModelProxy, ReactConnectProxy}
import japgolly.scalajs.react.component.builder.Lifecycle.ComponentDidMount
import japgolly.scalajs.react.extra.router.RouterCtl
import japgolly.scalajs.react.vdom.VdomNode
import japgolly.scalajs.react.vdom.all.div
import japgolly.scalajs.react.vdom.html_<^._
import japgolly.scalajs.react.{BackendScope, Callback, ScalaComponent}
import pwa.Page
import pwa.circuit.UpdateSensorList
import pwa.model.{SensorInfo, Sensors}
import pwa.util.ToStringUtils
import react.semanticui.collections.message.{Message, MessageHeader}
import react.semanticui.collections.table._
import react.semanticui.elements.button.Button
import react.semanticui.views.card._

import java.time.format.DateTimeFormatter
import java.time.{Instant, ZoneId}
import java.util.Locale

object SensorMeasurementLine {

  // TODO: split, to make date + timezone render using smaller font
  lazy val instantFormatter = DateTimeFormatter
    .ofPattern("HH:mm:ss' 'yyyy-MM-dd' 'O")
    .withLocale(Locale.getDefault)
    .withZone(ZoneId.systemDefault)

  @deprecated
  private def formatTimestamp(timestamp: Timestamp): String = {
    val instant = java.time.Instant.ofEpochSecond(timestamp.seconds, timestamp.nanos)
    instantFormatter.format(instant)
  }

  private def formatTimestamp(instant: Instant): String = {
    instantFormatter.format(instant)
  }

  case class Props(measurementType: MeasurementType, mpPotMeasurement: ModelProxy[Pot[Measurement]])
  case class State(rcp: ReactConnectProxy[Pot[Measurement]])

  class Backend {
    private def renderMeasurementLine(measurement: Measurement): VdomElement = {
      measurement match {
        case TemperatureMeasurement(timestamp: Instant, value) => TableRow()(
          TableCell("%.2f".format(value)),
          TableCell("°C"),
          TableCell(formatTimestamp(timestamp))
        ).vdomElement
        case PressureMeasurement(timestamp, value) => TableRow()(
          // TODO: better format (ideally move from serverside to shared - at least the formatting part of the trait)
          TableCell("%.2f".format(value)),
          TableCell("Pa"),
          TableCell(formatTimestamp(timestamp))
        ).vdomElement
        case RelativeHumidityMeasurement(timestamp, value) => TableRow()(
          TableCell("%.2f".format(value)),
          TableCell("%RH"),
          TableCell(formatTimestamp(timestamp))
        ).vdomElement
        case VoltageMeasurement(timestamp, value) => TableRow()(
          TableCell("%.3f".format(value)),
          TableCell("V"),
          TableCell(formatTimestamp(timestamp))
        ).vdomElement
        case PositionMeasurement(timestamp, latitudeDeg, longitudeDeg, positionalAccuracyMeter, altitudeMeter) => TableRow()(
          TableCell(s"${latitudeDeg} lat ${longitudeDeg} lon"),
          TableCell("Position"),
          TableCell(formatTimestamp(timestamp))
        ).vdomElement
        case Measurement.Empty => TableRow()(TableCell()(^.colSpan := 3, s"Unknown Measurement?")).vdomElement
      }
    }
    def render(props : Props, state: State): VdomElement = {
      def pendingRow(startTime: Long, text: String) = TableRow()(TableCell()(^.colSpan := 3, text)).vdomElement

      def failedRow(exception: Throwable) = TableRow()(TableCell()(^.colSpan := 3, s"Failed (${exception})")).vdomElement

      state.rcp((measurementProxy: ModelProxy[Pot[Measurement]]) => {
        measurementProxy.value match {
          case Empty => TableRow()(div("Not loaded")).vdomElement
          case Unavailable => TableRow()(div("Unavailable")).vdomElement
          case Ready(x: Measurement) => renderMeasurementLine(x)
          case Pending(startTime) => pendingRow(startTime, s"Loading ${ToStringUtils.measurementTypeToString(props.measurementType)}...")
          case PendingStale(x, startTime) => pendingRow(startTime, "Reloading...")
          case Failed(exception) => failedRow(exception)
          case FailedStale(x, exception) => failedRow(exception)
        }
      })
    }
  }

  private val component = ScalaComponent
    .builder[Props]
    .initialStateFromProps(props => State(rcp = props.mpPotMeasurement.connect(identity)))
    .renderBackend[Backend]
    .build

  def apply(measurementType: MeasurementType, mpPotMeasurement: ModelProxy[Pot[Measurement]]) = {
    component(Props(measurementType, mpPotMeasurement))
  }
}

object SensorCard {
  case class Props(sensorId: SensorId, mpPotSensorInfo: ModelProxy[Pot[SensorInfo]], routerCtl: RouterCtl[Page])
  case class State(rcp: ReactConnectProxy[Pot[SensorInfo]])

  class Backend {
    def render(props : Props, state: State): VdomElement = {
      import diode.react.ReactPot._

      state.rcp((mpPotSensorInfo: ModelProxy[Pot[SensorInfo]]) => {
        val potSensorInfo = mpPotSensorInfo.value
        val sensorId = props.sensorId

        val headerText = potSensorInfo.map(si => if (si.sensor.comment.nonEmpty) {
          si.sensor.comment
        } else {
          "Sensor"
        }).getOrElse("Loading...")
        val subHeaderText = s"${sensorId.value}"

        val latestValuesTable: VdomNode = potSensorInfo.render((sensorInfo: SensorInfo) => {
          scribe.debug(s"sensorcard: ${sensorId}, si: ${sensorInfo}")

          TableBody()(
            sensorInfo.sensor.measurementTypes.types.map((z: MeasurementType) => {
              scribe.debug(s"mt: ${z}")
              val proxy: ModelProxy[Pot[Measurement]] = mpPotSensorInfo.zoom(_.flatMap(_.latestMeasurements.get(z)))
              SensorMeasurementLine(z, proxy).vdomElement
            }): _*
          )
        })

        scribe.debug(s"sensorcard: ${sensorId}, potsi: ${potSensorInfo} types: ${potSensorInfo.map(_.latestMeasurements.keySet).getOrElse("unavailable")}")

        Card(
          ^.key := sensorId.value,
          CardContent(
            CardHeader(headerText),
            CardMeta(subHeaderText),
            Table()(
              TableHeader()(
                TableRow()(
                  TableHeaderCell("value"),
                  TableHeaderCell("unit"),
                  TableHeaderCell("time"),
                )
              ),
              latestValuesTable
            ),
          ),
          CardContent(extra = true)(
            Button()(
              "Details",
              props.routerCtl.setOnClick(Page.Sensor(sensorId)),
            )
          )
        ).vdomElement
      })
    }
  }

  private val component = ScalaComponent
    .builder[Props]
    .initialStateFromProps(props => State(rcp = props.mpPotSensorInfo.connect(identity)))
    .renderBackend[Backend]
    .build

  def apply(sensorId: SensorId, mpPotSensorInfo: ModelProxy[Pot[SensorInfo]], routerCtl: RouterCtl[Page]) = {
    component(Props(sensorId, mpPotSensorInfo, routerCtl))
  }

}

object SensorListScreen {
  case class Props(routerCtl: RouterCtl[Page], proxy: ModelProxy[Sensors])
  case class State(sensorListProxy: ReactConnectProxy[Sensors])
  case class Backend(bs: BackendScope[Props, State]) {
    def didMount(cdm: ComponentDidMount[Props, State, Backend]): Callback = {
      // TODO: sensor list refresh reuses latest measurements, add timer to refresh stale latest measurements
      scribe.debug("triggering sensor list refresh")
      cdm.props.proxy.dispatchCB(UpdateSensorList.all)
    }

    // NOTE: PotMap (and other PotCollections) are not 'IterableOnce', therefore 'toVdomArray' does not match
    def sensorArrayHelper(sensorProxy: ModelProxy[Sensors], f: (SensorId, ModelProxy[Pot[SensorInfo]]) => VdomNode): VdomArray = {
      val sensors = sensorProxy.value
      val a = VdomArray.empty()

      for (i: (SensorId, Pot[SensorInfo]) <- sensors.iterator) {

        scribe.debug(s"sensorArrayHelper:${i._1.value} -> ${i._2}")

        val sensorId = i._1

        // NOTE: just iterating over 'sensors.iterator' does not trigger 'sensors.refresh(sensorId)'
        // for PotState.PotEmpty, but calling .get(sensorId) on PotMap actually triggers the fetch
        val proxy : ModelProxy[Pot[SensorInfo]] = sensorProxy.zoom(_.get(sensorId))

        a.rawArray.push(f(sensorId, proxy).rawNode)
      }
      a
    }

    def render(props: Props, state: State) = {

      val dispatch: Action => Callback = props.proxy.dispatchCB

      state.sensorListProxy((sensorsProxy: ModelProxy[Sensors]) => {
        <.div(
          if (sensorsProxy.value.keySet.isEmpty) {
            // Loader(active = true)("Loading...")
            Message(info = true)(
              MessageHeader("No sensors"),
              "There are no sensors available at the moment."
            )
          } else {
            CardGroup(centered = true)(
              sensorArrayHelper(sensorsProxy, (sensorId: SensorId, mpPotSensorInfo: ModelProxy[Pot[SensorInfo]]) => {
                SensorCard(sensorId, mpPotSensorInfo, props.routerCtl)
              })
            ).vdomElement
          }
        )
      })
    }
  }

  private val component = ScalaComponent.builder[Props]
    .initialStateFromProps(props => State(sensorListProxy = props.proxy.connect(identity)))
    .renderBackend[Backend]
    .componentDidMount((x: ComponentDidMount[Props, State, Backend]) => x.backend.didMount(x))
    .build

  def apply(routerCtl: RouterCtl[Page], proxy: ModelProxy[Sensors]) = component(Props(routerCtl, proxy))
}
