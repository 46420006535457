package pwa.circuit

import api.v0.{LoginRequest, PwaSupportService0GrpcWeb, Tenant}
import diode._
import diode.data.{Failed, Pot, Ready}
import io.grpc.ManagedChannel
import org.scalajs.macrotaskexecutor.MacrotaskExecutor.Implicits._
import pwa.Page
import pwa.model.{ApiConfigModel, ApiToken, PwaSupportService, UserIdentity}
import scalapb.grpcweb.Metadata

case class LoginRequestAction(email: String, password: String) extends Action

case class LoginSuccessful(email: String, apiToken: ApiToken, defaultTenant: Tenant) extends Action

case class LoginFailed(reason: Throwable) extends Action

case class LogoutAction() extends Action

class LoginActionHandler[M](modelRW: ModelRW[M, ApiConfigModel], afterLoginPage: ModelRW[M, Option[Page]], currentTenant: ModelRW[M, Option[Tenant]], grpcChannel: ManagedChannel, api: PwaSupportService0GrpcWeb.PwaSupportService0[_]) extends ActionHandler(modelRW) {

  lazy val loggedOutApi: PwaSupportService = PwaSupportService0GrpcWeb.stub(grpcChannel)

  def loggedInApi(token: ApiToken): PwaSupportService = {
    val metadata: Metadata = scalapb.grpcweb.Metadata(shared.ApiUtils.MetadatAuthKey -> token.actualToken)
    // NOTE: as of "scalapb-grpcweb-code-gen" % "0.6.4", the following _cannot_ use (grpcChannel, metadata),
    // because generated code does not pass the 'metadata'
    // (the overload with 'f' and 'defaultContext' works...)
    PwaSupportService0GrpcWeb.stub[Metadata](grpcChannel, identity, metadata)
  }

  override protected def handle: PartialFunction[Any, ActionResult[M]] = {
    case LogoutAction() => {
      scribe.debug("LogoutAction")
      this.updated(
        newValue = ApiConfigModel(
          api = loggedOutApi,
          token = Pot.empty,
          user = Pot.empty,
        ),
        effect = AppCircuitHelper.triggerClearEverything() >> Effect.action(PageChangeNeeded.login)
      )
    }

    case LoginSuccessful(email, apiToken, defaultTenant) => {
      scribe.debug(s"login successful: token = '${apiToken.actualToken}', defaultTenant = '${defaultTenant}'")

      updated(
        newValue = ApiConfigModel(
          api = loggedInApi(apiToken),
          token = Ready(apiToken),
          user = Ready(UserIdentity(email))
        ),
        // Actual redirect o afterLoginPage is done in AtLoginWeakSetCurrentTenant handler.
        effect = Effect.action(AtLoginWeakSetCurrentTenant(defaultTenant))
      )
    }

    case LoginFailed(reason) => {
      scribe.debug(s"login failed: reason = ${reason}")
      updated(
        ApiConfigModel(
          api = loggedOutApi,
          token = Failed(reason),
          user = Failed(reason)
        ),
      )
    }
    case LoginRequestAction(email, password) => {
      scribe.debug(s"login request action")
      effectOnly(
        Effect(
          api
            .login(LoginRequest(email, password))
            .map(lr => LoginSuccessful(email, ApiToken(lr.token), lr.defaultTenant))
            .recover {
              case e: Throwable => {
                LoginFailed(e)
              }
            })
      )
    }
  }
}
