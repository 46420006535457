package pwa.circuit

import api.v0.{SensorId, SensorList, Tenant}
import diode.data._
import diode._
import org.scalajs.macrotaskexecutor.MacrotaskExecutor.Implicits._
import pwa.circuit.exceptions.NoCurrentTenantException
import pwa.model.{PwaSupportService, SensorInfo, Sensors}
import pwa.util.ProperAsyncAction

import scala.concurrent.Future
import scala.util.{Failure, Try}

// NOTE: Sensors subtree of the model should depend on tentant,
// TODO: change of tenant should create _new_ instances, not clearing existing ones (like here)
case class ClearSensorList() extends Action

case class UpdateSensorList(keys: Set[SensorId],
                            state: PotState = PotState.PotEmpty,
                            result: Try[Map[SensorId, Pot[SensorInfo]]] = Failure(new AsyncAction.PendingException)
                           ) extends ProperAsyncAction[Map[SensorId, Pot[SensorInfo]], SensorId, SensorInfo, UpdateSensorList] {

  override def shouldFetch(pot: Pot[SensorInfo]): Boolean = super.shouldFetchOnlyEmpty(pot)

  override def next(newState: PotState, newValue: Try[Map[SensorId, Pot[SensorInfo]]]): UpdateSensorList = {
    scribe.debug(s"newState ${newState}, newValue ${newValue}")
    UpdateSensorList(keys, newState, newValue)
  }
}

object UpdateSensorList {
  def all = UpdateSensorList(keys = Set.empty)
}

case class SensorListFetch(dispatch: Dispatcher) extends Fetch[SensorId] {
  override def fetch(key: SensorId): Unit = {
    scribe.debug(s"key ${key}")
    dispatch(UpdateSensorList(keys = Set(key)))
  }

  override def fetch(keys: Iterable[SensorId]): Unit = {
    scribe.debug(s"keys ${keys}")
    dispatch(UpdateSensorList(keys = Set() ++ keys))
  }
}

class UpdateSensorListHandler[M](
                                  dispatcher: Dispatcher,
                                  modelRW: ModelRW[M, Sensors],
                                  api: ModelR[M, PwaSupportService],
                                  currentTenant: RefTo[Option[Tenant]],
                                ) extends ApiActionHandler(modelRW, api, dispatcher) {

  // TODO: probably Pot[Sensor] instead of Pot[SensorInfo] and later convert using a function (replace the identity call)
  def load(sensorIds: Set[SensorId]): Future[Map[SensorId, Pot[SensorInfo]]] = {
    (currentTenant.target.value) match {
      case Some(ct) => {
        scribe.debug(s"requested ids: ${
          sensorIds
        }")

        def fetchCall: Future[SensorList] = api.value.getSensorList(ct.id)

        // NOTE: this filtering of whole returned list is not as bad as it seems.
        // fetching tenant's sensors actually requires getting sensor detail anyway.
        fetchCall.map((receivedSensorList: SensorList) => {
          val actuallyRequested = if (sensorIds.isEmpty) {
            // in case of request for empty collection, update all available sensors
            receivedSensorList.sensors
          } else {
            receivedSensorList.sensors.filter(sensor => sensorIds.contains(sensor.id))
          }
          val convertedResult: Seq[(SensorId, Ready[SensorInfo])] = actuallyRequested.map(returnedSensor => {
            returnedSensor.id -> Ready(SensorInfo(
              sensor = returnedSensor,
              latestMeasurements = PotMap(new LastMeasurementFetch(returnedSensor.id, dispatcher)),
              measurements = PotMap(new MeasurementFetch(returnedSensor.id, dispatcher)),
            ))
          })
          convertedResult.toMap
        })
      }
      case None => {
        Future.failed(new NoCurrentTenantException())
      }
    }
  }

  override protected def handle: PartialFunction[Any, ActionResult[M]] = {
    case action: ClearSensorList => {
      updated(value.clear)
    }
    case action: UpdateSensorList => {
      scribe.debug(s"action: ${
        action
      } (with potstate ${
        action.state
      })")

      scribe.debug(s"keys before handle: ${
        action.keys.size
      } = ${
        action.keys
      }")
      action.handleProperly(modelRW, (action: UpdateSensorList, keys: Set[SensorId]) => action.effect(load(keys))(
        success = identity, failure = redirectToLoginIfNeeded
      ))
    }
  }
}
