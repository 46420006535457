package pwa.circuit

import diode.{Action, ActionHandler, ActionResult, ModelRW}
import japgolly.scalajs.react.extra.router.RouterCtl
import pwa.Page

case class ConfigureRouter(ctl: RouterCtl[Page]) extends Action

class ConfigureRouterActionHandler[M](modelRW: ModelRW[M, RouterCtl[Page]]) extends ActionHandler(modelRW) {
  override protected def handle: PartialFunction[Any, ActionResult[M]] = {
    case ConfigureRouter(ctl) => {
      scribe.debug("setting RouterCtl model")
      updated(ctl)
    }
  }
}
