// Generated by the Scala Plugin for the Protocol Buffer Compiler.
// Do not edit!
//
// Protofile syntax: PROTO3

package api.v0
import api.v0.JavaNetInstantTypeMapper._

sealed abstract class MeasurementType(val value: _root_.scala.Int) extends _root_.scalapb.GeneratedEnum with api.v0.MeasurementTypeBase {
  type EnumType = MeasurementType
  def isUnknown: _root_.scala.Boolean = false
  def isTemperature: _root_.scala.Boolean = false
  def isPressure: _root_.scala.Boolean = false
  def isRelativeHumidity: _root_.scala.Boolean = false
  def isVoltage: _root_.scala.Boolean = false
  def isPosition: _root_.scala.Boolean = false
  def companion: _root_.scalapb.GeneratedEnumCompanion[MeasurementType] = api.v0.MeasurementType
  final def asRecognized: _root_.scala.Option[api.v0.MeasurementType.Recognized] = if (isUnrecognized) _root_.scala.None else _root_.scala.Some(this.asInstanceOf[api.v0.MeasurementType.Recognized])
}

object MeasurementType extends _root_.scalapb.GeneratedEnumCompanion[MeasurementType] {
  sealed trait Recognized extends MeasurementType
  implicit def enumCompanion: _root_.scalapb.GeneratedEnumCompanion[MeasurementType] = this
  
  @SerialVersionUID(0L)
  case object Unknown extends MeasurementType(0) with MeasurementType.Recognized with api.v0.UnknownMeasurementType {
    val index = 0
    val name = "UNKNOWN"
    override def isUnknown: _root_.scala.Boolean = true
  }
  
  @SerialVersionUID(0L)
  case object Temperature extends MeasurementType(1) with MeasurementType.Recognized with api.v0.TemperatureMeasurementType {
    val index = 1
    val name = "TEMPERATURE"
    override def isTemperature: _root_.scala.Boolean = true
  }
  
  @SerialVersionUID(0L)
  case object Pressure extends MeasurementType(2) with MeasurementType.Recognized with api.v0.PressureMeasurementType {
    val index = 2
    val name = "PRESSURE"
    override def isPressure: _root_.scala.Boolean = true
  }
  
  @SerialVersionUID(0L)
  case object RelativeHumidity extends MeasurementType(3) with MeasurementType.Recognized with api.v0.RelativeHumidityMeasurementType {
    val index = 3
    val name = "RELATIVE_HUMIDITY"
    override def isRelativeHumidity: _root_.scala.Boolean = true
  }
  
  @SerialVersionUID(0L)
  case object Voltage extends MeasurementType(4) with MeasurementType.Recognized with api.v0.VoltageMeasurementType {
    val index = 4
    val name = "VOLTAGE"
    override def isVoltage: _root_.scala.Boolean = true
  }
  
  @SerialVersionUID(0L)
  case object Position extends MeasurementType(5) with MeasurementType.Recognized with api.v0.PositionMeasurementType {
    val index = 5
    val name = "POSITION"
    override def isPosition: _root_.scala.Boolean = true
  }
  
  @SerialVersionUID(0L)
  final case class Unrecognized(unrecognizedValue: _root_.scala.Int) extends MeasurementType(unrecognizedValue) with _root_.scalapb.UnrecognizedEnum
  lazy val values = scala.collection.immutable.Seq(Unknown, Temperature, Pressure, RelativeHumidity, Voltage, Position)
  def fromValue(__value: _root_.scala.Int): MeasurementType = __value match {
    case 0 => Unknown
    case 1 => Temperature
    case 2 => Pressure
    case 3 => RelativeHumidity
    case 4 => Voltage
    case 5 => Position
    case __other => Unrecognized(__other)
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.EnumDescriptor = TypesProto.javaDescriptor.getEnumTypes().get(0)
  def scalaDescriptor: _root_.scalapb.descriptors.EnumDescriptor = TypesProto.scalaDescriptor.enums(0)
}