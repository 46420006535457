// Generated by the Scala Plugin for the Protocol Buffer Compiler.
// Do not edit!
//
// Protofile syntax: PROTO3

package api.v0

@SerialVersionUID(0L)
final case class SensorValuesResponse(
    measurements: api.v0.MeasurementList
    ) extends scalapb.GeneratedMessage with scalapb.lenses.Updatable[SensorValuesResponse] {
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      
      {
        val __value = api.v0.SensorValuesResponse._typemapper_measurements.toBase(measurements)
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      
      {
        val __v = api.v0.SensorValuesResponse._typemapper_measurements.toBase(measurements)
        _output__.writeTag(1, 2)
        _output__.writeUInt32NoTag(__v.serializedSize)
        __v.writeTo(_output__)
      };
    }
    def withMeasurements(__v: api.v0.MeasurementList): SensorValuesResponse = copy(measurements = __v)
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => api.v0.SensorValuesResponse._typemapper_measurements.toBase(measurements)
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => api.v0.SensorValuesResponse._typemapper_measurements.toBase(measurements).toPMessage
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.SensorValuesResponse.type = api.v0.SensorValuesResponse
    // @@protoc_insertion_point(GeneratedMessage[api.v0.SensorValuesResponse])
}

object SensorValuesResponse extends scalapb.GeneratedMessageCompanion[api.v0.SensorValuesResponse] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.SensorValuesResponse] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.SensorValuesResponse = {
    var __requiredFields0: _root_.scala.Long = 0x1L
    var __measurements: _root_.scala.Option[api.v0.MeasurementListMessage] = _root_.scala.None
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 10 =>
          __measurements = _root_.scala.Some(__measurements.fold(_root_.scalapb.LiteParser.readMessage[api.v0.MeasurementListMessage](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
          __requiredFields0 &= 0xfffffffffffffffeL
        case tag => _input__.skipField(tag)
      }
    }
    if (__requiredFields0 != 0L) { throw new _root_.com.google.protobuf.InvalidProtocolBufferException("Message missing required fields.") } 
    api.v0.SensorValuesResponse(
        measurements = api.v0.SensorValuesResponse._typemapper_measurements.toCustom(__measurements.getOrElse(api.v0.MeasurementListMessage.defaultInstance))
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.SensorValuesResponse] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.SensorValuesResponse(
        measurements = api.v0.SensorValuesResponse._typemapper_measurements.toCustom(__fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).get.as[api.v0.MeasurementListMessage])
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = PwaSupportService0Proto.javaDescriptor.getMessageTypes().get(8)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = PwaSupportService0Proto.scalaDescriptor.messages(8)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = {
    var __out: _root_.scalapb.GeneratedMessageCompanion[_] = null
    (__number: @_root_.scala.unchecked) match {
      case 1 => __out = api.v0.MeasurementListMessage
    }
    __out
  }
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = throw new MatchError(__fieldNumber)
  lazy val defaultInstance = api.v0.SensorValuesResponse(
    measurements = api.v0.SensorValuesResponse._typemapper_measurements.toCustom(api.v0.MeasurementListMessage.defaultInstance)
  )
  implicit class SensorValuesResponseLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.SensorValuesResponse]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.SensorValuesResponse](_l) {
    def measurements: _root_.scalapb.lenses.Lens[UpperPB, api.v0.MeasurementList] = field(_.measurements)((c_, f_) => c_.copy(measurements = f_))
  }
  final val MEASUREMENTS_FIELD_NUMBER = 1
  @transient
  private[v0] val _typemapper_measurements: _root_.scalapb.TypeMapper[api.v0.MeasurementListMessage, api.v0.MeasurementList] = implicitly[_root_.scalapb.TypeMapper[api.v0.MeasurementListMessage, api.v0.MeasurementList]]
  def of(
    measurements: api.v0.MeasurementList
  ): _root_.api.v0.SensorValuesResponse = _root_.api.v0.SensorValuesResponse(
    measurements
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.SensorValuesResponse])
}
