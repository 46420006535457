package pwa.circuit

import diode.{Action, ActionHandler, ActionResult, ModelRW}
import japgolly.scalajs.react.extra.router.RouterCtl
import pwa.Page

case class PageChangeNeeded(val page: pwa.Page) extends Action {

}

object PageChangeNeeded {
  def login = {
    PageChangeNeeded(page = Page.Login)
  }
}

class PageChangeNeededHandler[M](modelRW: ModelRW[M, RouterCtl[Page]]) extends ActionHandler(modelRW) {

  override protected def handle: PartialFunction[Any, ActionResult[M]] = {
    case pcn: PageChangeNeeded => {
      modelRW.value.set(pcn.page).runNow()

      // routerCtl is only an control interface for the router, model itself is not changed...
      noChange
    }
  }
}
