package pwa.screens

import diode.Action
import diode.data.Pot
import diode.react.{ModelProxy, ReactConnectProxy}
import japgolly.scalajs.react.component.builder.Lifecycle.ComponentDidMount
import japgolly.scalajs.react.extra.router.RouterCtl
import japgolly.scalajs.react.vdom.html_<^._
import japgolly.scalajs.react.{BackendScope, Callback, ScalaComponent}
import pwa.Page
import pwa.circuit.RefreshThingList
import pwa.model.Things
import react.semanticui.collections.message.{Message, MessageHeader}
import react.semanticui.elements.button.Button
import react.semanticui.elements.loader.Loader
import react.semanticui.views.card._

object ThingListScreen {
  case class Props(routerCtl: RouterCtl[Page], proxy: ModelProxy[Pot[Things]])

  case class State(
                    ThingListProxy: ReactConnectProxy[Pot[Things]]
                  )

  case class Backend(bs: BackendScope[Props, State]) {

    def didMount(cdm: ComponentDidMount[Props, State, Backend]): Callback = {
      cdm.props.proxy.dispatchCB(RefreshThingList())
    }

    def render(props: Props, state: State) = {
      import diode.react.ReactPot._

      val dispatch: Action => Callback = props.proxy.dispatchCB

      state.ThingListProxy((proxy: ModelProxy[Pot[Things]]) => {
        <.div(
          proxy().render((Things: Things) => {
            VdomArray(
              <.div(s"Current count: ${Things.seq.length}"),
              CardGroup(centered = true)(
                //<.ul(
                // note: react still complains about missing 'key'...
                Things.seq.toVdomArray { thing =>
                  //<.li(
                  Card(
                    //                    ^.key := Thingid.value,
                    CardContent(
                      CardHeader((if (thing.comment.isEmpty) {
                        "Thing"
                      } else thing.comment)),
                      CardMeta(thing.id.value),
                    ),
                    CardContent(extra = true)(
                      Button()(
                        "Details",
                        props.routerCtl.setOnClick(Page.Thing(thing.id)),
                      )
                    )
                  )
                }
              )
            )
          }
          ),
          proxy().renderPending { t =>
            Loader(active = true)("Loading...")
          },
          proxy().renderEmpty {
            Message(info = true)(
              MessageHeader("No Things"),
              "There are no Things visible."
            )
          },
          proxy().renderFailed { (e) =>
            // TODO: this is a hack, centralize it
            props.routerCtl.set(Page.Login).runNow()

            Message(negative = true)(
              MessageHeader("Failure"),
              "Failed to load Things!"
            )
          },
        )
      })
    }
  }

  private val component = ScalaComponent.builder[Props]
    .initialStateFromProps(props => State(ThingListProxy = props.proxy.connect(identity)))
    .renderBackend[Backend]
    .componentDidMount((x: ComponentDidMount[Props, State, Backend]) => x.backend.didMount(x))
    .build

  def apply(routerCtl: RouterCtl[Page], proxy: ModelProxy[Pot[Things]]) = component(Props(routerCtl, proxy))
}
