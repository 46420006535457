package pwa.screens

import diode.Action
import diode.data.Pot
import diode.react.{ModelProxy, ReactConnectProxy}
import japgolly.scalajs.react.component.builder.Lifecycle.ComponentDidMount
import japgolly.scalajs.react.extra.router.RouterCtl
import japgolly.scalajs.react.vdom.html_<^._
import japgolly.scalajs.react.{BackendScope, Callback, ScalaComponent}
import pwa.Page
import pwa.circuit.{RefreshTenantList, SetCurrentTenant}
import pwa.model.Tenants
import react.semanticui.collections.message.{Message, MessageHeader}
import react.semanticui.elements.button.Button
import react.semanticui.elements.loader.Loader
import react.semanticui.views.card._

object TenantListScreen {
  case class Props(routerCtl: RouterCtl[Page], proxy: ModelProxy[Pot[Tenants]])

  case class State(
                    tenantListProxy: ReactConnectProxy[Pot[Tenants]]
                  )

  case class Backend(bs: BackendScope[Props, State]) {

    def didMount(cdm: ComponentDidMount[Props, State, Backend]): Callback = {
      cdm.props.proxy.dispatchCB(RefreshTenantList())
    }

    def render(props: Props, state: State) = {
      import diode.react.ReactPot._

      val dispatch: Action => Callback = props.proxy.dispatchCB

      state.tenantListProxy((proxy: ModelProxy[Pot[Tenants]]) => {
        <.div(
          proxy().render((tenants: Tenants) => {
            VdomArray(
              <.div(s"Current count: ${tenants.seq.length}"),
              CardGroup(centered = true)(
                //<.ul(
                // note: react still complains about missing 'key'...
                tenants.seq.toVdomArray { tenant =>
                  //<.li(
                  Card(
                    //                    ^.key := sensorid.value,
                    CardContent(
                      CardHeader(s"${tenant.name}"),
                      CardMeta(tenant.id.value),
                    ),
                    CardContent(extra = true)(
                      Button()(
                        "Details",
                        props.routerCtl.setOnClick(Page.Tenant(tenant.id)),
                      ),
                      Button()(
                        "Switch to",
                        ^.onClick --> props.proxy.dispatchCB(SetCurrentTenant(tenant))
                      )
                    )
                  )
                }
              )
            )
          }
          ),
          proxy().renderPending { t =>
            Loader(active = true)("Loading...")
          },
          proxy().renderEmpty {
            Message(info = true)(
              MessageHeader("No tenants"),
              "There are no tenants visible."
            )
          },
          proxy().renderFailed { (e) =>
            // TODO: this is a hack, centralize it
            props.routerCtl.set(Page.Login).runNow()

            Message(negative = true)(
              MessageHeader("Failure"),
              "Failed to load tenants!"
            )
          },
        )
      })
    }
  }

  private val component = ScalaComponent.builder[Props]
    .initialStateFromProps(props => State(tenantListProxy = props.proxy.connect(identity)))
    .renderBackend[Backend]
    .componentDidMount((x: ComponentDidMount[Props, State, Backend]) => x.backend.didMount(x))
    .build

  def apply(routerCtl: RouterCtl[Page], proxy: ModelProxy[Pot[Tenants]]) = component(Props(routerCtl, proxy))
}
