// Generated by the Scala Plugin for the Protocol Buffer Compiler.
// Do not edit!
//
// Protofile syntax: PROTO3

package api.v0
import api.v0.JavaNetInstantTypeMapper._

sealed trait MeasurementList extends scalapb.GeneratedSealedOneof {
  type MessageType = api.v0.MeasurementListMessage
  final def isEmpty = this.isInstanceOf[api.v0.MeasurementList.Empty.type]
  final def isDefined = !isEmpty
  final def asMessage: api.v0.MeasurementListMessage = api.v0.MeasurementList.MeasurementListTypeMapper.toBase(this)
  final def asNonEmpty: Option[api.v0.MeasurementList.NonEmpty] = if (isEmpty) None else Some(this.asInstanceOf[api.v0.MeasurementList.NonEmpty])
}

object MeasurementList {
  case object Empty extends api.v0.MeasurementList
  
  sealed trait NonEmpty extends api.v0.MeasurementList
  def defaultInstance: api.v0.MeasurementList = Empty
  
  implicit val MeasurementListTypeMapper: _root_.scalapb.TypeMapper[api.v0.MeasurementListMessage, api.v0.MeasurementList] = new _root_.scalapb.TypeMapper[api.v0.MeasurementListMessage, api.v0.MeasurementList] {
    override def toCustom(__base: api.v0.MeasurementListMessage): api.v0.MeasurementList = __base.sealedValue match {
      case __v: api.v0.MeasurementListMessage.SealedValue.Temperature => __v.value
      case __v: api.v0.MeasurementListMessage.SealedValue.Pressure => __v.value
      case __v: api.v0.MeasurementListMessage.SealedValue.RelativeHumidity => __v.value
      case __v: api.v0.MeasurementListMessage.SealedValue.Voltage => __v.value
      case __v: api.v0.MeasurementListMessage.SealedValue.Position => __v.value
      case api.v0.MeasurementListMessage.SealedValue.Empty => Empty
    }
    override def toBase(__custom: api.v0.MeasurementList): api.v0.MeasurementListMessage = api.v0.MeasurementListMessage(__custom match {
      case __v: api.v0.TemperatureMeasurementList => api.v0.MeasurementListMessage.SealedValue.Temperature(__v)
      case __v: api.v0.PressureMeasurementList => api.v0.MeasurementListMessage.SealedValue.Pressure(__v)
      case __v: api.v0.RelativeHumidityMeasurementList => api.v0.MeasurementListMessage.SealedValue.RelativeHumidity(__v)
      case __v: api.v0.VoltageMeasurementList => api.v0.MeasurementListMessage.SealedValue.Voltage(__v)
      case __v: api.v0.PositionMeasurementList => api.v0.MeasurementListMessage.SealedValue.Position(__v)
      case Empty => api.v0.MeasurementListMessage.SealedValue.Empty
    })
  }
}
@SerialVersionUID(0L)
final case class MeasurementListMessage(
    sealedValue: api.v0.MeasurementListMessage.SealedValue = api.v0.MeasurementListMessage.SealedValue.Empty
    ) extends scalapb.GeneratedMessage with scalapb.lenses.Updatable[MeasurementListMessage] {
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      if (sealedValue.temperature.isDefined) {
        val __value = sealedValue.temperature.get
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      if (sealedValue.pressure.isDefined) {
        val __value = sealedValue.pressure.get
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      if (sealedValue.relativeHumidity.isDefined) {
        val __value = sealedValue.relativeHumidity.get
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      if (sealedValue.voltage.isDefined) {
        val __value = sealedValue.voltage.get
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      if (sealedValue.position.isDefined) {
        val __value = sealedValue.position.get
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      sealedValue.temperature.foreach { __v =>
        val __m = __v
        _output__.writeTag(1, 2)
        _output__.writeUInt32NoTag(__m.serializedSize)
        __m.writeTo(_output__)
      };
      sealedValue.pressure.foreach { __v =>
        val __m = __v
        _output__.writeTag(2, 2)
        _output__.writeUInt32NoTag(__m.serializedSize)
        __m.writeTo(_output__)
      };
      sealedValue.relativeHumidity.foreach { __v =>
        val __m = __v
        _output__.writeTag(3, 2)
        _output__.writeUInt32NoTag(__m.serializedSize)
        __m.writeTo(_output__)
      };
      sealedValue.voltage.foreach { __v =>
        val __m = __v
        _output__.writeTag(4, 2)
        _output__.writeUInt32NoTag(__m.serializedSize)
        __m.writeTo(_output__)
      };
      sealedValue.position.foreach { __v =>
        val __m = __v
        _output__.writeTag(5, 2)
        _output__.writeUInt32NoTag(__m.serializedSize)
        __m.writeTo(_output__)
      };
    }
    def getTemperature: api.v0.TemperatureMeasurementList = sealedValue.temperature.getOrElse(api.v0.TemperatureMeasurementList.defaultInstance)
    def withTemperature(__v: api.v0.TemperatureMeasurementList): MeasurementListMessage = copy(sealedValue = api.v0.MeasurementListMessage.SealedValue.Temperature(__v))
    def getPressure: api.v0.PressureMeasurementList = sealedValue.pressure.getOrElse(api.v0.PressureMeasurementList.defaultInstance)
    def withPressure(__v: api.v0.PressureMeasurementList): MeasurementListMessage = copy(sealedValue = api.v0.MeasurementListMessage.SealedValue.Pressure(__v))
    def getRelativeHumidity: api.v0.RelativeHumidityMeasurementList = sealedValue.relativeHumidity.getOrElse(api.v0.RelativeHumidityMeasurementList.defaultInstance)
    def withRelativeHumidity(__v: api.v0.RelativeHumidityMeasurementList): MeasurementListMessage = copy(sealedValue = api.v0.MeasurementListMessage.SealedValue.RelativeHumidity(__v))
    def getVoltage: api.v0.VoltageMeasurementList = sealedValue.voltage.getOrElse(api.v0.VoltageMeasurementList.defaultInstance)
    def withVoltage(__v: api.v0.VoltageMeasurementList): MeasurementListMessage = copy(sealedValue = api.v0.MeasurementListMessage.SealedValue.Voltage(__v))
    def getPosition: api.v0.PositionMeasurementList = sealedValue.position.getOrElse(api.v0.PositionMeasurementList.defaultInstance)
    def withPosition(__v: api.v0.PositionMeasurementList): MeasurementListMessage = copy(sealedValue = api.v0.MeasurementListMessage.SealedValue.Position(__v))
    def clearSealedValue: MeasurementListMessage = copy(sealedValue = api.v0.MeasurementListMessage.SealedValue.Empty)
    def withSealedValue(__v: api.v0.MeasurementListMessage.SealedValue): MeasurementListMessage = copy(sealedValue = __v)
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => sealedValue.temperature.orNull
        case 2 => sealedValue.pressure.orNull
        case 3 => sealedValue.relativeHumidity.orNull
        case 4 => sealedValue.voltage.orNull
        case 5 => sealedValue.position.orNull
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => sealedValue.temperature.map(_.toPMessage).getOrElse(_root_.scalapb.descriptors.PEmpty)
        case 2 => sealedValue.pressure.map(_.toPMessage).getOrElse(_root_.scalapb.descriptors.PEmpty)
        case 3 => sealedValue.relativeHumidity.map(_.toPMessage).getOrElse(_root_.scalapb.descriptors.PEmpty)
        case 4 => sealedValue.voltage.map(_.toPMessage).getOrElse(_root_.scalapb.descriptors.PEmpty)
        case 5 => sealedValue.position.map(_.toPMessage).getOrElse(_root_.scalapb.descriptors.PEmpty)
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.MeasurementListMessage.type = api.v0.MeasurementListMessage
    def toMeasurementList: api.v0.MeasurementList = api.v0.MeasurementList.MeasurementListTypeMapper.toCustom(this)
    // @@protoc_insertion_point(GeneratedMessage[api.v0.MeasurementList])
}

object MeasurementListMessage extends scalapb.GeneratedMessageCompanion[api.v0.MeasurementListMessage] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.MeasurementListMessage] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.MeasurementListMessage = {
    var __sealedValue: api.v0.MeasurementListMessage.SealedValue = api.v0.MeasurementListMessage.SealedValue.Empty
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 10 =>
          __sealedValue = api.v0.MeasurementListMessage.SealedValue.Temperature(__sealedValue.temperature.fold(_root_.scalapb.LiteParser.readMessage[api.v0.TemperatureMeasurementList](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
        case 18 =>
          __sealedValue = api.v0.MeasurementListMessage.SealedValue.Pressure(__sealedValue.pressure.fold(_root_.scalapb.LiteParser.readMessage[api.v0.PressureMeasurementList](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
        case 26 =>
          __sealedValue = api.v0.MeasurementListMessage.SealedValue.RelativeHumidity(__sealedValue.relativeHumidity.fold(_root_.scalapb.LiteParser.readMessage[api.v0.RelativeHumidityMeasurementList](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
        case 34 =>
          __sealedValue = api.v0.MeasurementListMessage.SealedValue.Voltage(__sealedValue.voltage.fold(_root_.scalapb.LiteParser.readMessage[api.v0.VoltageMeasurementList](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
        case 42 =>
          __sealedValue = api.v0.MeasurementListMessage.SealedValue.Position(__sealedValue.position.fold(_root_.scalapb.LiteParser.readMessage[api.v0.PositionMeasurementList](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
        case tag => _input__.skipField(tag)
      }
    }
    api.v0.MeasurementListMessage(
        sealedValue = __sealedValue
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.MeasurementListMessage] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.MeasurementListMessage(
        sealedValue = __fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).flatMap(_.as[_root_.scala.Option[api.v0.TemperatureMeasurementList]]).map(api.v0.MeasurementListMessage.SealedValue.Temperature(_))
            .orElse[api.v0.MeasurementListMessage.SealedValue](__fieldsMap.get(scalaDescriptor.findFieldByNumber(2).get).flatMap(_.as[_root_.scala.Option[api.v0.PressureMeasurementList]]).map(api.v0.MeasurementListMessage.SealedValue.Pressure(_)))
            .orElse[api.v0.MeasurementListMessage.SealedValue](__fieldsMap.get(scalaDescriptor.findFieldByNumber(3).get).flatMap(_.as[_root_.scala.Option[api.v0.RelativeHumidityMeasurementList]]).map(api.v0.MeasurementListMessage.SealedValue.RelativeHumidity(_)))
            .orElse[api.v0.MeasurementListMessage.SealedValue](__fieldsMap.get(scalaDescriptor.findFieldByNumber(4).get).flatMap(_.as[_root_.scala.Option[api.v0.VoltageMeasurementList]]).map(api.v0.MeasurementListMessage.SealedValue.Voltage(_)))
            .orElse[api.v0.MeasurementListMessage.SealedValue](__fieldsMap.get(scalaDescriptor.findFieldByNumber(5).get).flatMap(_.as[_root_.scala.Option[api.v0.PositionMeasurementList]]).map(api.v0.MeasurementListMessage.SealedValue.Position(_)))
            .getOrElse(api.v0.MeasurementListMessage.SealedValue.Empty)
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = TypesProto.javaDescriptor.getMessageTypes().get(21)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = TypesProto.scalaDescriptor.messages(21)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = {
    var __out: _root_.scalapb.GeneratedMessageCompanion[_] = null
    (__number: @_root_.scala.unchecked) match {
      case 1 => __out = api.v0.TemperatureMeasurementList
      case 2 => __out = api.v0.PressureMeasurementList
      case 3 => __out = api.v0.RelativeHumidityMeasurementList
      case 4 => __out = api.v0.VoltageMeasurementList
      case 5 => __out = api.v0.PositionMeasurementList
    }
    __out
  }
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = throw new MatchError(__fieldNumber)
  lazy val defaultInstance = api.v0.MeasurementListMessage(
    sealedValue = api.v0.MeasurementListMessage.SealedValue.Empty
  )
  sealed trait SealedValue extends _root_.scalapb.GeneratedOneof {
    def isEmpty: _root_.scala.Boolean = false
    def isDefined: _root_.scala.Boolean = true
    def isTemperature: _root_.scala.Boolean = false
    def isPressure: _root_.scala.Boolean = false
    def isRelativeHumidity: _root_.scala.Boolean = false
    def isVoltage: _root_.scala.Boolean = false
    def isPosition: _root_.scala.Boolean = false
    def temperature: _root_.scala.Option[api.v0.TemperatureMeasurementList] = _root_.scala.None
    def pressure: _root_.scala.Option[api.v0.PressureMeasurementList] = _root_.scala.None
    def relativeHumidity: _root_.scala.Option[api.v0.RelativeHumidityMeasurementList] = _root_.scala.None
    def voltage: _root_.scala.Option[api.v0.VoltageMeasurementList] = _root_.scala.None
    def position: _root_.scala.Option[api.v0.PositionMeasurementList] = _root_.scala.None
  }
  object SealedValue {
    @SerialVersionUID(0L)
    case object Empty extends api.v0.MeasurementListMessage.SealedValue {
      type ValueType = _root_.scala.Nothing
      override def isEmpty: _root_.scala.Boolean = true
      override def isDefined: _root_.scala.Boolean = false
      override def number: _root_.scala.Int = 0
      override def value: _root_.scala.Nothing = throw new java.util.NoSuchElementException("Empty.value")
    }
  
    @SerialVersionUID(0L)
    final case class Temperature(value: api.v0.TemperatureMeasurementList) extends api.v0.MeasurementListMessage.SealedValue {
      type ValueType = api.v0.TemperatureMeasurementList
      override def isTemperature: _root_.scala.Boolean = true
      override def temperature: _root_.scala.Option[api.v0.TemperatureMeasurementList] = Some(value)
      override def number: _root_.scala.Int = 1
    }
    @SerialVersionUID(0L)
    final case class Pressure(value: api.v0.PressureMeasurementList) extends api.v0.MeasurementListMessage.SealedValue {
      type ValueType = api.v0.PressureMeasurementList
      override def isPressure: _root_.scala.Boolean = true
      override def pressure: _root_.scala.Option[api.v0.PressureMeasurementList] = Some(value)
      override def number: _root_.scala.Int = 2
    }
    @SerialVersionUID(0L)
    final case class RelativeHumidity(value: api.v0.RelativeHumidityMeasurementList) extends api.v0.MeasurementListMessage.SealedValue {
      type ValueType = api.v0.RelativeHumidityMeasurementList
      override def isRelativeHumidity: _root_.scala.Boolean = true
      override def relativeHumidity: _root_.scala.Option[api.v0.RelativeHumidityMeasurementList] = Some(value)
      override def number: _root_.scala.Int = 3
    }
    @SerialVersionUID(0L)
    final case class Voltage(value: api.v0.VoltageMeasurementList) extends api.v0.MeasurementListMessage.SealedValue {
      type ValueType = api.v0.VoltageMeasurementList
      override def isVoltage: _root_.scala.Boolean = true
      override def voltage: _root_.scala.Option[api.v0.VoltageMeasurementList] = Some(value)
      override def number: _root_.scala.Int = 4
    }
    @SerialVersionUID(0L)
    final case class Position(value: api.v0.PositionMeasurementList) extends api.v0.MeasurementListMessage.SealedValue {
      type ValueType = api.v0.PositionMeasurementList
      override def isPosition: _root_.scala.Boolean = true
      override def position: _root_.scala.Option[api.v0.PositionMeasurementList] = Some(value)
      override def number: _root_.scala.Int = 5
    }
  }
  implicit class MeasurementListMessageLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.MeasurementListMessage]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.MeasurementListMessage](_l) {
    def temperature: _root_.scalapb.lenses.Lens[UpperPB, api.v0.TemperatureMeasurementList] = field(_.getTemperature)((c_, f_) => c_.copy(sealedValue = api.v0.MeasurementListMessage.SealedValue.Temperature(f_)))
    def pressure: _root_.scalapb.lenses.Lens[UpperPB, api.v0.PressureMeasurementList] = field(_.getPressure)((c_, f_) => c_.copy(sealedValue = api.v0.MeasurementListMessage.SealedValue.Pressure(f_)))
    def relativeHumidity: _root_.scalapb.lenses.Lens[UpperPB, api.v0.RelativeHumidityMeasurementList] = field(_.getRelativeHumidity)((c_, f_) => c_.copy(sealedValue = api.v0.MeasurementListMessage.SealedValue.RelativeHumidity(f_)))
    def voltage: _root_.scalapb.lenses.Lens[UpperPB, api.v0.VoltageMeasurementList] = field(_.getVoltage)((c_, f_) => c_.copy(sealedValue = api.v0.MeasurementListMessage.SealedValue.Voltage(f_)))
    def position: _root_.scalapb.lenses.Lens[UpperPB, api.v0.PositionMeasurementList] = field(_.getPosition)((c_, f_) => c_.copy(sealedValue = api.v0.MeasurementListMessage.SealedValue.Position(f_)))
    def sealedValue: _root_.scalapb.lenses.Lens[UpperPB, api.v0.MeasurementListMessage.SealedValue] = field(_.sealedValue)((c_, f_) => c_.copy(sealedValue = f_))
  }
  final val TEMPERATURE_FIELD_NUMBER = 1
  final val PRESSURE_FIELD_NUMBER = 2
  final val RELATIVE_HUMIDITY_FIELD_NUMBER = 3
  final val VOLTAGE_FIELD_NUMBER = 4
  final val POSITION_FIELD_NUMBER = 5
  def of(
    sealedValue: api.v0.MeasurementListMessage.SealedValue
  ): _root_.api.v0.MeasurementListMessage = _root_.api.v0.MeasurementListMessage(
    sealedValue
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.MeasurementList])
}

@SerialVersionUID(0L)
final case class TemperatureMeasurementList(
    measurements: _root_.scala.Seq[api.v0.TemperatureMeasurement] = _root_.scala.Seq.empty
    ) extends scalapb.GeneratedMessage with api.v0.MeasurementList.NonEmpty with scalapb.lenses.Updatable[TemperatureMeasurementList] {
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      measurements.foreach { __item =>
        val __value = __item
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      }
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      measurements.foreach { __v =>
        val __m = __v
        _output__.writeTag(1, 2)
        _output__.writeUInt32NoTag(__m.serializedSize)
        __m.writeTo(_output__)
      };
    }
    def clearMeasurements = copy(measurements = _root_.scala.Seq.empty)
    def addMeasurements(__vs: api.v0.TemperatureMeasurement *): TemperatureMeasurementList = addAllMeasurements(__vs)
    def addAllMeasurements(__vs: Iterable[api.v0.TemperatureMeasurement]): TemperatureMeasurementList = copy(measurements = measurements ++ __vs)
    def withMeasurements(__v: _root_.scala.Seq[api.v0.TemperatureMeasurement]): TemperatureMeasurementList = copy(measurements = __v)
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => measurements
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => _root_.scalapb.descriptors.PRepeated(measurements.iterator.map(_.toPMessage).toVector)
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.TemperatureMeasurementList.type = api.v0.TemperatureMeasurementList
    // @@protoc_insertion_point(GeneratedMessage[api.v0.TemperatureMeasurementList])
}

object TemperatureMeasurementList extends scalapb.GeneratedMessageCompanion[api.v0.TemperatureMeasurementList] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.TemperatureMeasurementList] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.TemperatureMeasurementList = {
    val __measurements: _root_.scala.collection.immutable.VectorBuilder[api.v0.TemperatureMeasurement] = new _root_.scala.collection.immutable.VectorBuilder[api.v0.TemperatureMeasurement]
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 10 =>
          __measurements += _root_.scalapb.LiteParser.readMessage[api.v0.TemperatureMeasurement](_input__)
        case tag => _input__.skipField(tag)
      }
    }
    api.v0.TemperatureMeasurementList(
        measurements = __measurements.result()
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.TemperatureMeasurementList] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.TemperatureMeasurementList(
        measurements = __fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).map(_.as[_root_.scala.Seq[api.v0.TemperatureMeasurement]]).getOrElse(_root_.scala.Seq.empty)
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = TypesProto.javaDescriptor.getMessageTypes().get(11)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = TypesProto.scalaDescriptor.messages(11)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = {
    var __out: _root_.scalapb.GeneratedMessageCompanion[_] = null
    (__number: @_root_.scala.unchecked) match {
      case 1 => __out = api.v0.TemperatureMeasurement
    }
    __out
  }
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = throw new MatchError(__fieldNumber)
  lazy val defaultInstance = api.v0.TemperatureMeasurementList(
    measurements = _root_.scala.Seq.empty
  )
  implicit class TemperatureMeasurementListLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.TemperatureMeasurementList]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.TemperatureMeasurementList](_l) {
    def measurements: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Seq[api.v0.TemperatureMeasurement]] = field(_.measurements)((c_, f_) => c_.copy(measurements = f_))
  }
  final val MEASUREMENTS_FIELD_NUMBER = 1
  def of(
    measurements: _root_.scala.Seq[api.v0.TemperatureMeasurement]
  ): _root_.api.v0.TemperatureMeasurementList = _root_.api.v0.TemperatureMeasurementList(
    measurements
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.TemperatureMeasurementList])
}

@SerialVersionUID(0L)
final case class PressureMeasurementList(
    measurements: _root_.scala.Seq[api.v0.PressureMeasurement] = _root_.scala.Seq.empty
    ) extends scalapb.GeneratedMessage with api.v0.MeasurementList.NonEmpty with scalapb.lenses.Updatable[PressureMeasurementList] {
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      measurements.foreach { __item =>
        val __value = __item
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      }
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      measurements.foreach { __v =>
        val __m = __v
        _output__.writeTag(1, 2)
        _output__.writeUInt32NoTag(__m.serializedSize)
        __m.writeTo(_output__)
      };
    }
    def clearMeasurements = copy(measurements = _root_.scala.Seq.empty)
    def addMeasurements(__vs: api.v0.PressureMeasurement *): PressureMeasurementList = addAllMeasurements(__vs)
    def addAllMeasurements(__vs: Iterable[api.v0.PressureMeasurement]): PressureMeasurementList = copy(measurements = measurements ++ __vs)
    def withMeasurements(__v: _root_.scala.Seq[api.v0.PressureMeasurement]): PressureMeasurementList = copy(measurements = __v)
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => measurements
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => _root_.scalapb.descriptors.PRepeated(measurements.iterator.map(_.toPMessage).toVector)
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.PressureMeasurementList.type = api.v0.PressureMeasurementList
    // @@protoc_insertion_point(GeneratedMessage[api.v0.PressureMeasurementList])
}

object PressureMeasurementList extends scalapb.GeneratedMessageCompanion[api.v0.PressureMeasurementList] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.PressureMeasurementList] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.PressureMeasurementList = {
    val __measurements: _root_.scala.collection.immutable.VectorBuilder[api.v0.PressureMeasurement] = new _root_.scala.collection.immutable.VectorBuilder[api.v0.PressureMeasurement]
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 10 =>
          __measurements += _root_.scalapb.LiteParser.readMessage[api.v0.PressureMeasurement](_input__)
        case tag => _input__.skipField(tag)
      }
    }
    api.v0.PressureMeasurementList(
        measurements = __measurements.result()
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.PressureMeasurementList] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.PressureMeasurementList(
        measurements = __fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).map(_.as[_root_.scala.Seq[api.v0.PressureMeasurement]]).getOrElse(_root_.scala.Seq.empty)
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = TypesProto.javaDescriptor.getMessageTypes().get(13)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = TypesProto.scalaDescriptor.messages(13)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = {
    var __out: _root_.scalapb.GeneratedMessageCompanion[_] = null
    (__number: @_root_.scala.unchecked) match {
      case 1 => __out = api.v0.PressureMeasurement
    }
    __out
  }
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = throw new MatchError(__fieldNumber)
  lazy val defaultInstance = api.v0.PressureMeasurementList(
    measurements = _root_.scala.Seq.empty
  )
  implicit class PressureMeasurementListLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.PressureMeasurementList]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.PressureMeasurementList](_l) {
    def measurements: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Seq[api.v0.PressureMeasurement]] = field(_.measurements)((c_, f_) => c_.copy(measurements = f_))
  }
  final val MEASUREMENTS_FIELD_NUMBER = 1
  def of(
    measurements: _root_.scala.Seq[api.v0.PressureMeasurement]
  ): _root_.api.v0.PressureMeasurementList = _root_.api.v0.PressureMeasurementList(
    measurements
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.PressureMeasurementList])
}

@SerialVersionUID(0L)
final case class RelativeHumidityMeasurementList(
    measurements: _root_.scala.Seq[api.v0.RelativeHumidityMeasurement] = _root_.scala.Seq.empty
    ) extends scalapb.GeneratedMessage with api.v0.MeasurementList.NonEmpty with scalapb.lenses.Updatable[RelativeHumidityMeasurementList] {
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      measurements.foreach { __item =>
        val __value = __item
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      }
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      measurements.foreach { __v =>
        val __m = __v
        _output__.writeTag(1, 2)
        _output__.writeUInt32NoTag(__m.serializedSize)
        __m.writeTo(_output__)
      };
    }
    def clearMeasurements = copy(measurements = _root_.scala.Seq.empty)
    def addMeasurements(__vs: api.v0.RelativeHumidityMeasurement *): RelativeHumidityMeasurementList = addAllMeasurements(__vs)
    def addAllMeasurements(__vs: Iterable[api.v0.RelativeHumidityMeasurement]): RelativeHumidityMeasurementList = copy(measurements = measurements ++ __vs)
    def withMeasurements(__v: _root_.scala.Seq[api.v0.RelativeHumidityMeasurement]): RelativeHumidityMeasurementList = copy(measurements = __v)
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => measurements
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => _root_.scalapb.descriptors.PRepeated(measurements.iterator.map(_.toPMessage).toVector)
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.RelativeHumidityMeasurementList.type = api.v0.RelativeHumidityMeasurementList
    // @@protoc_insertion_point(GeneratedMessage[api.v0.RelativeHumidityMeasurementList])
}

object RelativeHumidityMeasurementList extends scalapb.GeneratedMessageCompanion[api.v0.RelativeHumidityMeasurementList] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.RelativeHumidityMeasurementList] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.RelativeHumidityMeasurementList = {
    val __measurements: _root_.scala.collection.immutable.VectorBuilder[api.v0.RelativeHumidityMeasurement] = new _root_.scala.collection.immutable.VectorBuilder[api.v0.RelativeHumidityMeasurement]
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 10 =>
          __measurements += _root_.scalapb.LiteParser.readMessage[api.v0.RelativeHumidityMeasurement](_input__)
        case tag => _input__.skipField(tag)
      }
    }
    api.v0.RelativeHumidityMeasurementList(
        measurements = __measurements.result()
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.RelativeHumidityMeasurementList] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.RelativeHumidityMeasurementList(
        measurements = __fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).map(_.as[_root_.scala.Seq[api.v0.RelativeHumidityMeasurement]]).getOrElse(_root_.scala.Seq.empty)
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = TypesProto.javaDescriptor.getMessageTypes().get(15)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = TypesProto.scalaDescriptor.messages(15)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = {
    var __out: _root_.scalapb.GeneratedMessageCompanion[_] = null
    (__number: @_root_.scala.unchecked) match {
      case 1 => __out = api.v0.RelativeHumidityMeasurement
    }
    __out
  }
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = throw new MatchError(__fieldNumber)
  lazy val defaultInstance = api.v0.RelativeHumidityMeasurementList(
    measurements = _root_.scala.Seq.empty
  )
  implicit class RelativeHumidityMeasurementListLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.RelativeHumidityMeasurementList]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.RelativeHumidityMeasurementList](_l) {
    def measurements: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Seq[api.v0.RelativeHumidityMeasurement]] = field(_.measurements)((c_, f_) => c_.copy(measurements = f_))
  }
  final val MEASUREMENTS_FIELD_NUMBER = 1
  def of(
    measurements: _root_.scala.Seq[api.v0.RelativeHumidityMeasurement]
  ): _root_.api.v0.RelativeHumidityMeasurementList = _root_.api.v0.RelativeHumidityMeasurementList(
    measurements
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.RelativeHumidityMeasurementList])
}

@SerialVersionUID(0L)
final case class VoltageMeasurementList(
    measurements: _root_.scala.Seq[api.v0.VoltageMeasurement] = _root_.scala.Seq.empty
    ) extends scalapb.GeneratedMessage with api.v0.MeasurementList.NonEmpty with scalapb.lenses.Updatable[VoltageMeasurementList] {
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      measurements.foreach { __item =>
        val __value = __item
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      }
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      measurements.foreach { __v =>
        val __m = __v
        _output__.writeTag(1, 2)
        _output__.writeUInt32NoTag(__m.serializedSize)
        __m.writeTo(_output__)
      };
    }
    def clearMeasurements = copy(measurements = _root_.scala.Seq.empty)
    def addMeasurements(__vs: api.v0.VoltageMeasurement *): VoltageMeasurementList = addAllMeasurements(__vs)
    def addAllMeasurements(__vs: Iterable[api.v0.VoltageMeasurement]): VoltageMeasurementList = copy(measurements = measurements ++ __vs)
    def withMeasurements(__v: _root_.scala.Seq[api.v0.VoltageMeasurement]): VoltageMeasurementList = copy(measurements = __v)
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => measurements
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => _root_.scalapb.descriptors.PRepeated(measurements.iterator.map(_.toPMessage).toVector)
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.VoltageMeasurementList.type = api.v0.VoltageMeasurementList
    // @@protoc_insertion_point(GeneratedMessage[api.v0.VoltageMeasurementList])
}

object VoltageMeasurementList extends scalapb.GeneratedMessageCompanion[api.v0.VoltageMeasurementList] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.VoltageMeasurementList] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.VoltageMeasurementList = {
    val __measurements: _root_.scala.collection.immutable.VectorBuilder[api.v0.VoltageMeasurement] = new _root_.scala.collection.immutable.VectorBuilder[api.v0.VoltageMeasurement]
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 10 =>
          __measurements += _root_.scalapb.LiteParser.readMessage[api.v0.VoltageMeasurement](_input__)
        case tag => _input__.skipField(tag)
      }
    }
    api.v0.VoltageMeasurementList(
        measurements = __measurements.result()
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.VoltageMeasurementList] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.VoltageMeasurementList(
        measurements = __fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).map(_.as[_root_.scala.Seq[api.v0.VoltageMeasurement]]).getOrElse(_root_.scala.Seq.empty)
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = TypesProto.javaDescriptor.getMessageTypes().get(17)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = TypesProto.scalaDescriptor.messages(17)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = {
    var __out: _root_.scalapb.GeneratedMessageCompanion[_] = null
    (__number: @_root_.scala.unchecked) match {
      case 1 => __out = api.v0.VoltageMeasurement
    }
    __out
  }
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = throw new MatchError(__fieldNumber)
  lazy val defaultInstance = api.v0.VoltageMeasurementList(
    measurements = _root_.scala.Seq.empty
  )
  implicit class VoltageMeasurementListLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.VoltageMeasurementList]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.VoltageMeasurementList](_l) {
    def measurements: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Seq[api.v0.VoltageMeasurement]] = field(_.measurements)((c_, f_) => c_.copy(measurements = f_))
  }
  final val MEASUREMENTS_FIELD_NUMBER = 1
  def of(
    measurements: _root_.scala.Seq[api.v0.VoltageMeasurement]
  ): _root_.api.v0.VoltageMeasurementList = _root_.api.v0.VoltageMeasurementList(
    measurements
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.VoltageMeasurementList])
}

@SerialVersionUID(0L)
final case class PositionMeasurementList(
    measurements: _root_.scala.Seq[api.v0.PositionMeasurement] = _root_.scala.Seq.empty
    ) extends scalapb.GeneratedMessage with api.v0.MeasurementList.NonEmpty with scalapb.lenses.Updatable[PositionMeasurementList] {
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      measurements.foreach { __item =>
        val __value = __item
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      }
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      measurements.foreach { __v =>
        val __m = __v
        _output__.writeTag(1, 2)
        _output__.writeUInt32NoTag(__m.serializedSize)
        __m.writeTo(_output__)
      };
    }
    def clearMeasurements = copy(measurements = _root_.scala.Seq.empty)
    def addMeasurements(__vs: api.v0.PositionMeasurement *): PositionMeasurementList = addAllMeasurements(__vs)
    def addAllMeasurements(__vs: Iterable[api.v0.PositionMeasurement]): PositionMeasurementList = copy(measurements = measurements ++ __vs)
    def withMeasurements(__v: _root_.scala.Seq[api.v0.PositionMeasurement]): PositionMeasurementList = copy(measurements = __v)
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => measurements
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => _root_.scalapb.descriptors.PRepeated(measurements.iterator.map(_.toPMessage).toVector)
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.PositionMeasurementList.type = api.v0.PositionMeasurementList
    // @@protoc_insertion_point(GeneratedMessage[api.v0.PositionMeasurementList])
}

object PositionMeasurementList extends scalapb.GeneratedMessageCompanion[api.v0.PositionMeasurementList] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.PositionMeasurementList] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.PositionMeasurementList = {
    val __measurements: _root_.scala.collection.immutable.VectorBuilder[api.v0.PositionMeasurement] = new _root_.scala.collection.immutable.VectorBuilder[api.v0.PositionMeasurement]
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 10 =>
          __measurements += _root_.scalapb.LiteParser.readMessage[api.v0.PositionMeasurement](_input__)
        case tag => _input__.skipField(tag)
      }
    }
    api.v0.PositionMeasurementList(
        measurements = __measurements.result()
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.PositionMeasurementList] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.PositionMeasurementList(
        measurements = __fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).map(_.as[_root_.scala.Seq[api.v0.PositionMeasurement]]).getOrElse(_root_.scala.Seq.empty)
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = TypesProto.javaDescriptor.getMessageTypes().get(19)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = TypesProto.scalaDescriptor.messages(19)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = {
    var __out: _root_.scalapb.GeneratedMessageCompanion[_] = null
    (__number: @_root_.scala.unchecked) match {
      case 1 => __out = api.v0.PositionMeasurement
    }
    __out
  }
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = throw new MatchError(__fieldNumber)
  lazy val defaultInstance = api.v0.PositionMeasurementList(
    measurements = _root_.scala.Seq.empty
  )
  implicit class PositionMeasurementListLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.PositionMeasurementList]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.PositionMeasurementList](_l) {
    def measurements: _root_.scalapb.lenses.Lens[UpperPB, _root_.scala.Seq[api.v0.PositionMeasurement]] = field(_.measurements)((c_, f_) => c_.copy(measurements = f_))
  }
  final val MEASUREMENTS_FIELD_NUMBER = 1
  def of(
    measurements: _root_.scala.Seq[api.v0.PositionMeasurement]
  ): _root_.api.v0.PositionMeasurementList = _root_.api.v0.PositionMeasurementList(
    measurements
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.PositionMeasurementList])
}
