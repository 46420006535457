package pwa.components

import api.v0.{MeasurementList, MeasurementType}
import diode.data.Pot
import diode.react.ModelProxy
import japgolly.scalajs.react.vdom.html_<^._
import japgolly.scalajs.react.{BackendScope, Ref, ScalaComponent}
import org.scalajs.dom.html
import pwa.components.ScalarDataVisualization.ActiveTab.ActiveTab
import react.semanticui.collections.menu.{Menu, MenuItem}
import react.semanticui.elements.header.Header
import react.semanticui.elements.segment.Segment

object ScalarDataVisualization {

  object ActiveTab extends Enumeration {
    type ActiveTab = Value
    val GRAPH, TABLE = Value
  }

  case class Props(mt: MeasurementType, mlp: ModelProxy[Pot[MeasurementList]])

  case class State(activeTab: ActiveTab = ActiveTab.GRAPH)

  case class Backend($: BackendScope[Props, State]) {
    private val divRef = Ref[html.Element]

    def render(props: Props, state: State) = {

      val renderTab: VdomElement = state.activeTab match {
        case ActiveTab.GRAPH => {
          ScalarChart(props.mt, props.mlp).vdomElement
        }
        case ActiveTab.TABLE => {
          MeasurementsTable(props.mt, props.mlp).vdomElement
        }
      }

      import pwa.util.ToStringUtils._

      Segment(
        Header(as = <.h3)(measurementTypeToString(props.mt)),
        Menu(pointing = true, secondary = true)(
          MenuItem(as = <.a, active = state.activeTab == ActiveTab.GRAPH, onClick = $.modState((s: State) => s.copy(activeTab = ActiveTab.GRAPH)))("Graph"),
          MenuItem(as = <.a, active = state.activeTab == ActiveTab.TABLE, onClick = $.modState((s: State) => s.copy(activeTab = ActiveTab.TABLE)))("Table"),
        ),
        renderTab
      )

    }
  }

  private val component = ScalaComponent.builder[Props]
    .initialState(State(activeTab = ActiveTab.GRAPH))
    .renderBackend[Backend]
    .build

  def apply(mt: MeasurementType, mlp: ModelProxy[Pot[MeasurementList]]) = component(Props(mt, mlp))
}
