// Generated by the Scala Plugin for the Protocol Buffer Compiler.
// Do not edit!
//
// Protofile syntax: PROTO3

package api.v0

@SerialVersionUID(0L)
final case class LatestSensorValueRequest(
    sensorId: api.v0.SensorId,
    measurementType: api.v0.MeasurementType = api.v0.MeasurementType.Unknown
    ) extends scalapb.GeneratedMessage with scalapb.lenses.Updatable[LatestSensorValueRequest] {
    @transient
    private[this] var __serializedSizeMemoized: _root_.scala.Int = 0
    private[this] def __computeSerializedSize(): _root_.scala.Int = {
      var __size = 0
      
      {
        val __value = sensorId
        __size += 1 + _root_.com.google.protobuf.CodedOutputStream.computeUInt32SizeNoTag(__value.serializedSize) + __value.serializedSize
      };
      
      {
        val __value = measurementType.value
        if (__value != 0) {
          __size += _root_.com.google.protobuf.CodedOutputStream.computeEnumSize(2, __value)
        }
      };
      __size
    }
    override def serializedSize: _root_.scala.Int = {
      var __size = __serializedSizeMemoized
      if (__size == 0) {
        __size = __computeSerializedSize() + 1
        __serializedSizeMemoized = __size
      }
      __size - 1
      
    }
    def writeTo(`_output__`: _root_.com.google.protobuf.CodedOutputStream): _root_.scala.Unit = {
      
      {
        val __v = sensorId
        _output__.writeTag(1, 2)
        _output__.writeUInt32NoTag(__v.serializedSize)
        __v.writeTo(_output__)
      };
      {
        val __v = measurementType.value
        if (__v != 0) {
          _output__.writeEnum(2, __v)
        }
      };
    }
    def withSensorId(__v: api.v0.SensorId): LatestSensorValueRequest = copy(sensorId = __v)
    def withMeasurementType(__v: api.v0.MeasurementType): LatestSensorValueRequest = copy(measurementType = __v)
    def getFieldByNumber(__fieldNumber: _root_.scala.Int): _root_.scala.Any = {
      (__fieldNumber: @_root_.scala.unchecked) match {
        case 1 => sensorId
        case 2 => {
          val __t = measurementType.javaValueDescriptor
          if (__t.getNumber() != 0) __t else null
        }
      }
    }
    def getField(__field: _root_.scalapb.descriptors.FieldDescriptor): _root_.scalapb.descriptors.PValue = {
      _root_.scala.Predef.require(__field.containingMessage eq companion.scalaDescriptor)
      (__field.number: @_root_.scala.unchecked) match {
        case 1 => sensorId.toPMessage
        case 2 => _root_.scalapb.descriptors.PEnum(measurementType.scalaValueDescriptor)
      }
    }
    def toProtoString: _root_.scala.Predef.String = _root_.scalapb.TextFormat.printToUnicodeString(this)
    def companion: api.v0.LatestSensorValueRequest.type = api.v0.LatestSensorValueRequest
    // @@protoc_insertion_point(GeneratedMessage[api.v0.LatestSensorValueRequest])
}

object LatestSensorValueRequest extends scalapb.GeneratedMessageCompanion[api.v0.LatestSensorValueRequest] {
  implicit def messageCompanion: scalapb.GeneratedMessageCompanion[api.v0.LatestSensorValueRequest] = this
  def parseFrom(`_input__`: _root_.com.google.protobuf.CodedInputStream): api.v0.LatestSensorValueRequest = {
    var __requiredFields0: _root_.scala.Long = 0x1L
    var __sensorId: _root_.scala.Option[api.v0.SensorId] = _root_.scala.None
    var __measurementType: api.v0.MeasurementType = api.v0.MeasurementType.Unknown
    var _done__ = false
    while (!_done__) {
      val _tag__ = _input__.readTag()
      _tag__ match {
        case 0 => _done__ = true
        case 10 =>
          __sensorId = _root_.scala.Some(__sensorId.fold(_root_.scalapb.LiteParser.readMessage[api.v0.SensorId](_input__))(_root_.scalapb.LiteParser.readMessage(_input__, _)))
          __requiredFields0 &= 0xfffffffffffffffeL
        case 16 =>
          __measurementType = api.v0.MeasurementType.fromValue(_input__.readEnum())
        case tag => _input__.skipField(tag)
      }
    }
    if (__requiredFields0 != 0L) { throw new _root_.com.google.protobuf.InvalidProtocolBufferException("Message missing required fields.") } 
    api.v0.LatestSensorValueRequest(
        sensorId = __sensorId.getOrElse(api.v0.SensorId.defaultInstance),
        measurementType = __measurementType
    )
  }
  implicit def messageReads: _root_.scalapb.descriptors.Reads[api.v0.LatestSensorValueRequest] = _root_.scalapb.descriptors.Reads{
    case _root_.scalapb.descriptors.PMessage(__fieldsMap) =>
      _root_.scala.Predef.require(__fieldsMap.keys.forall(_.containingMessage eq scalaDescriptor), "FieldDescriptor does not match message type.")
      api.v0.LatestSensorValueRequest(
        sensorId = __fieldsMap.get(scalaDescriptor.findFieldByNumber(1).get).get.as[api.v0.SensorId],
        measurementType = api.v0.MeasurementType.fromValue(__fieldsMap.get(scalaDescriptor.findFieldByNumber(2).get).map(_.as[_root_.scalapb.descriptors.EnumValueDescriptor]).getOrElse(api.v0.MeasurementType.Unknown.scalaValueDescriptor).number)
      )
    case _ => throw new RuntimeException("Expected PMessage")
  }
  def javaDescriptor: _root_.com.google.protobuf.Descriptors.Descriptor = PwaSupportService0Proto.javaDescriptor.getMessageTypes().get(5)
  def scalaDescriptor: _root_.scalapb.descriptors.Descriptor = PwaSupportService0Proto.scalaDescriptor.messages(5)
  def messageCompanionForFieldNumber(__number: _root_.scala.Int): _root_.scalapb.GeneratedMessageCompanion[_] = {
    var __out: _root_.scalapb.GeneratedMessageCompanion[_] = null
    (__number: @_root_.scala.unchecked) match {
      case 1 => __out = api.v0.SensorId
    }
    __out
  }
  lazy val nestedMessagesCompanions: Seq[_root_.scalapb.GeneratedMessageCompanion[_ <: _root_.scalapb.GeneratedMessage]] = Seq.empty
  def enumCompanionForFieldNumber(__fieldNumber: _root_.scala.Int): _root_.scalapb.GeneratedEnumCompanion[_] = {
    (__fieldNumber: @_root_.scala.unchecked) match {
      case 2 => api.v0.MeasurementType
    }
  }
  lazy val defaultInstance = api.v0.LatestSensorValueRequest(
    sensorId = api.v0.SensorId.defaultInstance,
    measurementType = api.v0.MeasurementType.Unknown
  )
  implicit class LatestSensorValueRequestLens[UpperPB](_l: _root_.scalapb.lenses.Lens[UpperPB, api.v0.LatestSensorValueRequest]) extends _root_.scalapb.lenses.ObjectLens[UpperPB, api.v0.LatestSensorValueRequest](_l) {
    def sensorId: _root_.scalapb.lenses.Lens[UpperPB, api.v0.SensorId] = field(_.sensorId)((c_, f_) => c_.copy(sensorId = f_))
    def measurementType: _root_.scalapb.lenses.Lens[UpperPB, api.v0.MeasurementType] = field(_.measurementType)((c_, f_) => c_.copy(measurementType = f_))
  }
  final val SENSOR_ID_FIELD_NUMBER = 1
  final val MEASUREMENT_TYPE_FIELD_NUMBER = 2
  def of(
    sensorId: api.v0.SensorId,
    measurementType: api.v0.MeasurementType
  ): _root_.api.v0.LatestSensorValueRequest = _root_.api.v0.LatestSensorValueRequest(
    sensorId,
    measurementType
  )
  // @@protoc_insertion_point(GeneratedMessageCompanion[api.v0.LatestSensorValueRequest])
}
