package pwa.util

import diode.ActionResult.NoChange
import diode.data.PotState.PotEmpty
import diode.{ActionHandler, ActionResult, Effect, ModelRW}
import diode.data.{AsyncAction, Pending, Pot, PotMap, PotState}

// TODO: better name, 'keys' make it PotMap specific anyway
trait ProperAsyncAction[A <: Map[K, Pot[V]], K, V, P <: ProperAsyncAction[A, K, V, P]] extends AsyncAction[A, P] {
  // required for 'effectGenerator'
  this: P =>

  def keys: Set[K]

  // TODO: this should be configurable per instance
  def shouldFetch(pot: Pot[V]): Boolean = shouldFetchNotPending(pot)

  def shouldFetchNotPending(pot: Pot[V]): Boolean = {
    if (pot.isPending) {
      false
    } else {
      true
    }
  }

  def shouldFetchOnlyEmpty(pot: Pot[V]): Boolean = {
    pot.state == PotEmpty
  }

  def provideNewMap(original: PotMap[K, V], kvs: Iterable[(K, Pot[V])])(implicit merger: Merger[Pot[V]]): PotMap[K, V] = {
    // merge if there is an old value..
    // (because it may be in the process of feching nested Pots
    val merged: Iterable[(K, Pot[V])] = kvs.map((i: (K, Pot[V])) => {
      val key = i._1
      val value = i._2
      val mergedValue = if (original.keySet.contains(key)) {
        // NOTE: if there is Some(Empty), the 'get' will trigger a fetch.
        // but this handler should suppress actual api call
        // (caller of this function will make it non-empty, and handler checks before the call)
        val old = original.get(key)
        scribe.debug(s"merging ${old} into ${value}")
        Merger.merge(old, value)
      } else {
        value
      }
      (key, mergedValue)

    })
    original.updated(merged)
  }

  def handleProperly[M](model: ModelRW[M, PotMap[K, V]], effectGenerator: (P, Set[K]) => Effect)(implicit merger: Merger[Pot[V]]): ActionResult[M] = {
    // NOTE: this is an ugly hack. 'AsyncAction.mapHandler' required an action handler with correct model type,
    // for use with 'value' and 'updated' calls. In order to avoid reimplementing parts of the ActionHandler class
    // (and possibly diverging), we create temporary handler here.
    // Ideally ActionHandler should work only withthe 'handle' and inherit the rest (value, updated,...) from some other class
    val handler = new ActionHandler(model) {
      override protected def handle: PartialFunction[Any, ActionResult[M]] = throw new RuntimeException("Invalid action handler called")
    }

    // makes it easier to reason about what is action (and what is imported from handler)
    val action = this

    action.state match {
      case PotState.PotEmpty => {
        val keysToSkip: Set[K] = handler.value.seq.filter((z: (K, Pot[V])) => !action.shouldFetch(z._2)).map(_._1).toSet
        val actuallyNeededKeys: Set[K] = action.keys.diff(keysToSkip)

        scribe.debug(s"requested keys: ${action.keys.size} = ${action.keys}")
        scribe.debug(s"current keys and state ${handler.value.seq.map { case (id, value) => s"'${id}' -> ${value.state}" }.mkString(",")}")

        if (action.keys.isEmpty) {
          // NOTE: when action is created with empty key list, tha caller wants _all_ to fetch PotMap for all keys.
          // Because keys are not known beforehand, there are no PotPending values created...
          // (they just appear as PotReady once fetch finishes)
          scribe.debug(s"fetching the whole collection")
          handler.effectOnly(effectGenerator(action, action.keys))
        } else if (actuallyNeededKeys.isEmpty) {
          // Nothing to do, make sure not extra effects is created
          scribe.debug(s"no keys to be fetched")
          NoChange
        } else {
          scribe.debug(s"keys to be fetched: ${actuallyNeededKeys}")
          val effect = effectGenerator(action, actuallyNeededKeys)

          val newValue: PotMap[K, V] = handler.value.map {
            (k: K, v: Pot[V]) => {
              if (actuallyNeededKeys.contains(k)) {
                v.pending()
              } else {
                v
              }
            }
          } ++ (actuallyNeededKeys -- handler.value.keySet).map(k => k -> Pending())

          scribe.debug(s"after initiating fetch -  keys and state ${newValue.seq.map { case (id, value) => s"'${id}' -> ${value.state}" }.mkString(",")}")

          handler.updated(newValue, effect)
        }
      }
      case PotState.PotUnavailable => NoChange
      case PotState.PotReady => {
        scribe.debug(s"before potready -  keys and state ${handler.value.seq.map { case (id, value) => s"'${id}' -> ${value.state}" }.mkString(",")}")
        // NOTE: using 'provideNewMap' we give chance to new values to reuse data from previous iteration
        // (e.g. already fetched Pot members)
        // It would be nice to have a way to mark such inherited values as possibly-out-of-date,
        // unfortunately PotState has only isStale, that results from initiating refresh on Ready value
        // (and we cannot do that - it could be expensive fetch. we need to just mark stale... and that
        // would require reimplementing Pot types)
        val newValue = provideNewMap(handler.value, action.result.get)
        scribe.debug(s"after potready -  keys and state ${newValue.seq.map { case (id, value) => s"'${id}' -> ${value.state}" }.mkString(",")}")
        handler.updated(newValue)
      }
      case PotState.PotPending => NoChange
      case PotState.PotFailed => {
        val t: Throwable = action.result.failed.get
        val newValue: PotMap[K, V] = handler.value.map {
          (k: K, v: Pot[V]) => {
            if (action.keys.contains(k)) {
              v.fail(t)
            } else {
              v
            }
          }
        }
        handler.updated(newValue)
      }
    }
  }
}
